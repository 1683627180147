<div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="'HOME.CONSIDER_TSA.CONSIDER_TSA_HEADER' | translate"></h4>
      </div>
      <div class="modal-body">
        <div class="chk-interview-ins">
          <span [innerHTML]="'HOME.CONSIDER_TSA.CONSIDER_TSA_STATEMENT' | translate"></span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row row-link-group">

          <div class="col-xs-12 col-md-6">
            <a class="btn btn-primary btn-block" tabindex="0" data-dismiss="modal" (click)="showSiteLeavingToTSAModal();" (keyup.enter)="showSiteLeavingToTSAModal();"
              [innerHTML]="'HOME.CONSIDER_TSA.APPLY_TO_TSA' | translate"></a>
          </div>
          <div class="col-xs-12 col-md-6">
            <a class="btn btn-primary btn-block" style="white-space: normal; word-wrap: break-word;"  tabindex="0" data-dismiss="modal" (click)="continueWithUP();" (keyup.enter)="continueWithUP();"
                  [innerHTML]="'HOME.CONSIDER_TSA.CONTINUE_WITH_GLOBAL_ENTRY' | translate"></a>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
