<div class="row">
	<div class="col-lg-12 col-lg-12 progress-top-level centered">
        <h1 translate>PROGRESS.PAYMENT_RECEIPT</h1>
        <p class="divider hidden-xs"></p>
    </div>
</div> 

<div class="page-content" *ngIf="paymentInfo">
    <div class="container">
        <div class="content-panel" id="print-area">
            <div class="row">
                <div class="col-xs-12">
                    <h2 translate>PAYMENT_HISTORY.PAYMENT_INFORMATION</h2>
                    
                    
                    <table class="payment-table table table-striped">
                        <tbody>
                            <tr>
                                <td colspan="2" class="title-row" translate>PAYMENT_HISTORY.PAYMENT_SUMMARY</td>
                            </tr>
                            <tr *ngIf="paymentInfo.paymentStatus === 'Reversed'"> 
                                <td translate>PAYMENT_HISTORY.PAYMENT_STATUS</td>
                                <td>{{paymentInfo.paymentStatus}}</td>
                            </tr>
                            <tr> 
                                <td translate>PAYMENT_HISTORY.PAYMENT_METHOD</td>
                                <td>{{paymentInfo.paymentMethod}}</td>
                            </tr>
                            <tr> 
                                <td translate>PAYMENT_HISTORY.GOES_TRACKING_ID</td>
                                <td>{{paymentInfo.paymentTrackingId}}</td>
                            </tr>
                            <!-- 
                            per review feedback
                            <tr> 
                                <td translate>PAYMENT_HISTORY.CREDIT_CARD_AUTH</td>
                                <td>{{paymentInfo.creditCardAuthCode}}</td>
                            </tr> -->
                            <tr> 
                                <td translate>PAYMENT_HISTORY.PAYMENT_DATE</td>
                                <td>{{paymentDate?.getFormattedString()}}</td>
                            </tr>
                            <tr> 
                                <td translate>PAYMENT_HISTORY.TOTAL_AMOUNT</td>
                                <td class="payment-amount">{{ paymentInfo.totalPaymentAmount | currency : "USD" : true }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    
                    <!--Applications -->
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 translate>PAYMENT_HISTORY.APP_INFO</h2>
                            <table class="payment-table table table-striped"  *ngFor="let application of paymentInfo.applications">
                                <tbody>
                                    <tr> 
                                        <td colspan="2" class="title-row" translate>{{application.applicationName}}</td>
                                    </tr>
                                    <tr> 
                                        <td translate>PAYMENT_HISTORY.APPLICATION_ID</td>
                                        <td>{{application.appId}}</td>
                                    </tr>
                                    <tr> 
                                        <td translate>PAYMENT_HISTORY.PAYMENT_AMOUNT</td>
                                        <td>{{application.paymentAmount  | currency : "USD" : true }}</td>
                                    </tr> 
                                    <br>
                                </tbody>
                            </table>
                            
                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr> 
                                        <td colspan="2" class="title-row" translate>PAYMENT_HISTORY.APPLICANT</td>
                                    </tr>
                                    <tr> 
                                        <td translate>PAYMENT_HISTORY.FIRST_NAME</td>
                                        <td>{{paymentInfo.firstName}}</td>
                                    </tr>
                                    <tr> 
                                        <td translate>PAYMENT_HISTORY.LAST_NAME</td>
                                        <td>{{paymentInfo.lastName}}</td>
                                    </tr>
                                    <tr>                                 
                                        <td translate>PAYMENT_HISTORY.DATE_OF_BIRTH</td>
                                        <td>{{dob?.getFormattedString()}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>    
                </div>
            </div>
            <div class="row">
                <div class="alert alert-info col-xs-12">
                  <i class="fa fa-info-circle" style="vertical-align: middle;" aria-hidden="true"></i>
                    <span translate>PAYMENT_HISTORY.RECOMMEND_PRINT</span>
                </div>
            </div>
        </div>
        
        <div class="row row-button-group">
            <div class="col-xs-12 col-md-2 col-lg-2">
                <a id="back" class="btn btn-nav btn-default btn-nav btn-block"  tabindex="0" [routerLink]="['/dashboard']" name="print" translate>GENERAL_REUSABLE.BACK_BUTTON</a>
            </div>
            <div class="col-xs-12 col-md-3 col-lg-3">
                <a id="print" class="btn btn-nav btn-default btn-nav btn-block"  tabindex="0" (click)="openPrintView()" (keyup.enter)="openPrintView()" name="print" translate>GENERAL_REUSABLE.PRINT_BUTTON</a>
            </div>
        </div>
    </div>
</div>