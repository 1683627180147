import { SpinnerService } from './shared/spinner/spinner.service';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { LoginComponent } from './login.component';
import { PaymentRoutingComponent } from './payment-routing.component';
import { AccountProfileComponent } from './sign-up/account-profile.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';

import { routes } from './app.routes';
import { VehicleService } from '../app/common/services/vehicle.service';
import { PhoneService } from '../app/common/services/phone.service';
import { FAQService } from '../app/common/services/faq.service';
import { NewsService } from '../app/common/services/news.service';
import { LogService } from '../app/common/services/log.service';
import { AuthService } from '../app/common/services/auth.service';
import { AuthGuard } from './common/services/auth-guard.service';
import { ApplicationService } from '../app/common/services/application.service';
import { SignUpService } from '../app/common/services/sign-up.service';
import { TranslateReferenceService } from '../app/common/services/translate-reference.service';
import { ErrorHandlerService } from '../app/common/services/error-handler.service';
import { TemplateValidationService } from '../app/common/services/template-validation.service';
import { PaymentHistoryService } from '../app/common/services/payment-history.service';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { ModalService } from './shared/modals/modal.service';
import { ProgramCertificationService } from '../app/common/services/program-certification.service';
import { PurchaseSummaryService } from '../app/common/services/purchase-summary.service';
import { PurchaseSummaryComponent } from './purchase-summary/purchase-summary.component';
import { FAQComponent} from './faq/faq.component';

import { SystemMessagesService } from '../app/common/services/system-messages.service';
import { SystemConfigService } from '../app/common/services/system-config.service';

import { ScheduleComponent } from './interview/schedule/schedule.component';
import { InterviewSummaryComponent } from './interview/summary/interview-summary.component';
import { InterviewCancellationComponent } from './interview/cancellation/interview-cancellation.component';
import { InterviewCancelComponent } from './interview/cancellation/interview-cancel.component';

import { Ng2MissingTranslationHandler } from './common/utils/ng2-missing-translation-handler';
import { DashboardService } from '../app/common/services/dashboard.service';
import { ScheduleService } from '../app/common/services/schedule.service';

import { UpdateDocumentService } from '../app/common/services/update-document.service';
import { ViewMembershipInfoService } from '../app/common/services/view-membership-info.service';

// import { AuthModule } from './auth.module';
import { AuthHttpService } from '../app/common/services/auth-http.service';

import { BypassComponent } from './bypass.component';
import { SurveyStatsService } from '../app/common/services/survey-stats.service';

import { AnnouncementsComponent } from './announcements/announcements.component';

import { ReconsiderationComponent} from './reconsideration/reconsideration.component';
import { ViewReconsiderationComponent} from './reconsideration/viewReconsideration.component';
import { ReconsiderationService } from './reconsideration/reconsideration.service';
import { DocumentVerificationService } from '../app/common/services/document-verification.service';

import { AnalyticsService } from '../app/common/services/analytics.service';

import { ReapplyService } from '../app/common/services/reapply.service';

import { JwtModule, JwtInterceptor, JWT_OPTIONS } from '@auth0/angular-jwt';
import { JwtHttpInterceptor } from './jwt-http-interceptor';
import { FileUploadModule } from 'ng2-file-upload';
import { environment } from '../environments/environment';
import { CalendarService } from './shared/services/calendar.service';
import { CBSAService } from './common/services/cbsa.service';
import { PrivacyActComponent } from './privacy-act/privacy-act.compontent';
import { LegalGuardianService } from './common/services/legal-guardian.service';
import { LegalGuardianComponent } from './legal-guardian/legal-guardian-info.component';

export function translateModuleFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function translateReferenceServiceFactory(referenceService : TranslateReferenceService) {
    return () => {
        return referenceService.initData();
    };
}

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem('ttp_token');
    },
    allowedDomains: environment.allowedDomains,
    disallowedRoutes: environment.disallowedRoutes
  };
}


@NgModule({
  imports: [
      BrowserModule,
      FormsModule,
      HttpClientModule,
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory
        }
      }), 
      RouterModule.forRoot(routes,   {
    initialNavigation: 'disabled',
    relativeLinkResolution: 'legacy'
}),
      //InMemoryWebApiModule.forRoot(InMemoryDataService),
      HomeModule,
      SharedModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateModuleFactory,
          deps: [HttpClient]
        }
      }),
      // AuthModule,
      FileUploadModule
    ],
  declarations: [
    AppComponent,
    UnderMaintenanceComponent,
    PaymentRoutingComponent,
    LoginComponent,
    PaymentHistoryComponent,
    AccountProfileComponent,
    PurchaseSummaryComponent,
    FAQComponent,
    ReconsiderationComponent,
    ViewReconsiderationComponent,
    ScheduleComponent,
    InterviewCancellationComponent,
    InterviewSummaryComponent,
    InterviewCancelComponent,
    BypassComponent,
    AnnouncementsComponent,
    PrivacyActComponent,
    LegalGuardianComponent
  ],
  providers: [
    AuthHttpService,
    FAQService,
    PhoneService,
    ErrorHandlerService,
    PaymentHistoryService,
    AuthService,
    AuthGuard,
    ApplicationService,
    TranslateReferenceService,
    ScheduleService,
    SpinnerService,
    SignUpService,
    NewsService,
    LogService,
    ModalService,
    TemplateValidationService,
    TranslateService,
    TemplateValidationService,
    ProgramCertificationService,
    PurchaseSummaryService,
    VehicleService,
    DashboardService,
    UpdateDocumentService,
    SystemMessagesService,
    SystemConfigService,
    SurveyStatsService,
    ReconsiderationService,
    ReapplyService,
    ViewMembershipInfoService,
    DocumentVerificationService,
    AnalyticsService,
    CalendarService,
    CBSAService,
    LegalGuardianService,
    // requestOptionsProvider,
    {
        provide: APP_BASE_HREF,
        // useValue: '<%= APP_BASE %>'
        useValue: '/'
    },
    {
      provide: MissingTranslationHandler,
      useClass: Ng2MissingTranslationHandler
    },
    {
        provide : APP_INITIALIZER,
        useFactory: translateReferenceServiceFactory,
        deps : [TranslateReferenceService],
        multi: true
    },
    JwtInterceptor,
    {
        provide: HTTP_INTERCEPTORS,
        useExisting: JwtInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtHttpInterceptor,
        multi: true
    }
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],

})

export class AppModule { }
