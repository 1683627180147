import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApplicationService } from '../common/services/application.service';
import { PurchaseSummaryService } from '../common/services/purchase-summary.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationSummary } from '../common/models/application-summary.model';
import { ModalService} from '../shared/modals/modal.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from '../common/services/auth.service';
import { DashboardService } from '../common/services/dashboard.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { TranslateReferenceService } from '../common/services/translate-reference.service';

import Utils from '../common/utils/utils';
import { map } from 'rxjs/operators';
declare var $: any;
@Component({
  moduleId: module.id,
  templateUrl: 'purchase-summary.component.html'
})

export class PurchaseSummaryComponent implements OnInit {
  private dueDate: Date;
  public dueDateDay: string;
  public dueDateMonth: string;
  public dueDateDate: string;
  public dueDateYear: string;
  public totalFee = 0;
  public paymentNotice: boolean;
  private completedApplications: number;

  private appId: string;           // Optional application id
  private appType: string;         // C (Conversion) || R (Renewal)
  private newProgramCode: string;  // Optional code needed for conversion
  private userId: string;
  public applicationsSummary: ApplicationSummary[];
  public payAction: string;      // Optional BD - Balance Due or CONV - Conversion ALL - All unpaid
  public isSESOnly = false;

  public translatedDueDate = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private applicationService: ApplicationService,
              private purchaseSummaryService: PurchaseSummaryService,
              private modalService: ModalService,
              private translate: TranslateService,
              private authService: AuthService,
              private spinner: SpinnerService,
              private dashboardService: DashboardService,
              private refService: TranslateReferenceService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.payAction = params.payAction || null;

      if (this.payAction === 'B' || this.payAction === 'C') {
        this.appId = params.appId || null;
        this.appType = params.appType || null;
        this.newProgramCode = params.newProgramCode || null;
      }
      else {
        this.payAction = 'ALL';
      }
    });

    this.userId = this.authService.getUser().userId;

    // Balance Due or Conversion
    if (this.payAction === 'B' || this.payAction === 'C') {
      this.applicationsSummary = new Array<ApplicationSummary>();
      this.spinner.show();
      // Convert dashboardMetadata to AppSummary
      this.getAppSummaryFromDashboardMetadata(this.appId, this.payAction).subscribe(res => {
        if (res) {
          const convertedApp = res;
          this.applicationsSummary.push(convertedApp);
          convertedApp.addFeeInd = this.payAction;
          this.calculateTotal();
          this.calculateDueDate();
        }
      });
      this.spinner.hide();
    }
    else {
      this.spinner.show();

      this.purchaseSummaryService.getUnpaidApplications(this.userId, 'en').subscribe(res => {
        if (res) {
          this.applicationsSummary = res;
          this.completedApplications = res.length;

          if (this.applicationsSummary && this.applicationsSummary.length === 1 && this.applicationsSummary[0].programCode === 'SE') {
            this.isSESOnly = true;
          }

          this.calculateTotal();
          this.calculateDueDate();
        }

        this.spinner.hide();

      });
    }

    // Set up a callback to listen for a languae change to get new disclaimers
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateDueDate();
    });
  }
  // remove ABTC and recalculate fee
  removeABTC(appId: string) {

    const promptBody = 'PURCHASE_SUMMARY.ABTC_DELETE_PROMPT_BODY';
    const promptTitle = 'PURCHASE_SUMMARY.ABTC_DELETE_PROMPT_TITLE';
    const promptBtnNo = 'PURCHASE_SUMMARY.ABTC_DELETE_PROMPT_BUTTON_NO';
    const promptBtnYes = 'PURCHASE_SUMMARY.ABTC_DELETE_PROMPT_BUTTON_YES';

    this.modalService.confirm(promptBody, promptTitle, promptBtnNo, promptBtnYes)
    .then(res => {
      const userId = this.authService.getUser().userId;
      if (res) {
        this.applicationService.removeABTC(userId, appId).subscribe(res => {
            if (res) {
              this.applicationsSummary.forEach(app => {
                  if (app.id === appId) {
                      app.abtcAddon = false;
                      app.abtcFee = 0;
                  }
              });
              this.calculateTotal();
            }
        });
      }
    });
  }

  removeApplication(appId: string) {

    const promptBody = 'PURCHASE_SUMMARY.DELETE_APPLICATION_PROMPT_BODY';
    const promptTitle = 'PURCHASE_SUMMARY.DELETE_APPLICATION_PROMPT_TITLE';
    const promptBtnNo = 'PURCHASE_SUMMARY.DELETE_APPLICATION_PROMPT_BUTTON_NO';
    const promptBtnYes = 'PURCHASE_SUMMARY.DELETE_APPLICATION_PROMPT_BUTTON_YES';

    this.modalService.confirm(promptBody, promptTitle, promptBtnNo, promptBtnYes)
    .then(res => {
      if (res) {

        const userId = this.authService.getUser().userId;
        this.spinner.show();
        this.applicationService.cancelApplication(userId, appId).subscribe(deleteRes => {
          if (deleteRes) {
            for (let i = 0; i < this.applicationsSummary.length; i++) {
              if (appId === this.applicationsSummary[i].id) {
                // TODO Call backend delete method
                this.applicationsSummary.splice(i, 1);

                this.calculateTotal();
                if (this.applicationsSummary.length === 0){
                    this.router.navigate(['dashboard']);
                }
                break;
              }
            }
          }
          this.spinner.show();
        });
      }
    });
  }

  showPayLaterFAQ() {

    const promptBody = 'PURCHASE_SUMMARY.PAY_LATER_PROMPT_BODY';
    const promptTitle = 'PURCHASE_SUMMARY.PAY_LATER_PROMPT_TITLE';
    const promptBtn = 'PURCHASE_SUMMARY.PAY_LATER_PROMPT_BUTTON';

    this.modalService.notify(promptBody, promptTitle, promptBtn);
  }

  showAlreadyPaidFAQ(): void {
    $('#mdl-already-paid').modal('show');
  }

  calculateTotal() {
    this.totalFee = 0; // Reset the total
    for (const appSummary of this.applicationsSummary) {
      this.totalFee += appSummary.fee;
      if (appSummary.abtcFee)  {
        this.totalFee += appSummary.abtcFee;
      }
    }
  }

  calculateDueDate() {
    let oldest = new Date();

    // TODO loop through the certifiation date in the application summary
    for (const appSummary of this.applicationsSummary) {
      if (appSummary.certificationDate && oldest > appSummary.certificationDate)  {
        oldest = appSummary.certificationDate;
      }
    }

    oldest.setDate(oldest.getDate() + 30);
    this.dueDate = oldest;

    this.translateDueDate();
    // let dateArray: string[] = oldest.toDateString().split(" ");
    // this.dueDateDay = (dateArray[0]).toUpperCase();
    // this.dueDateMonth = (dateArray[1]).toUpperCase();
    // this.dueDateDate = (dateArray[2]);
    // this.dueDateYear = (dateArray[3]);
  }

  submitForm(): void {
    if (this.totalFee === 0) {
      if (!this.isSESOnly) {
        this.spinner.show();
        this.purchaseSummaryService.initiatePayment(this.userId, this.applicationsSummary, this.totalFee).subscribe(res => {
          this.spinner.hide();
        });
        Utils.scrollUp();
      }
      else if (this.isSESOnly && this.paymentNotice) {
        this.spinner.show();
        this.purchaseSummaryService.initiatePayment(this.userId, this.applicationsSummary, this.totalFee).subscribe(res => {
          this.spinner.hide();
        });
        Utils.scrollUp();
      }
      else {
        this.modalService.alert('ERROR_MESSAGES.GENERAL.ACKNOWLEDGEMENT_REQUIRED');
      }
    }
    // If discliamer button checked submit forms
    else if (this.paymentNotice) {

      const promptBody = 'PURCHASE_SUMMARY.PAY_NOW_PROMPT_BODY';
      const promptTitle = 'PURCHASE_SUMMARY.PAY_NOW_PROMPT_TITLE';
      const promptBtnNo = 'PURCHASE_SUMMARY.PAY_NOW_PROMPT_BUTTON_NO';
      const promptBtnYes = 'PURCHASE_SUMMARY.PAY_NOW_PROMPT_BUTTON_YES';

      this.modalService.confirm(promptBody, promptTitle, promptBtnNo, promptBtnYes)
        .then(res => {
            if (res) {
              this.spinner.show();
              this.purchaseSummaryService.initiatePayment(this.userId, this.applicationsSummary, this.totalFee).subscribe(res => {
                this.spinner.hide();
              });
              Utils.scrollUp();
            }
        });

    }
    else {
      this.modalService.alert('ERROR_MESSAGES.GENERAL.ACKNOWLEDGEMENT_REQUIRED');
    }
  }

  getAppSummaryFromDashboardMetadata(appId: string, payAction: string): Observable<ApplicationSummary> {
    const dashboardActiveApps = this.dashboardService.getActiveApplications();
    let appSummary: ApplicationSummary = null;

    for (const app of dashboardActiveApps) {
      if (app.applicationId === appId) {
        appSummary = new ApplicationSummary({
          applicationId : app.applicationId
        });
        // appSummary.id = app.applicationId;
        appSummary.programCode = app.programs[0]; // This needs to be fixed
        appSummary.appType = app.applicationType;
        appSummary.source = app.source;
        break;
      }
    }

    if (appSummary) {
        return this.purchaseSummaryService.getProgramFeeList(this.payAction, appSummary, this.userId).pipe(map(res => {
          if (res) {

            // We want to display what we are converting to
            if (payAction === 'C') {
              appSummary.preConvertProgramCode = appSummary.programCode;
              appSummary.programCode = 'UP';
            }

            appSummary.feeList = res;
            // console.log('Fee List:', appSummary.feeList);

            appSummary.fee = 0;
            appSummary.abtcFee = 0;

            for (const programFee of appSummary.feeList) {
              if (this.authService.getUser().age >=  programFee.minAge) {
                if (programFee.programCode === 'AB') {
                    appSummary.abtcAddon = true;
                    appSummary.abtcFee = programFee.amount;
                }
                else {
                  appSummary.programCode = programFee.programCode;
                  appSummary.fee = programFee.amount;
                }
              }
            }
            appSummary = this.purchaseSummaryService.addDisplayData(appSummary);

            return appSummary;
          }
          else {
            this.router.navigate(['dashboard']);
          }
          return null;
        }));

        // break;
      }
    return of(appSummary);
    }

    /**
     * Takes the program(s) and applicationType to determine what to display for the application for
     * field.
     *
     * If the application type is Vehicle Enrollment only display {Vehicle Enrollment}. If Renewal,
     * Reapplication or Card Replacement display {programs applicationType}
     *
     * @param  {Array<string>} programs        [description]
     * @param  {string}        applicationType [description]
     * @return {string}                        [description]
     */
    public getProgramHeader(appSummary: ApplicationSummary): string {
      let translatedApplicationFor = '';
      let programNames = '';

      if (appSummary.appType === 'VE') {
        return this.refService.getApplicationType(appSummary.appType);
      }
      else {
        programNames += this.refService.getProgramName(appSummary.programCode);

        translatedApplicationFor += programNames;

        if (appSummary.appType !== 'IE') {
          translatedApplicationFor += ' ' + this.refService.getApplicationType(appSummary.appType);
        }
      }

      return translatedApplicationFor;
    }

    /**
     * Returns the dislcaimer text for the fee checkbox.
     *
     * @return {string} [description]
     */
    public getFeeConfirmationDisclaimer(): string {
      let feeConfirmation = 'PURCHASE_SUMMARY.FEE_CONFIRMATION';

      if (this.isSESOnly) {
          feeConfirmation =  'PURCHASE_SUMMARY.KOREA_SES_FEE_CONFIRMATION';
      }

      return feeConfirmation;
    }

    public cancel(): void {
        this.router.navigate(['dashboard']);
    }

    /**
     * The amount of units for a program in a application.
     *
     * NOTE: Only relevant to vehicles.
     *
     * @param  {ApplicationSummary} appSummary [description]
     * @return {number}                        [description]
     */
    public getQuantity(appSummary: ApplicationSummary): number {
      let quantity = 0;

      if (appSummary && appSummary.appType === 'VE') {
        quantity = appSummary.feeList[0].unit;
      }
      else {
        quantity = 1;
      }

      return quantity;
    }

    /**
     * The amount per item in the application.
     *
     * NOTE: Only getting amount from feeList for vehicle application type.
     *
     * @param  {ApplicationSummary} appSummary [description]
     * @return {[type]}                        [description]
     */
    public getPerUnitAmount(appSummary: ApplicationSummary): number {
      let amount = 0;

      if (appSummary) {

        if (appSummary.appType === 'VE') {
          amount = appSummary.feeList[0].amount;
        }
        else {
          amount = appSummary.fee;
        }
      }

      return amount;
    }

    translateDueDate() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
      this.translatedDueDate = this.dueDate.toLocaleDateString(this.translate.currentLang, options);
    }
}
