<div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="title | translate"></h4>
      </div>
      <div class="modal-body">
        <p [innerHTML]="translateSrc | translate"></p>
      </div>
      <div class="modal-footer">
  	  	<div class="col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        	<button type="button" class="btn btn-block btn-primary" data-dismiss="modal">{{buttonText | translate}}</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal --> 
