import { Component, Input, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'need-help-modal',
  templateUrl: 'need-help.component.html'
})

export class NeedHelpComponent implements OnInit {

	@Input()
	id:string;

	screenShotInfoShown:boolean;

	ngOnInit(): void {
		this.screenShotInfoShown = false;
	}

	showScreenShotInfo(): void {
		this.screenShotInfoShown = true;
	}

	showHelpInfo(): void {
		this.screenShotInfoShown = false;
	}

	reset():void {
		this.screenShotInfoShown = false;
	}

}
