import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleService } from '../../common/services/schedule.service';
import { AuthService } from '../../common/services/auth.service';
import { AppointmentModel } from '../../common/models/appointments/appointment.model';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';
import { User } from '../../common/models/user';
import { ApplicationMetadata } from '../../common/models/application-metadata.model';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import Utils from '../../common/utils/utils';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import * as moment from 'moment-timezone';

@Component({
    moduleId: module.id,
    templateUrl: 'interview-cancellation.component.html'
})
export class InterviewCancellationComponent implements OnInit {

    public appointmentModel: AppointmentModel = null;
    public applicationMetadata: ApplicationMetadata = null;
    public user: User = new User();
    public programName: string;
    protected programId: string = '';
    public month: string;
    public dayOfWeek: string;
    private cancellationDate: Date;
    moment: any;    // helps to get time zone

    constructor(private router: Router,
                private route: ActivatedRoute,
                private scheduleService: ScheduleService,
                private referenceService: TranslateReferenceService,
                private authService: AuthService,
                private spinner: SpinnerService,
                private calService: CalendarService) {
                    this.moment = moment;
                 }

    ngOnInit() {
        // this.setTestData();

        if (this.scheduleService.focusedAppointmentApp != null) {
            this.setup();
        } else {
            // appointment data has not been gathered so redirect
            this.returnHome();
        }
            // Set up a callback to listen for a languae change to get new disclaimers

    }

    private setup(){
        this.spinner.show();
        this.applicationMetadata = this.scheduleService.focusedAppointmentApp;
        this.programId = this.applicationMetadata.programs.length > 0 ? this.applicationMetadata.programs[0] : '';
        this.user = this.authService.getUser();
        this.scheduleService.getAppointment(this.scheduleService.focusedAppointmentApp.applicationId,
            this.scheduleService.focusedAppointmentApp.source)
            .subscribe(
                (res: AppointmentModel) => {
                    this.spinner.hide();
                    this.appointmentModel = res;

                    this.dayOfWeek = this.calService.getDayDisplayName(this.appointmentModel.lastUpdatedDateObject);
                    this.cancellationDate = this.appointmentModel.lastUpdatedDateObject;

                    this.month = 'GENERAL_REUSABLE.MONTH.';
                    switch (this.cancellationDate.getMonth() + 1) {
                        case 1:
                            this.month += 'JAN';
                            break;
                        case 2:
                            this.month += 'FEB';
                            break;
                        case 3:
                            this.month += 'MAR';
                            break;
                        case 4:
                            this.month += 'APR';
                            break;
                        case 5:
                            this.month += 'MAY';
                            break;
                        case 6:
                            this.month += 'JUN';
                            break;
                        case 7:
                            this.month += 'JUL';
                            break;
                        case 8:
                            this.month += 'AUG';
                            break;
                        case 9:
                            this.month += 'SEP';
                            break;
                        case 10:
                            this.month += 'OCT';
                            break;
                        case 11:
                            this.month += 'NOV';
                            break;
                        case 12:
                            this.month += 'DEC';
                            break;
                    }

                }, error => {
                    this.spinner.hide();
                }
            );
        // let hasErrors: boolean = false;
        this.setupReferenceData();
    }

    protected setupReferenceData(){
        this.programName = this.referenceService.getProgramName(this.programId);
    }

    // button events

    public onDone(event: any){
        this.router.navigate(['/dashboard']);
    }

    public openPrintView(event: any){
        //window.print();
        Utils.openPrintView();
    }

    protected returnHome(){
        this.router.navigate(['/']);
    }

    protected onReschedule(event: any){
        //console.warn('todo: onReschedule (call service to redirect)');
    }
}
