import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, ActivatedRoute } from '@angular/router';
import { Title }     from '@angular/platform-browser';

import { filter, map, mergeMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './shared/spinner/spinner.service';
import { Observable } from 'rxjs';
import { AuthService } from './common/services/auth.service';

import Utils from './common/utils/utils';
import { environment } from '../environments/environment';
import { SETTINGS } from './app.constants';

declare var ga: Function;

/**
 * This class represents the main application component. Within the @Routes annotation is the configuration of the
 * applications routes, configuring the paths for the lazy loaded components (HomeComponent, AboutComponent).
 */
@Component({
  moduleId: module.id,
  selector: 'go-app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {

  private url : Observable<string>;
  private routeName : string;

  constructor(private translate: TranslateService,
               private authService: AuthService,
               private route : ActivatedRoute,
               private spinner : SpinnerService,
               private router : Router,
               private titleService: Title) {

    //scroll up and spin on route change
    this.router.events.subscribe((event : any) => {
       if (event instanceof NavigationStart) {
         this.spinner.show();
       }
       if (event instanceof NavigationEnd) {
         this.spinner.hide();
         if (this._canScrollUp(this.router.url)) {
           Utils.scrollUp();
         }
       }
       if (event instanceof NavigationCancel || event instanceof NavigationError) {
         this.spinner.hide();
       }
    });

    // Check for the existence of the reentry token; if there logout
    // if (this.authService.getReentryToken()) {
    //     this.authService.logOut(SETTINGS.LOGINGOV_TOKEN_NAME);
    // }

    // kick off translation
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let navigator = <any>window.navigator;
    translate.use(Utils.getPreferredLanguage(navigator.languages));
    //  //console.log('Environment config', Config);

    // if language changes, then change the lang attribute
    this.translate.onLangChange.subscribe((event : any)=> {
       let htmlTag = document.getElementsByTagName("html");
       if(htmlTag && htmlTag.length > 0){
         htmlTag[0].setAttribute('lang', event.lang);
       }
    });

    // send the page views to google analytics after routing
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (typeof ga !== undefined && typeof ga !== 'undefined' && ga != null && event.urlAfterRedirects === "/") {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      }
    });

  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route:any) => route.outlet === 'primary'),
      mergeMap((route:any) => route.data)
    ).subscribe((event) => this.setTitle(event['title']));


    if (environment.underMaintenance) {
         this.router.navigate(['under-maintenance']);
    }
    else {
        this.router.initialNavigation();
    }
    window.addEventListener("dragover",function(e:any){
      e = e || event;
      e.preventDefault();
    },false);
    window.addEventListener("drop",function(e:any){
      e = e || event;
      e.preventDefault();
    },false);
  }

  private _canScrollUp(url: string): boolean {
    return url !== "/" && url !== "/#program-grid" && url.indexOf("/faq") === -1;
  }

  public setTitle( newTitle: string ) {
    if (newTitle) {
      this.translate.get(newTitle).subscribe(res=> {
        this.titleService.setTitle( 'TTP ' + res );
      });
    } else {
      this.titleService.setTitle( 'Official Trusted Traveler Program Website | Department of Homeland Security' );
    }
  }
}
