import { Component, Input } from '@angular/core';
import { ModalService } from '../modal.service';

declare var $ : any;

/**
 * This class represents the notify (service version of info) modal component
 */
@Component({
  moduleId: module.id,
  selector: 'notify-modal',
  templateUrl: 'notify-modal.component.html'
})

export class NotifyModalComponent {


    private _confirmBtn: HTMLElement;
    translateSrc: string;
    title: string;
    buttonText: string;

    constructor(modalService: ModalService) {

        modalService.notify = this.notify.bind(this);
    }

    ngOnInit() {
        this._confirmBtn = document.getElementById('notifyBtn');
    }

    notify(msgKey: string, title: string, buttonText: string): Promise<boolean> {

        this.translateSrc = msgKey;
        this.title = title;
        this.buttonText = buttonText;
         $('#notifyInfo').modal();
        let promise = new Promise < boolean > (resolve => {
            this._configureNotifyModal(resolve);
        });
        return promise;
    }

    private _configureNotifyModal(resolve: (x: boolean) => any) {
        let confirmed = (e: any) => resolve(true);
        this._confirmBtn.onclick = ((e: any) => {
            e.preventDefault();
            confirmed(e);
            $('#notifyInfo').modal('hide');
        });

    }

}
