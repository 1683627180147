
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewsService } from '../common/services/news.service';
import { AuthService } from '../common/services/auth.service';
declare var $:any;


/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    moduleId: module.id,
    selector: 'announcements',
    templateUrl: 'announcements.component.html'
  })

export class AnnouncementsComponent implements OnInit {

    goldBarItems: any[] = new Array();

    alertTitle: string;
    alertContent: string;
    showAnnouncements:boolean = true;

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private newsService : NewsService
      ) {
    }

    ngOnInit() {
        // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
            // navigator.languages isn't in all browsers yet, so cast
            // the type to make typescript happy.
            // let navigator = <any>window.navigator;
        // let langKey = this.getPreferredLanguage(navigator.languages);

        //if language selection is changed, fetch relevant news.
        this.newsService.newsUpdatedSubj.subscribe(res => {

          var localGoldBarItems:any = [];
          this.newsService.getNews().subscribe((res:any)=> {
              let tempItems = res[this.translate.currentLang];

              if (tempItems && tempItems.length > 0) {
                for (let news of tempItems) {
                  if (news.goldBarInd) {
                    localGoldBarItems.push(news);
                  }
                }

                this.goldBarItems = localGoldBarItems;
              }
            });
        });
    }

    isUserLoggedIn() {
      return this.authService.isUserLoggedIn();
    }

    showAlertContent(goldBarItem: any) {
      if (goldBarItem) {
        if (goldBarItem.name) {
          this.alertTitle = goldBarItem.name;

          if (goldBarItem.content) {
            this.alertContent = goldBarItem.content;
          }
        }

        $('#alert-info-modal').modal('show');
      }
    }
}
