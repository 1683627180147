import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';
import { environment } from '../../../environments/environment';
import { SETTINGS } from '../../app.constants';

declare var $: any;

/**
 * This class represents the information modal component
 */
@Component({
  moduleId: module.id,
  selector: 'check-int-avail-modal',
  templateUrl: 'check-int-avail-modal.component.html'
})


export class CheckInterviewAvailModalComponent {

	@Input()
	id: string;

	globalEntryName: string;
  nexusName: string;
  sentriName: string;
  fnName: string;
  fsName: string;

	schedulerViewLocationsUrl: string;

	constructor(private translate: TranslateService,
							private refService: TranslateReferenceService,
		) {
			this.updateProgramNames();

			// Initialize the program names in the specified language
			this.refService.translationSwitchedSubj.subscribe(res => {
				this.updateProgramNames();
			});

			// set the scheduler view location url and also update it when language changes
			this.schedulerViewLocationsUrl = environment.uriScheduler + "schedule-interview/location?lang=" + this.translate.currentLang + "&vo=true&returnUrl=" + SETTINGS.baseReturnService + "&service=";
			this.translate.onLangChange.subscribe((event: any) => {
				this.schedulerViewLocationsUrl = environment.uriScheduler + "schedule-interview/location?lang=" + this.translate.currentLang + "&vo=true&returnUrl=" + SETTINGS.baseReturnService + "&service=";
			});
	}

	private updateProgramNames() {
		this.globalEntryName = this.refService.getProgramName('UP');
		this.nexusName = this.refService.getProgramName('NH');
		this.sentriName = this.refService.getProgramName('SH');
		this.fnName = this.refService.getProgramName('FN');
		this.fsName = this.refService.getProgramName('FS');
	}
}
