import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class SpinnerService {
    public spinnerObservable: Observable<boolean>;
    private spinnerObserver: Observer<boolean>;
    private timer: NodeJS.Timer[] = new Array<NodeJS.Timer>();

    constructor() {
        this.spinnerObservable = new Observable<boolean>((observer:any) => {
                this.spinnerObserver = observer;
            }
        ).pipe(share());
    }

    show() {
        if (this.spinnerObserver) {
            this.spinnerObserver.next(true);
            // wait 60 seconds and if the spinner is still going
            // shut it off
            this.timer.push(setTimeout(() => {
                this.timer.pop();
                if (this.timer.length <= 0) {
                    // only shut off the spinner if this was the
                    // last call to the spinner
                    this.spinnerObserver.next(false);
                }
            }, 60000));
        }
    }

    hide() {
        if (this.spinnerObserver) {
            clearTimeout(this.timer.shift());
            // don't shut off the spinner untill all
            // calls that initiated the spinner come through
            if (this.timer.length <= 0) {
                this.spinnerObserver.next(false);
            }
        }
    }
}
