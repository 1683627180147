  <div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="'PROGRAM_SELECTION.LEAVING_SITE_WARNING' | translate"></h4>
      </div>
      <div class="modal-body">
        <div>
          <p [innerHTML]= "'PROGRAM_SELECTION.CONTINUE_TO_TSA_PRECHECK_WARNING' | translate"></p>
        </div>
      </div>
      <div class="modal-footer">
          <div class="modal-program-button">
              <div class="col-md-12text-center">
                <button type="button" id="continueToTSABtn" class="btn btn-primary"  
                  (click)="confirmGoToTSAPrecheck()" data-dismiss="modal" [innerHTML]="'PROGRAM_SELECTION.CONTINUE_TO_TSA_PRECHECK' | translate"></button>
              </div>      
          </div>
        </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->