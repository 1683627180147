<div class="program-widget">
    <div class="tt-comparison-page" id="tt-comparison-page">
      <div class="tt-page-wrap">
        <section class="tt-site-panel" id="panel-cards">
          <div class="tt-wrap container">


            <div *ngIf="bestMatchCard && bestMatchCard.programAbbr !== 'tsaprecheck'" class="tt__best-match" id="best-match-placeholder">
              <h2>{{recommendationTxt | translate}}</h2>
                <p [ngClass]="bestMatchCard ? 'recommendation--' + bestMatchCard.programAbbr : ''" [innerHTML]="bestMatchTxt | translate">
                </p>
            </div>

            <div *ngIf="bestMatchCard && bestMatchCard.programAbbr == 'tsaprecheck'" class="tt__best-match" id="best-match-placeholder">
              <h2>{{recommendationTxt | translate}}</h2>
              <div class="recommendation--tsaprecheck">
                <div>TSA PreCheck<sup>&reg;</sup></div>
                <div [innerHTML]="bestMatchTxt | translate"></div>
              </div>

            </div>

            <!-- Best Match Card -->
            <div *ngIf="bestMatchCard" class="tt-card best-match" [ngClass]="{'best-match--promo': isPromoCountry === true}"
              id="{{bestMatchCard.id}}">
              <h2 *ngIf="!isPromoCountry" id="best-match-header" class="best-match-header" translate>HOME.BEST_MATCH_HEADER</h2>
              <h2 *ngIf="isPromoCountry" id="best-match-header" class="best-match-header" translate>HOME.PROMO_COUNTRY_BEST_MATCH_HEADER</h2>

              <div class="tt-card__primary-section">
                <header class="tt-card-header">
                  <h2 class="tt-card-header__primary" [innerHTML]="bestMatchCard.programName | translate"></h2>
                  <div class="tt-price">
                    <p *ngIf="bestMatchCard.programAbbr == 'tsaprecheck'" class="tt-price__amount">{{'MODAL.FROM' | translate}} {{bestMatchCard.price | currency: 'USD': 'symbol'}} <span class="tt-price__currency">USD</span></p>
                    <p *ngIf="bestMatchCard.programAbbr != 'tsaprecheck'" class="tt-price__amount">{{bestMatchCard.price | currency: 'USD': 'symbol'}} <span class="tt-price__currency">USD</span></p>
                    <p class="tt-price__description" translate>HOME.BEST_MATCH_RESULTS.MEMBERSHIP_FEE</p>
                    <p *ngIf="bestMatchCard.programAbbr == 'tsaprecheck'" translate>MODAL.NEW_ENROLLMENT</p>
                  </div>
                </header>
                <div class="tt-card__blocks">
                  <div class="tt-card__block">
                    <h3 class="tt-program-highlight">{{ bestMatchCard.section1.programHighlight  | translate}}</h3>
                    <p>
                      <span [innerHTML]="bestMatchCard.section1.programHighlightDesc | translate"></span>
                      <tooltip *ngIf="bestMatchCard.programAbbr == 'fast'" [tooltip-text] ="'HOME.BEST_MATCH_RESULTS.FAST_PROGRAM_HIGHLIGHT_TOOLTIP'"></tooltip>
                    </p>
                  </div>
                  <div class="tt-card__block" *ngIf="bestMatchCard.middleSection">
                    <h3 translate>{{ bestMatchCard.middleSection.header }}</h3>
                    <p [innerHTML]="bestMatchCard.middleSection.text | translate"></p>
                  </div>
                  <div class="tt-card__block">
                    <h4 translate>HOME.REQUIRED_DOCUMENT</h4>
                    <div class="{{ bestMatchCard.section2.documentClass }}">
                      <img alt="{{ bestMatchCard.section2.imgAlt | translate }}" src="{{ bestMatchCard.section2.imgSrc}}" /></div>
                    <p [innerHTML]="bestMatchCard.section2.imgDescription | translate"></p>
                  </div>
                </div>
                <div class="tt-card__actions">
                  <a [attr.id]="bestMatchCard.cardExpandableId + '-link'" (click)="toggleDetails(bestMatchCard.cardExpandableId)" (keyup.enter)="toggleDetails(bestMatchCard.cardExpandableId)" aria-expanded="false" class="tt-card__toggle" tabindex="0" role="button">
                    <span aria-hidden="true" class="tt-card__toggle__affordance">+</span>
                    <span translate>HOME.BEST_MATCH_RESULTS.VIEW_MORE_DETAILS</span>
                  </a>

                  <a class="tt-btn tt-enroll-btn" *ngIf="bestMatchCard.ttpLogin" (click)="logSelected(bestMatchCard.programAbbr); logIn();" (keyup.enter)="logSelected(bestMatchCard.programAbbr); logIn();" tabindex="0" role="button" translate>HOME.BEST_MATCH_RESULTS.ENROLL_NOW</a>
                  <a class="tt-btn tt-enroll-btn" *ngIf="!bestMatchCard.ttpLogin" [attr.href]="bestMatchCard.actionHref" (click)="logSelected(bestMatchCard.programAbbr);" (keyup.enter)="logSelected(bestMatchCard.programAbbr);"
                    target="_blank" tabindex="0" role="button" translate>HOME.BEST_MATCH_RESULTS.ENROLL_NOW</a>
                </div>
              </div>
              <div class="tt-card__secondary-section collapsed" [attr.id]="bestMatchCard.cardExpandableId">
                <div class="tt-card__blocks">
                  <div class="tt-card__block">
                    <h3 translate>HOME.BEST_MATCH_RESULTS.APPLICATION_PROCESS_TEXT</h3>
                    <div class="application-process">
                      <div class="application-process__item1">
                        <i class="fa fa-desktop circle_for_fa" aria-hidden="true"></i>
                        <h4 translate>HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY_HEADER</h4>
                        <p *ngIf="bestMatchCard.ttpLogin">{{ 'HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY' |
                          translate}} <a (click)="logSelected(bestMatchCard.programAbbr); logIn();" (keyup.enter)="logSelected(bestMatchCard.programAbbr); logIn();" tabindex="0" role="button" translate>HOME.BEST_MATCH_RESULTS.ONLINE</a>.
                        </p>
                        <p *ngIf="!bestMatchCard.ttpLogin" translate>{{ 'HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY' |
                          translate}} <a target="_blank" href="{{ bestMatchCard.appProcessHref }}" (click)="logSelected(bestMatchCard.programAbbr);" (keyup.enter)="logSelected(bestMatchCard.programAbbr);" tabindex="0" translate>HOME.BEST_MATCH_RESULTS.ONLINE</a>.</p>
                      </div>
                      <div class="application-process__item2">
                        <i class="fa fa-user circle_for_fa" aria-hidden="true"></i>
                        <h4 translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW_HEADER</h4>
                        <p *ngIf="bestMatchCard.programAbbr !== 'global-entry'" translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW</p>
                        <p *ngIf="bestMatchCard.programAbbr == 'global-entry'" translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW_UP</p>

                        <p *ngIf="bestMatchCard.programAbbr == 'global-entry'">
                          <!--/* //NOSONAR */--><a tabindex="0" href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-arrival"
                            target="_blank" rel="noopener" translate>HOME.BEST_MATCH_RESULTS.FIND_EOA_LOCATION</a>
                        </p>
                        <p *ngIf="bestMatchCard.programAbbr !== 'fast'">
                          <a *ngIf="bestMatchCard.programAbbr !== 'nexus'" tabindex="0" href="{{ bestMatchCard.findEnrollmentCenterLink }}"
                            target="_blank" translate>HOME.BEST_MATCH_RESULTS.FIND_ENROLLMENT_CENTER</a>
                          <a *ngIf="bestMatchCard.programAbbr == 'nexus'" tabindex="0" href="{{ bestMatchCard.findEnrollmentCenterLink }}"
                            target="_blank" translate>HOME.BEST_MATCH_RESULTS.FIND_ENROLLMENT_CENTER_NX</a>
                        </p>
                        <div *ngIf="bestMatchCard.programAbbr == 'fast'">
                          <br>
                          <p>
                            <span translate>HOME.BEST_MATCH_RESULTS.ENROLLMENT_CENTERS</span>:<br>
                            <a tabindex="0" [attr.href]="this.schedulerViewLocationsUrl + 'fs'" target="_blank" translate>HOME.BEST_MATCH_RESULTS.ENROLLMENT_CENTERS_US_MEXICO</a>
                            <a tabindex="0" [attr.href]="this.schedulerViewLocationsUrl + 'fn'" target="_blank" translate>HOME.BEST_MATCH_RESULTS.ENROLLMENT_CENTERS_US_CANADA</a>
                          </p>
                        </div>
                      </div>
                      <div class="application-process__item3">
                        <i class="fa fa-id-card-o circle_for_fa" aria-hidden="true"></i>
                        <h4 translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP_HEADER</h4>
                        <p translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP</p>
                      </div>
                    </div>
                    <p><em [innerHTML]="bestMatchCard.membershipNote | translate"></em></p>

                    <h3 translate>HOME.BEST_MATCH_RESULTS.ELIGIBLITY</h3>
                    <p *ngIf="bestMatchCard.programAbbr === 'global-entry'" translate>{{ globalTravelEligibilityTxt }}</p>
                    <p *ngIf="bestMatchCard.programAbbr !== 'global-entry'" translate>{{ bestMatchCard.eligibility }}</p>

                    <p *ngIf="bestMatchCard.programAbbr === 'tsaprecheck'">
                        <a target="_blank" [attr.href]="bestMatchCard.learnMoreLink" target="_blank" [innerHTML]="'HOME.BEST_MATCH_RESULTS.TSAPRE_LEARNMORE_AT' | translate"></a>
                    </p>
                    <p *ngIf="bestMatchCard.programAbbr !== 'tsaprecheck'">
                        <!-- <a target="_blank" [attr.href]="bestMatchCard.learnMoreLink" target="_blank" translate [translateParams]="{value: bestMatchCard.programName}">HOME.BEST_MATCH_RESULTS.LEARNMORE_AT</a> -->
                        <a target="_blank" [attr.href]="bestMatchCard.learnMoreLink" target="_blank">{{ 'HOME.BEST_MATCH_RESULTS.LEARNMORE_AT' | translate: {value: bestMatchCard.programName} }}</a>
                      </p>
                  </div>
                  <div class="tt-card__block">
                    <h3 translate>HOME.BEST_MATCH_RESULTS.FEATURED_BENEFITS</h3>
                    <ul class="tt-featured-benefits">
                      <li *ngFor="let fb of bestMatchCard.featuredBenefits" class="tt-featured-benefits__item">
                        <span [innerHTML]="fb.benefit | translate"></span>
                        <tooltip *ngIf="fb.tooltip" class="no-float" [tooltip-text] ="fb.tooltip"></tooltip>
                      </li>
                    </ul>

                    <h3 *ngIf="bestMatchCard.additionalBenefits" translate>HOME.BEST_MATCH_RESULTS.ADDITIONAL_BENEFITS</h3>
                    <ul *ngIf="bestMatchCard.additionalBenefits">
                      <li *ngFor="let ab of filterExclusionsFromBenefits(bestMatchCard.programAbbr)">
                          <span [innerHTML]="ab.text | translate"></span>
                          <tooltip *ngIf="ab.tooltip" class="no-float" [tooltip-text] ="ab.tooltip"></tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <div *ngIf="bestMatchCard && cards.length >= 1">
              <h2 class="other-programs-header" id="other-programs-header" translate>HOME.OTHERPROGRAMS_BESTMATCH_TEXT</h2>
            </div>
            <div class="tt-cards" id="sortableCards">

              <!-- Other Programs -->
              <!-- <div *ngFor="let card of cards; let i = index"> -->
              <div *ngFor="let card of cards; let i = index" class="tt-card" id="{{card.id}}" [ngClass]="otherProgramsClass">
                <div class="tt-card__primary-section">
                  <header class="tt-card-header">
                    <h2 class="tt-card-header__primary" [innerHTML]="card.programName | translate"></h2>
                    <div class="tt-price">  
                      <p *ngIf="card.programAbbr == 'tsaprecheck'" class="tt-price__amount">{{'MODAL.FROM' | translate}} {{card.price | currency: 'USD': 'symbol'}} <span class="tt-price__currency">USD</span></p>
                      <p *ngIf="card.programAbbr != 'tsaprecheck'" class="tt-price__amount">{{card.price | currency: 'USD': 'symbol'}} <span class="tt-price__currency">USD</span></p>
                      <p class="tt-price__description" translate>HOME.BEST_MATCH_RESULTS.MEMBERSHIP_FEE</p>
                      <p *ngIf="card.programAbbr === 'tsaprecheck'" translate>MODAL.NEW_ENROLLMENT</p>
                    </div>
                  </header>
                  <div class="tt-card__blocks">
                    <div class="tt-card__block">
                      <h3 class="tt-program-highlight" [innerHTML]="card.section1.programHighlight | translate"></h3>
                      <p>
                        <span [innerHTML]="card.section1.programHighlightDesc | translate"></span>
                        <tooltip *ngIf="card.programAbbr == 'fast'" [tooltip-text] ="'HOME.BEST_MATCH_RESULTS.FAST_PROGRAM_HIGHLIGHT_TOOLTIP'"></tooltip>
                      </p>

                    </div>
                    <div class="tt-card__block" *ngIf="card.middleSection">
                      <h3 translate>{{ card.middleSection.header }}</h3>
                      <p [innerHTML]="card.middleSection.text | translate"></p>
                    </div>
                    <div class="tt-card__block">
                      <h4 translate>HOME.REQUIRED_DOCUMENT</h4>
                      <div class="{{ card.section2.documentClass }}">
                        <img alt="{{ card.section2.imgAlt | translate }}" src="{{ card.section2.imgSrc }}" /></div>
                      <p [innerHTML]="card.section2.imgDescription | translate"></p>
                    </div>
                  </div>
                  <div class="tt-card__actions">
                    <a [attr.id]="card.cardExpandableId + '-link'" (click)="toggleDetails(card.cardExpandableId)" (keyup.enter)="toggleDetails(card.cardExpandableId)" aria-expanded="false" class="tt-card__toggle" tabindex="0" role="button">
                      <span aria-hidden="true" class="tt-card__toggle__affordance">+</span>
                      <span translate>HOME.BEST_MATCH_RESULTS.VIEW_MORE_DETAILS</span>
                    </a>

                    <a class="tt-btn tt-enroll-btn" *ngIf="card.ttpLogin" (click)="logSelected(card.programAbbr); logIn();" (keyup.enter)="logSelected(card.programAbbr); logIn();" tabindex="0" role="button" translate>HOME.BEST_MATCH_RESULTS.ENROLL_NOW</a>
                    <a class="tt-btn tt-enroll-btn" *ngIf="!card.ttpLogin" (click)="logSelected(card.programAbbr);" (keyup.enter)="logSelected(card.programAbbr);" [attr.href]="card.actionHref" target="_blank" tabindex="0" role="button"
                      translate>HOME.BEST_MATCH_RESULTS.ENROLL_NOW</a>
                  </div>
                </div>
                <div class="tt-card__secondary-section collapsed" [attr.id]="card.cardExpandableId">
                  <div class="tt-card__blocks">
                    <div class="tt-card__block">
                      <h3 translate>HOME.BEST_MATCH_RESULTS.APPLICATION_PROCESS_TEXT</h3>
                      <div class="application-process">
                        <div class="application-process__item1">
                          <i class="fa fa-desktop circle_for_fa" aria-hidden="true"></i>
                          <h4 translate>HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY_HEADER</h4>
                          <p *ngIf="card.ttpLogin" translate>{{ 'HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY' |
                            translate}} <a (click)="logSelected(card.programAbbr); logIn();" (keyup.enter)="logSelected(card.programAbbr); logIn();" tabindex="0" role="button" translate>HOME.BEST_MATCH_RESULTS.ONLINE</a>.</p>
                          <p *ngIf="!card.ttpLogin" translate>{{ 'HOME.BEST_MATCH_RESULTS.VIEW_MORE_CARD_APPLY' |
                            translate}} <a target="_blank" href="{{ card.appProcessHref }}" (click)="logSelected(card.programAbbr);" (keyup.enter)="logSelected(card.programAbbr);" translate>HOME.BEST_MATCH_RESULTS.ONLINE</a>.</p>
                        </div>

                        <div class="application-process__item2">
                          <i class="fa fa-user circle_for_fa" aria-hidden="true"></i>
                          <h4 translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW_HEADER</h4>
                          <p *ngIf="card.programAbbr !== 'global-entry'" translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW</p>
                          <p *ngIf="card.programAbbr == 'global-entry'" translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_INTERVIEW_UP</p>

                          <p *ngIf="card.programAbbr == 'global-entry'">
                            <!--/* //NOSONAR */--><a tabindex="0" href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-arrival"
                            target="_blank" rel="noopener" translate>HOME.BEST_MATCH_RESULTS.FIND_EOA_LOCATION</a><br>
                          </p>
                          
                          <p>
                            <a *ngIf="card.programAbbr !== 'nexus'" href="{{ card.findEnrollmentCenterLink }}" tabindex="0" target="_blank"
                              translate>HOME.BEST_MATCH_RESULTS.FIND_ENROLLMENT_CENTER</a>
                            <a *ngIf="card.programAbbr == 'nexus'" href="{{ card.findEnrollmentCenterLink }}" tabindex="0" target="_blank"
                              translate>HOME.BEST_MATCH_RESULTS.FIND_ENROLLMENT_CENTER_NX</a>
                          </p>
                        </div>
                        <div class="application-process__item3">
                          <i class="fa fa-id-card-o circle_for_fa" aria-hidden="true"></i>
                          <h4 translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP_HEADER</h4>
                          <p translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP</p>
                        </div>
                      </div>
                      <p><em [innerHTML]="card.membershipNote | translate"></em></p>

                      <h3 translate>HOME.BEST_MATCH_RESULTS.ELIGIBLITY</h3>
                      <p *ngIf="card.programAbbr === 'global-entry'" translate>{{ globalTravelEligibilityTxt }}</p>
                      <p *ngIf="card.programAbbr !== 'global-entry'" translate>{{ card.eligibility }}</p>
                      <p *ngIf="card.programAbbr === 'tsaprecheck'">
                          <a target="_blank" [attr.href]="card.learnMoreLink" target="_blank" [innerHTML]="'HOME.BEST_MATCH_RESULTS.TSAPRE_LEARNMORE_AT' | translate"></a>
                      </p>
                      <p *ngIf="card.programAbbr !== 'tsaprecheck'">
                          <!-- <a target="_blank" [attr.href]="card.learnMoreLink" target="_blank" translate [translateParams]="{value: card.programName}">HOME.BEST_MATCH_RESULTS.LEARNMORE_AT</a> -->
                          <a target="_blank" [attr.href]="card.learnMoreLink" target="_blank">{{ 'HOME.BEST_MATCH_RESULTS.LEARNMORE_AT' | translate: {value: card.programName} }}</a>
                        </p>
                      </div>
                    <div class="tt-card__block">
                      <h3 translate>HOME.BEST_MATCH_RESULTS.FEATURED_BENEFITS</h3>
                      <ul class="tt-featured-benefits">
                        <li *ngFor="let fb of card.featuredBenefits" class="tt-featured-benefits__item">
                          <span [innerHTML]="fb.benefit | translate"></span>
                          <tooltip *ngIf="fb.tooltip" class="no-float" [tooltip-text] ="fb.tooltip"></tooltip>

                          <!-- <span class="infoIcon"><a aria-expanded="false" aria-label="Show infoIcon" class="infoIcon__icon" href="#">i</a><span class="infoIcon__text"> </span></span>  -->
                        </li>
                      </ul>

                      <h3 *ngIf="card.additionalBenefits" translate>HOME.BEST_MATCH_RESULTS.ADDITIONAL_BENEFITS</h3>
                      <ul *ngIf="card.additionalBenefits">
                        <li *ngFor="let ab of filterExclusionsFromBenefits(card.programAbbr)">
                            <span [innerHTML]="ab.text | translate"></span>
                            <tooltip *ngIf="ab.tooltip" class="no-float" [tooltip-text] ="ab.tooltip"></tooltip>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->

            </div>
          </div>
        </section>
      </div>
<!--
          <div class="return-button row row-centered">
              <div class="col-xs-12 col-centered" style="margin-bottom: 0px;">
                <a tabindex="0" id="return-button" (click)="returnHome();" (keydown.enter)="returnHome();"  class="btn btn-info widget-button">Return</a>
              </div>
          </div> -->
    </div>
  </div>
