<div id="announcement-container" *ngIf="!isUserLoggedIn() && goldBarItems.length > 0 && showAnnouncements">
    <div class="row announcement">
        <div class="col-xs-12 col-sm-2 announcement-heading hidden-xs">
            <span translate>ALERTS.ALERTS_LINE_1</span> <span class="sub" translate> ALERTS.ALERTS_LINE_2</span>
        </div>
        <div class="col-xs-12 col-sm-10 announcement-body">
            <div class="announcement-close">
                <button type="button" class="close" aria-label="Close" (click)="showAnnouncements=false"><span aria-hidden="true">×</span></button>
            </div>
            <div *ngFor="let goldBarItem of goldBarItems; let i = index">
                <span class="glyphicon glyphicon-info-sign" ng-class="{'glyphicon-exclamation-sign': item.style == 2, 'glyphicon-info-sign': item.style == 1}"></span>
                <span [innerHTML]="goldBarItems[i]?.name"></span> <a class="read-more-link" *ngIf="goldBarItems[i]?.content" href="javascript:void(0)" (click)="showAlertContent(goldBarItem)" (key.enter)="showAlertContent(goldBarItem)" translate>HOME.ABOUT.LEARN_MORE</a>
            </div>
        </div>
    </div>
</div>

<info-modal [id]="'alert-info-modal'"  [title]="alertTitle" [buttonText]="'MODAL.BUTTON_CLOSE'" [translate-src]="alertContent"></info-modal>
