import { Component, OnInit, ViewChild, DoCheck, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../common/models/user';
import { AuthService } from '../common/services/auth.service';
import { TranslateReferenceService } from '../common/services/translate-reference.service';
import { TranslateService } from '@ngx-translate/core';

import { NgForm } from '@angular/forms';

import { FileUploader } from 'ng2-file-upload';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';

import { ModalService } from '../../app/shared/modals/modal.service';
import { SpinnerService } from '../../app/shared/spinner/spinner.service';

import { ReconsiderationRequest } from '../common/models/reconsideration-request.model';

import { ReconsiderationService } from './reconsideration.service';

import { GoesDate } from '../common/models/date.model';
import { DashboardService } from '../common/services/dashboard.service';
import Utils from '../common/utils/utils';


declare var $: any;
const URL = '/api/';
// const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
    moduleId: module.id,
    templateUrl: 'reconsideration.component.html',
    providers: [ReconsiderationService]
})

export class ReconsiderationComponent implements OnInit, AfterViewInit, DoCheck {

    public expColToggle: number;
    public user = new User();
    private _INITUSER: User;
    public isUserAuthenticated: boolean;

    bannerStatus = 'empty';
    bannerMessage = '';
    membershipProgram = '';
    programName = '';
    membershipStatus = '';
    gesApplicationId = '';
    dob: GoesDate;
    dobFormatStr = '';
    // tslint:disable-next-line:variable-name
    private _cancelBtn: HTMLElement;
    // tslint:disable-next-line:variable-name
    private _confirmBtn: HTMLElement;

    public reconsiderationExplanation = '';
    public hasExplanationError = false;
    public hasExplanationMinEnteredError = false;
    public reconsiderationExplanationData = '';

    public isSelectedFilesValid = true;
    public isFormSubmitable = false;
    public invalidFileErrors: string[] = [];
    public fileAddedDate: number = Date.now();

    public hasBaseDropZoneOver = false;
    public request: ReconsiderationRequest;

    public sortField: string;
    public sortDirection: string;

    @ViewChild('reconsiderationNewform')
    public myRequestForm: NgForm;

    fileObject: any;

    public uploader: FileUploader = new FileUploader({
        url: URL,
        disableMultipart: false
        /*
            Parameters supported by this object:
                url - URL of File Uploader's route
                authToken - Auth token that will be applied as 'Authorization' header during file send.
                disableMultipart - If 'true', disable using a multipart form for file upload and instead stream the file.
                    Some APIs (e.g. Amazon S3) may expect the file to be streamed rather than sent via a form. Defaults to false.

                itemAlias - item alias (form name redefenition)
                formatDataFunction - Function to modify the request body. 'DisableMultipart' must be 'true' for this function to be called.
                formatDataFunctionIsAsync - Informs if the function sent in 'formatDataFunction' is asynchronous. Defaults to false.
                parametersBeforeFiles - States if additional parameters should be appended before or after the file. Defaults to false.
        */

    });


    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private reconsiderationService: ReconsiderationService,
                private modalService: ModalService,
                private spinnerService: SpinnerService,
                private translate: TranslateService,
                private refService: TranslateReferenceService,
                private dashboardService: DashboardService) {
        // modalService.confirm  = this.confirm.bind(this);

        // https://angular.io/guide/rx-library#naming-conventions-for-observables
        authService.authorizedUser$.subscribe(
            user => {
                this.user = user;
            }
        );
    }

    ngOnInit() {
      if (this.dashboardService.reconsiderationSubmitted) {
          this.router.navigate(['/dashboard']);
      } else {
          this.route.queryParams.subscribe(params => {
              this.membershipProgram = params['membershipId'] || null;
              this.membershipStatus = params['membershipStatus'] || null;
              this.gesApplicationId = params['gesApplicationId'] || null;
          });

          this._INITUSER = this.user;

          this.programName = this.refService.getProgramName(this.membershipProgram);
          // this.dob=new GoesDate(null, null, null, this.user.dateOfBirth);
          this.bannerStatus = this.dashboardService.getBannerStatus();
          this.bannerMessage = this.dashboardService.getBannerMessage();
          this._confirmBtn = document.getElementById('yesJsonHov');
          this._cancelBtn = document.getElementById('noJsonHov');
      }
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngDoCheck(): void {
        this.user = this.authService.getUser();

        if (this.user !== this._INITUSER) {
            this.isUserAuthenticated = this.user.username ? true : false;
        }
    }

    ngAfterViewInit() {
      this.dobFormatStr = Utils.convertISODateToLongDate(this.user.dateOfBirth);
    }

    checkExplanation() {
        if (this.reconsiderationExplanation != null && this.reconsiderationExplanation.length < 1) {
            this.hasExplanationError = true;
        }
    }
    // confirm(msgKey: string, titleKey?:string, noKey?:string, yesKey?:string): Promise<boolean> {
    //     let promise = new Promise<boolean>(resolve => {
    //       this.cancelReq(resolve);
    //     });
    //     return promise;
    //   }

    reset(): void {
        this._cancelBtn.click();
        $('#cancelReconsideration').modal('hide');

    }
    acceptCancel(): void {
        this._confirmBtn.click();
        // this.dashboardService.resetBanner;
        this.dashboardService.setBannerMessage('OMBUDSMAN.CANCEL_BANNER_MSG_RECONSIDERATION');
        this.dashboardService.setBannerStatus('failure');
        this.bannerMessage = 'OMBUDSMAN.CANCEL_BANNER_MSG_RECONSIDERATION';
        this.bannerStatus = 'failure';

        $('#cancelReconsideration').modal('hide');
        this.router.navigate(['/dashboard']);
        this.spinnerService.hide();
    }

    isDeniedRevokedOrClosed(): boolean {
        if (this.membershipStatus === 'DN' || // Denied
            this.membershipStatus === 'CL' || // Closed
            this.membershipStatus === 'RV') { // Revoked
            return true;
        }
        else {
            return false;
        }
    }

    removeSelectedFile(item: FileItem) {
        const promptBody = 'OMBUDSMAN.ATTACHEMENT_REMOVE_PROMPT_BODY';
        const promptTitle = 'OMBUDSMAN.ATTACHEMENT_REMOVE_PROMPT_TITLE';
        const promptBtnNo = 'OMBUDSMAN.ATTACHEMENT_REMOVE_PROMPT_BUTTON_NO';
        const promptBtnYes = 'OMBUDSMAN.ATTACHEMENT_REMOVE_PROMPT_BUTTON_YES';
        this.modalService.confirm(promptBody, promptTitle, promptBtnNo, promptBtnYes)
            .then(
                confirmed => {
                    if (confirmed) {
                        item.remove();
                        this.hideFilesInvalidMessage();
                        // if (this.uploader.queue.length == 0 && this.reconsiderationExplanationData.trim().length == 0) {
                        //     this.isFormSubmitable = false;
                        // }
                    }
                })
            .catch(e => {
                console.log(e);
            });
    }

    // Cancel the modal and return to Dashboard
    showCancelModal(){
        this.translate.get('OMBUDSMAN.CANCEL_REQUEST_PROMPT_BODY', { value: this.programName}).subscribe(result => {
            this.modalService.confirm(result, 'OMBUDSMAN.CANCEL_REQUEST_PROMPT_TITLE', 'OMBUDSMAN.CANCEL_REQUEST_PROMPT_BUTTON_NO', 'OMBUDSMAN.CANCEL_REQUEST_PROMPT_BUTTON_YES')
                .then(confirmed => {
                    if (confirmed){
                        this.router.navigate(['dashboard']);
                        this.dashboardService.setBannerMessage('');
                        this.dashboardService.setBannerStatus('failure');
                        this.bannerMessage = '';
                        this.bannerStatus = 'failure';
                    }
                });
        });
    }

    showSaveModal(){
        this.translate.get('OMBUDSMAN.SUBMIT_RECONSIDERATION_MSG', { value: this.programName}).subscribe(data => {
            this.modalService.confirm(data, 'OMBUDSMAN.CANCEL_REQUEST_PROMPT_TITLE', 'OMBUDSMAN.CANCEL_REQUEST_PROMPT_BUTTON_NO', 'OMBUDSMAN.YES_SUBMIT')
                .then(confirmed => {
                    if (confirmed){
                        this.saveData();
                    }
                });
        });
    }

    private saveData() {

        const formData = new FormData();
        const request = new ReconsiderationRequest();

        request.id = '0';
        request.program = this.membershipProgram;
        request.requestReason = this.reconsiderationExplanation;
        if (this.membershipStatus !== 'DN') {
            this.gesApplicationId = '';
        }
        this.spinnerService.show();

        /*
            From Angular posting only Userid, Program and Request Reason
            In Java Services,
                Generating Unique Request ID, setting default status
                From User id, set Person, Passid
        */

        formData.append('id', '0');
        formData.append('program', this.membershipProgram);
        formData.append('requestReason', this.reconsiderationExplanation);
        formData.append('gesApplicationId', this.gesApplicationId);

        if (this.uploader.queue.length > 0) {
            for (let eachFile of this.uploader.queue) {
                formData.append('attachFiles', eachFile._file);
            }
        }

        this.reconsiderationService.saveRequestData(formData, request, this.user.userId)
            .subscribe(res => {
                if (res === false) {
                    this.spinnerService.hide();
                }
                else if (res && res.resultCode === 'ERR') {
                    this.spinnerService.hide();
                    this.bannerStatus = this.dashboardService.getBannerStatus();
                    this.bannerMessage = this.dashboardService.getBannerMessage();
                }
                else if(res) {
                    this.dashboardService.reconsiderationSubmitted = true;
                    this.spinnerService.hide();
                    this.bannerStatus = this.dashboardService.getBannerStatus();
                    this.bannerMessage = this.dashboardService.getBannerMessage();
                    this.router.navigate(['/dashboard']);
                }
            },
            error => {
                this.spinnerService.hide();
                this.bannerStatus = this.dashboardService.getBannerStatus();
                this.bannerMessage = this.dashboardService.getBannerMessage();
            });

        if (this.reconsiderationExplanation && this.reconsiderationExplanation.length < 1) {
            this.hasExplanationError = true;
            this.isFormSubmitable = false;
        }
    }

    onClick() {
        this.bannerStatus = 'empty';
    }

    onFocusOut(value: string) {

        this.hasExplanationError = false;
        this.reconsiderationExplanation = value;

        if (value && value.trim().length >= 1) {
            this.hasExplanationMinEnteredError = false;

            if (  (this.uploader.queue.length >= 0 ) &&
                    (this.uploader.queue.length <= 10 ) &&
                    (this.isSelectedFilesValid)  ) {
                this.isFormSubmitable = true;
            }
        } else {
            this.hasExplanationMinEnteredError = true;
            this.isFormSubmitable = false;
        }
        this.reconsiderationExplanation = value;
    }

    public collapseAll(): void {
        $('.collapse').collapse('hide');
    }

    public expandAll(): void {
        $('.collapse').collapse('show');
    }
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public onFileSelected(event: any): void {

        const evetntFileLength = event.length;
        const fileFromEvent: File = event[0];
        this.isSelectedFilesValid = true;
        this.invalidFileErrors.length = 0;

        if (this.uploader.queue.length > 10) {
            this.isSelectedFilesValid = false;

            // this.invalidFileErrors.push("You cannot select more than 10 attachments.");
            this.translate.get('OMBUDSMAN.MORETHAN_10FILES').subscribe(message => {
                this.invalidFileErrors.push(message);
            });

            this.isFormSubmitable = false;
            // this.uploader.queue.length = 0;
            return;
        }

        const tempQueue: FileItem[] = [];
        for (let eachFile of this.uploader.queue) {
            if (eachFile != null) {
                if (eachFile._file.size <= (1024 * 1024) * 2) {
                    const fileParts: string[] = eachFile.file.name.split('.');
                    const extension: string = fileParts[fileParts.length - 1].toLocaleLowerCase();

                    if (
                        extension === 'jpg' || extension === 'gif' ||
                        extension === 'pdf' || extension === 'png' ||
                        extension === 'docx' || extension === 'doc') {
                        tempQueue.push(eachFile);
                        this.fileAddedDate = Date.now();

                    } else {
                        tempQueue.push(eachFile);
                        this.isSelectedFilesValid = false;
                        // this.invalidFileErrors.push("Formats accepted are PDF (preferred), JPG, GIF, PNG, DOCX and DOC.");
                        this.translate.get('OMBUDSMAN.ACCEPTED_FILE_TYPES').subscribe(message => {
                            this.invalidFileErrors.push(message);
                        });
                    }
                } else {
                    this.isSelectedFilesValid = false;
                    tempQueue.push(eachFile);
                    // this.invalidFileErrors.push("You can upload multiple files no larger than 2 MG per file.");
                    this.translate.get('OMBUDSMAN.ATTACHMENT_MAX_FILESIZE').subscribe(message => {
                        this.invalidFileErrors.push(message);
                    });

                }
            }
        }
        this.uploader.queue = tempQueue;

        if (this.isSelectedFilesValid) {
            if (this.uploader.queue.length > 0) {
                if (this.hasExplanationMinEnteredError || this.reconsiderationExplanation.length < 1) {
                    this.isFormSubmitable = false;
                } else {
                    this.isFormSubmitable = true;
                }
            }
        } else {
            this.isFormSubmitable = false;
        }
    }

    hideFilesInvalidMessage(): void {
        this.isSelectedFilesValid = true;
        this.invalidFileErrors.length = 0;

        if (this.uploader.queue.length > 10) {
            this.isSelectedFilesValid = false;
            this.translate.get('OMBUDSMAN.MORETHAN_10FILES').subscribe(message => {
                this.invalidFileErrors.push(message);
            });

            this.isFormSubmitable = false;
            return;
        }

        for (let eachFile of this.uploader.queue) {
            if (eachFile != null) {
                if (eachFile._file.size <= (1024 * 1024) * 2) {
                    const fileParts: string[] = eachFile.file.name.split('.');
                    const extension: string = fileParts[fileParts.length - 1].toLocaleLowerCase();

                    if (
                        extension === 'jpg' || extension === 'gif' ||
                        extension === 'pdf' || extension === 'png' ||
                        extension === 'docx' || extension === 'doc') {
                    } else {
                        this.isSelectedFilesValid = false;
                        // this.invalidFileErrors.push("Formats accepted are PDF (preferred), JPG, GIF, PNG, DOCX, and DOC.");
                        this.translate.get('OMBUDSMAN.ACCEPTED_FILE_TYPES').subscribe(message => {
                            this.invalidFileErrors.push(message);
                        });
                        // eachFile.remove();
                        if (this.uploader.queue.length === 0 && this.reconsiderationExplanationData.trim().length == 0) {
                            this.isFormSubmitable = false;
                        }
                    }
                } else {
                    this.isSelectedFilesValid = false;
                    // this.invalidFileErrors.push("You can upload multiple files no larger than 2 MG per file.");
                    this.translate.get('OMBUDSMAN.ATTACHMENT_MAX_FILESIZE').subscribe(message => {
                        this.invalidFileErrors.push(message);
                    });
                }
            }
        }

        if (this.isSelectedFilesValid) {
            if (this.hasExplanationMinEnteredError ||
                this.reconsiderationExplanation.length < 1 ||
                // this.uploader.queue.length <= 0 ||
                this.uploader.queue.length > 10
            ) {
                this.isFormSubmitable = false;
            } else {
                this.isFormSubmitable = true;
            }
        } else {
            this.isFormSubmitable = false;
        }

    }

    // Function used to sort data when a column header is clicked
    public sortColumnRecon(sortField: string, sortDirection: string) {

        const sameField = (sortField === this.sortField ? true : false);
        this.sortField = sortField;
        this.sortDirection = sortDirection ? (sortDirection === 'desc' && sameField ? 'asc' : 'desc') : 'desc';
        const direction = this.sortDirection ? (this.sortDirection === 'asc' ? 1 : -1) : 1;

        switch (this.sortField.toLocaleLowerCase()) {
            case 'filename': {
                this.uploader.queue.sort((a, b) => {
                    if (a.file.name.toLocaleLowerCase() === b.file.name.toLocaleLowerCase()) {
                        return 0;
                    }
                    else if (a.file.name.toLocaleLowerCase() > b.file.name.toLocaleLowerCase()) {
                        return 1 * direction;
                    }
                    else {
                        return -1 * direction;
                    }
                });
                break;
            }
            case 'filesize': {
            this.uploader.queue.sort((a, b) => {
                if (a.file.size === b.file.size) {
                    return 0;
                }
                else if (a.file.size > b.file.size) {
                    return 1 * direction;
                }
                else {
                    return -1 * direction;
                }
            });
            break;
        }
        //   case 'uploaddate': {
        //     this.uploader.queue.sort((a, b) => {
        //         if (a.fileUploadDateStr.toLocaleLowerCase() === b.fileUploadDateStr.toLocaleLowerCase()) {
        //             return 0;
        //         }
        //         else if (a.fileUploadDateStr.toLocaleLowerCase() > b.fileUploadDateStr.toLocaleLowerCase()) {
        //             return 1 * direction;
        //         }
        //         else {
        //             return -1 * direction;
        //         }
        //     });
        //     break;
        // }
        }
    }

}

function readBase64(file: any): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
        reader.addEventListener('load', () => {
            resolve(reader.result);
        }, false);

        reader.addEventListener('error', (event) => {
            reject(event);
        }, false);

        reader.readAsDataURL(file);
    });

    return future;
}
