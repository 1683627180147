import { SETTINGS } from './../app.constants';
import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../common/models/user';
import { AuthService } from '../common/services/auth.service';
import { ReconsiderationService } from './reconsideration.service';
import { Reconsideration } from '../common/models/reconsideration.model';
import { ReconsiderationAttachment } from '../common/models/reconsideration-attachment.model';
import { TranslateReferenceService } from '../common/services/translate-reference.service';
import { Observable, of } from 'rxjs';
import { Location } from '@angular/common';

import { SpinnerService } from '../../app/shared/spinner/spinner.service';
import { ModalService } from '../shared/modals/modal.service';
import Utils from '../common/utils/utils';
import { environment } from '../../environments/environment';

declare var $: any;
@Component({
  moduleId: module.id,
  templateUrl: 'viewReconsideration.component.html'
})
export class ViewReconsiderationComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy, DoCheck {

  reconRequest: Reconsideration;
  public user = new User();
  private _INITUSER: User;
  public isUserAuthenticated: boolean;
  ReconsiderationId = '';
  programName = '';
  requestID = '';
  requestReason = '';
  submittedDateStr = '';

  public sortField: string;
  public sortDirection: string;

  dobFormatStr: string;
  reconAttachments: ReconsiderationAttachment[] = [];

  constructor(private authService: AuthService,
              private location: Location,
              private route: ActivatedRoute,
              private router: Router,
              private reconsiderationService: ReconsiderationService,
              private refService: TranslateReferenceService,
              private spinnerService: SpinnerService,
              private modalService: ModalService) {

    authService.authorizedUser$.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  ngOnDestroy(): void {

  }
  ngAfterViewInit() {
    this.dobFormatStr = Utils.convertISODateToLongDate(this.user.dateOfBirth);
 }
  ngAfterViewChecked() {
    // adjust height of side-bar

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.ReconsiderationId = params['reconId'] || null;
    });
    this._INITUSER = this.user;

    let userId = '';
    if (SETTINGS.useStaticTestAPIData && !this.authService.getUser()) {
      userId = 'TEST_USER_ID';
    }
    else {
      userId = this.authService.getUser().userId;
    }
    this.reconsiderationService.getReconsiderationRequest(
      userId, this.ReconsiderationId)
      .subscribe((res: Reconsideration) => {
        if (res) {
          this.initReconsideration(res).subscribe(res => {
          });
        } else {
          this.modalService.alert('ERROR_MESSAGES.GENERAL.ERRORS_ON_PAGE');
          this.router.navigate(['/dashboard']);
          return null;

        }
      },
        error => {
          // either the promo code is invalid or there was a service error
          this.modalService.alert('ERROR_MESSAGES.GENERAL.ERRORS_ON_PAGE');
        }
      );

    this.reconsiderationService.getReconsiderationAttachments(userId, this.ReconsiderationId)
      .subscribe((res: Array<ReconsiderationAttachment>) => {
        this.reconAttachments = res;
        if (this.reconAttachments.length > 0 ){
          this.sortDirection = 'asc';
          this.sortField = 'fileName';
          this.sortColumn(this.sortField, this.sortDirection);
        }
      });

  }

  ngDoCheck(): void {
    this.user = this.authService.getUser();
    if (this.user !== this._INITUSER) {
      this.isUserAuthenticated = this.user.username ? true : false;
    }

  }

  /**
   * Initialize the dashboard.
   */
  protected initReconsideration(recon: Reconsideration): Observable<boolean> {

    if (recon) {
      this.reconRequest = recon;
      this.programName = this.refService.getProgramName(this.reconRequest.program);
      this.requestID = this.reconRequest.reconsiderationId;
      this.requestReason = this.reconRequest.requestReason;
      this.submittedDateStr = this.reconRequest.submittedDateStr;
    }
    else {
    }

    return of(true);
  }

  goBack() {
    this.location.back();
  }

  downloadAttachment(fileName: string, objKey: string, contentType: string) {
    this.spinnerService.show();

    this.reconsiderationService.downloadAttachment(
      this.user.userId, this.authService.token, fileName, objKey, contentType)
      .subscribe(data => {
        if (data) {

          const blob = new Blob( [data], {type: 'application/octet-stream'});
          const fileUrl1 = window.URL.createObjectURL(blob);

          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob){
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
                const downloadLink = document.createElement('a');
                downloadLink.href = fileUrl1;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
          }
          this.spinnerService.hide();

        } else {
          this.spinnerService.hide();
        }
       }, (error) => {
        this.spinnerService.hide();
        this.modalService.alert(error);
      });
  }

  // Function used to sort data when a column header is clicked
  public sortColumn(sortField: string, sortDirection: string) {

    const sameField = (sortField === this.sortField ? true : false);
    this.sortField = sortField;
    this.sortDirection = sortDirection ? (sortDirection === 'desc' && sameField ? 'asc' : 'desc') : 'desc';
    const direction = this.sortDirection ? (this.sortDirection === 'asc' ? 1 : -1) : 1;

    switch (this.sortField.toLocaleLowerCase()) {
        case 'filename': {
            this.reconAttachments.sort((a, b) => {
                if (a.fileName.toLocaleLowerCase() === b.fileName.toLocaleLowerCase()) {
                    return 0;
                }
                else if (a.fileName.toLocaleLowerCase() > b.fileName.toLocaleLowerCase()) {
                    return 1 * direction;
                }
                else {
                    return -1 * direction;
                }
            });
            break;
        }
        case 'filesize': {
          this.reconAttachments.sort((a, b) => {
              if (a.fileSize === b.fileSize) {
                  return 0;
              }
              else if (a.fileSize > b.fileSize) {
                  return 1 * direction;
              }
              else {
                  return -1 * direction;
              }
          });
          break;
      }
      case 'uploaddate': {
        this.reconAttachments.sort((a, b) => {
            if (a.fileUploadDateStr.toLocaleLowerCase() === b.fileUploadDateStr.toLocaleLowerCase()) {
                return 0;
            }
            else if (a.fileUploadDateStr.toLocaleLowerCase() > b.fileUploadDateStr.toLocaleLowerCase()) {
                return 1 * direction;
            }
            else {
                return -1 * direction;
            }
        });
        break;
    }
}
    }
}
