import { Directive, ElementRef, HostListener, Input, Inject, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: 'input[trimSpaces], textarea[trimSpaces]',
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: NoWhitespaceDirective, multi: true}]
})
export class NoWhitespaceDirective implements ControlValueAccessor {

    /**
     * Keep the type of input element in cache.
     * 
     * @type {string}
     * @private
     */
    private _type: string = 'text';

    // Source services to modify elements.
    private _sourceRenderer: Renderer2;
    private _sourceElementRef: ElementRef;
    onChange: (arg?: any) => {};
    onTouched: () => {};

    @Input()
    get type(): string {
        return this._type;
    } set type(value: string) {
        this._type = value || 'text';
    }

    /**
     * Set a new value to the field and model.
     */
    set value(val: any) {
        // update element
        this.writeValue(val);
        // update model
        this.onChange(val);
        this.onTouched();
    } get value(): any {
        return this._sourceElementRef.nativeElement.value;
    }

    /**
     * Updates the value on the blur event.
     */
    @HostListener('blur', ['$event.type', '$event.target.value'])
    onBlur(event: string, value: string) {
        this.updateValue(event, value);
    }

    constructor(@Inject(Renderer2) renderer: Renderer2,
                @Inject(ElementRef) elementRef: ElementRef) {
        this._sourceRenderer = renderer;
        this._sourceElementRef = elementRef;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /**
     * Writes a new value to the element based on the type of input element
     * @param {any} value - new value
     */
     public writeValue(value: any) {
         this._sourceRenderer.setProperty(this._sourceElementRef.nativeElement, 'value', value);

         // update the element value if 'setProperty' doesn't set it for some reason.
         if (this._type !== 'text') {
             this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, 'value', value);
         }
     }

     /**
      * Trims an input value, and sets it to the model and element.
      * @param {string} value - input value
      * @param {string} event - input event
      */
      private updateValue(event: string, value: string) {
          this.value = value.trim();
      }

}