<div class="page-content">
  <div class="row">
    <div class="col-lg-12 progress-top-level centered">
      <!--
        dashboard title is from
      -->
      <h1 class="header-1-recon" translate>OMBUDSMAN.TITLE</h1>
      <p class="divider hidden-xs"></p>
    </div>
  </div>
  <!-- Start Reconsideration Request page  -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!-- Form Start -->
        <form #reconsiderationNewform="ngForm">
          <div class="row">
            <div class="content-panel with-side-bar">
              <div class="main">
                <div id="myAlertBannerRecID" [ngClass]="{'myAlertBannerEmptyRec' : bannerStatus === 'empty','myAlertBannerRec1' : bannerStatus === 'success','myAlertBannerRec2' : bannerStatus === 'failure'}">
                  <!-- banner start -->
                  <div class="row" *ngIf="bannerStatus" class="dashboard-alert col-md-9 col-xs-12 col-lg-9 alert alert-dismissible" [ngClass]="{'alert-success':  bannerStatus === 'success', 'alert-danger' : bannerStatus === 'failure'} ">
                    <a href="#" tabindex="0" (click)="onClick()" class="close alert-close" data-dismiss="alert" aria-label="close">×</a>
                      <span *ngIf="bannerStatus === 'success'" class="fa" [ngClass]="{'fa-check-circle-o' : bannerStatus === 'success', 'fa-minus-square-o-o' : false}"
                      aria-hidden="true"></span>
                    <div class="alert-content">
                      <span [innerHTML]="bannerMessage | translate"></span>
                      <br>
                    </div>
                  </div>
                </div>

                <!-- end banner -->
                <div class="middle req-title" id="requestTitle">
                  <p class="req-title-p1" translate>OMBUDSMAN.REQUEST_INFO</p>

                  <p class="required req-title-p2" translate>GENERAL_REUSABLE.REQUIRED_FIELD</p>
                </div>
                <div class="req-clear-div"></div>

                <table class="table-border">
                  <tr>
                    <td>
                      <div class="middle">
                        <b>
                          <span translate>OMBUDSMAN.REQUESTER_NAME</span>: </b>
                      </div>
                    </td>
                    <td class="table-width">
                      &nbsp;
                    </td>
                    <td>
                      <span id="userNameId"> {{user.name}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="middle">
                        <b>
                          <span translate>OMBUDSMAN.REQUESTER_DOB</span>: </b>
                      </div>
                    </td>
                    <td class="table-width">
                      &nbsp;
                    </td>
                    <td>
                      <span id="dateOfBirthId">{{dobFormatStr}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="middle">
                        <b>
                          <span translate>OMBUDSMAN.REQUESTER_PROGRAM</span>: </b>
                      </div>
                    </td>
                    <td class="table-width">
                      &nbsp;
                    </td>
                    <td>
                      <span id="programId">{{programName}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="middle">
                        <b>
                          <span translate>OMBUDSMAN.REQUESTER_MEMBERSHIP_ID</span>: </b>
                      </div>
                    </td>
                    <td class="table-width">
                      &nbsp;
                    </td>
                    <td>
                      <span id="membershipId">{{user.membershipOrPassId}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="middle">
                        <b>
                          <span translate>OMBUDSMAN.REQUESTER_EMAIL</span>: </b>
                      </div>
                    </td>
                    <td class="table-width">
                      &nbsp;
                    </td>
                    <td>
                      <span id="emailId">{{user.email}}</span>
                    </td>
                  </tr>

                </table>
                <div class="middle">
                  <br>
                </div>

                <div class="middle">

                  <div class="middle div-middle-color" *ngIf="isDeniedRevokedOrClosed()">
                    <span [innerHTML]="'OMBUDSMAN.REVIEW_BY_THE_OMBUDSMAN_NOTE' | translate"></span>
                  </div>
                  <br>
                  <span class="required">*</span>
                  <b>
                    <span translate>OMBUDSMAN.REQUESTER_EXPLAIN</span>
                  </b>
                </div>
                <div class="middle">
                  <textarea [value-type]="'textPattern'" rows="4" tabindex="0" class="form-control" maxlength="500" id="reconsiderationExplanationData" name="reconsiderationExplanationData"
                    [(ngModel)]="reconsiderationExplanationData" trimSpace #recExplain (focusout)="onFocusOut(recExplain.value)"></textarea>
                </div>

                <div class="middle">
                  <small>{{500-recExplain.value.trim().length}}</small><small class="char-count-ml" translate>OMBUDSMAN.REMAINING_NUMBER_OF_CHARACTERS</small>
                </div>

                <div class="field-error" *ngIf="hasExplanationMinEnteredError">
                  <p translate>OMBUDSMAN.EXPLANATION_MIN_MSG</p>
                </div>

                <div class="field-error" *ngIf="hasExplanationError">
                  <p translate>OMBUDSMAN.EXPLANATION_MISSING</p>
                </div>

                <div class="middle">
                  <br>
                </div>

                <div class="middle">
                  <b>
                    <span translate>OMBUDSMAN.ADD_ATTACHMENTS</span>
                  </b>
                  <tooltip [tooltip-text]="'OMBUDSMAN.ATTACHMENTS_TOOLTIP'"></tooltip>
                  <br>
                  <span [innerHTML]="'OMBUDSMAN.ATTACH_DOCUMENTS_OR_DRAG_AND_DROP' | translate"></span>
                </div>
                <div class="middle">
                  <br>
                </div>
                <!-- file upload start  -->
                <div class="field-error" *ngIf="!isSelectedFilesValid">
                  <p *ngFor="let errMsg of invalidFileErrors">{{errMsg}}</p>
                </div>

                <div class="middle">

                  <div class="row row-button-group">
                    <div class="col-xs-12 col-sm-4 col-md-2">
                      <div class="upload-btn-wrapper">
                        <button id="multiSelectButtonId" id="attachHov" class="btn btn--attach multi-select-butt1" translate>OMBUDSMAN.ATTACH_FILES</button>
                        <input data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.ATTACH_UP_TO_TEN_FILES' | translate}}" id="multiFileInputId"
                         class="attach-input" name="omSelectmultifile" type="file" ng2FileSelect [uploader]="uploader"
                          accept=".pdf,.jpg,.gif,.png,.docx,.doc" multiple (onFileSelected)="onFileSelected($event)"
                        />

                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-1">
                      <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader"
                        (onFileDrop)="onFileSelected($event)" data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.ATTACH_UP_TO_TEN_FILES' | translate}}" class="my-drop-zone file-drop-c" translate>
                        OMBUDSMAN.DRAG_AND_DROP_FILES
                      </div>
                    </div>

                  </div>

                </div>


                <div class="row">
                  <div class="col-md-9 req-div-margin">
                    <p></p>
                    <div class="middle" *ngIf="uploader.queue.length>0">
                      <!-- Start Only show table if files are selected-->
                      <b>
                          <span translate>OMBUDSMAN.ATTACHED_FILES</span>
                      </b>
                        <div class="tab-content" style="padding-left: 0 !important;">
                        <div id="dataTable" class="panel-body table-responsive" style="padding-left: 0 !important;">
                        <table role="grid" class="table table-sortable table-striped mdl-js-data-table">
                          <thead class="att-tab-bac-col">
                            <tr role="row">
                                <th scope="col" role="columnheader" class="table-headerl non-numeric sortable" (click)="sortColumnRecon('fileName', sortDirection)" (keydown)="sortColumnRecon('fileName', sortDirection)" aria-sort="none" tabindex="0" data-sort="text" translate>OMBUDSMAN.FILE_NAME
                                  <div class="pull-right">
                                  <i class="fa fa-caret-up" *ngIf="sortField=='fileName' && sortDirection=='asc'"></i>
                                  <i class="fa fa-caret-down" *ngIf="sortField=='fileName' && sortDirection=='desc'"></i>
                                </div>
                              </th>
                                <th scope="col" role="columnheader" class="table-headerc non-numeric sortable" (click)="sortColumnRecon('fileSize', sortDirection)" (keydown)="sortColumnRecon('fileSize', sortDirection)" aria-sort="none" tabindex="0" translate>OMBUDSMAN.FILE_SIZE
                                  <div class="pull-right">
                                    <i class="fa fa-caret-up" *ngIf="sortField=='fileSize' && sortDirection=='asc'"></i>
                                    <i class="fa fa-caret-down" *ngIf="sortField=='fileSize' && sortDirection=='desc'"></i>
                                  </div>
                                </th>
                                <th scope="col" role="columnheader" class="table-headerc non-numeric" tabindex="0"><span translate>OMBUDSMAN.DATE_TIME</span></th>
                                <th scope="col" role="columnheader" class="table-headerc non-numeric" tabindex="0"><span translate>OMBUDSMAN.ACTIONS</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="stripe-row"  *ngFor="let item of uploader.queue">
                              <td>{{ item?.file?.name }}</td>
                              <td scope="row" class="non-numeric">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                              <td class="non-numeric">{{ fileAddedDate | ttpDate:'MM/DD/YYYY hh:mm a' }}</td>
                              <td class="non-numeric" nowrap>
                                <button data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.REMOVE_THIS_ATTACHMENT' | translate}}" type="button"
                                  class="btn-file-action btn-danger btn-xs att-fil-btn" (click)="removeSelectedFile(item);">
                                  <span class="glyphicon glyphicon-trash"></span>
                                  <span class="req-remove" translate>OMBUDSMAN.RECON_REMOVE</span>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      </div>
                      </div>
                    <!-- End -- Only show table if files are selected-->
                    <div class="middle" *ngIf="uploader.queue.length == 0">
                        <b>
                            <span translate>OMBUDSMAN.ATTACHED_FILES</span>
                        </b>
                        <div class="tab-content" style="padding-left: 0 !important;">
                            <div id="dataTable" class="panel-body table-responsive" style="padding-left: 0 !important;">
                      <table class="table table-sortable table-striped mdl-js-data-table">
                        <thead class="att-tab-bac-col">
                          <tr>
                            <th scope="col" class="table-headerl non-numeric" tabindex="0"><span translate>OMBUDSMAN.FILE_NAME</span></th>
                            <th scope="col" class="table-headerl non-numeric" tabindex="0"><span translate>OMBUDSMAN.FILE_SIZE</span></th>
                            <th scope="col" class="table-headerl non-numeric" tabindex="0"><span translate>OMBUDSMAN.DATE_TIME</span></th>
                            <th scope="col" class="table-headerl non-numeric" tabindex="0"><span translate>OMBUDSMAN.ACTIONS</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="stripe-row">
                            <td colspan="4" scope="row" class="non-numeric"><span translate>OMBUDSMAN.RECON_ATTACHMENTS</span></td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                    </div>
                    <!-- End -- Only show table if files are selected-->
                  </div>
                </div>

                <div class="middle" *ngIf="uploader.queue.length==10000000">
                  <div class="row">
                  </div>
                </div>
                <!-- End -- No files are selected-->
                <!-- file upload End  -->
              </div>

              <!--outside of container buttons-->
              <div class="middle">

                <div class="row row-button-group">
                  <!-- debug dash button function added for banners -->
                  <div class="col-xs-12 col-sm-3 col-sm-push-8 col-sm-offset-1">
                    <button class="btn--submit btn-block btn-primary" id="submitHov" data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.SUBMIT_YOUR_RECONSIDERATION_REQUEST' | translate}}"
                      [disabled]="!isFormSubmitable" (click)="showSaveModal()" translate [style.background-color]="isFormSubmitable ? '#00568F' : '#999999'">OMBUDSMAN.REQUESTER_ATTACHMENT_SUBMIT
                    </button>
                  </div>
                  <div class="col-xs-12 col-sm-3 col-sm-pull-4">
                    <button class="btn--cancel btn-block req-btn-color" id="cancelHov" data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.CANCEL_YOUR_RECONSIDERATION_REQUEST' | translate}}"
                      (click)="showCancelModal()" translate>OMBUDSMAN.REQUESTER_ATTACHMENT_CANCEL</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- From End-->
        <!--
            https://github.com/valor-software/ng2-file-upload
            https://github.com/valor-software/ng2-file-upload/tree/master/demo/components/file-upload
            https://valor-software.com/ng2-file-upload/
            https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
            https://codepen.io/adambene/pen/xRWrXN
            https://stackblitz.com/edit/ng2-file-upload?file=app%2Fapp.component.html
           -->



      </div>
    </div>
  </div>
  <!-- End Reconsideration Request page  -->
</div>
