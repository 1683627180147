
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../app/common/services/auth.service';
import { SpinnerService } from '../app/shared/spinner/index';
import { ModalService } from './shared/modals/modal.service';
import { User } from '../app/common/models/user';
import { SystemConfigService } from '../app/common/services/system-config.service';

import Utils from '../app/common/utils/utils';

@Component({
  selector: 'login',
  template: ``
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    // private http: HttpClient,
    private route: ActivatedRoute,
    // private errorHandler: ErrorHandlerService,
    private spinner: SpinnerService,
    private router: Router,
    private translate: TranslateService,
    private modalService: ModalService,
    private systemConfigService: SystemConfigService) {
    }

  ngOnInit(): void {
    this._login();
  }

  /*
   * switch languages if a either lang parameter was passed
   * or if language code is contained in the state
   */
  private _setLanguage(lang: string, state: string){
    if (lang) {
      this.translate.use(lang);
    } else {
      const parsedState = state.split(':');
      if (parsedState.length > 1){
        lang = parsedState[0];
        this.translate.use(lang);
      }
    }
  }

  private _login() {

    this.spinner.show();

    let route = '';

    this.route.queryParams.subscribe((params: Params) => {
      const code = params.code;
      const state = params.state;
      const error = params.error;
      const logout = params.logout;
      const lang = params.lang;
      const survey = params.survey;

      if (error) {
        if (error !== 'access_denied') {
          this.modalService.alert(error);
        }
        this.router.navigate(['/']);
        this.spinner.hide();
        return;
      }

      if (survey) {
          this.systemConfigService.initData().then((res: any) => {
            if (res && res.surveyUrl) {
              Utils.gotoExternalUrl(res.surveyUrl);
            } else {
              this.spinner.hide();
              this.authService.logOut();
            }
          });
          return;
      }

      if (logout) {
        this.spinner.hide();
        this.authService.logOut();
        return;
      }
      
    
      this._setLanguage(lang, state);

      this.authService.requestToken(code, state).subscribe(res => {
          if (res) {
            // console.log(res);
            const email = res.email;
            let uuid = res.sub;
            if (params.dev_uuid) {
              // temporary by pass / dev
              uuid = params.dev_uuid;
            }

            this.authService.loadUser(res.id_token, uuid, email).subscribe(loadRes => {
              let user = new User();
              if (loadRes) {
                user = this.authService.getUser();
                this.authService.startTimeoutWarningTimer();
              }

              if (user.name) {
                route = '/dashboard';
              } else if (user.userId) {
                route = '/account-profile/' + user.userId;
              } else {
                route = '';
              }

              this.spinner.hide();
              this.router.navigate([route]);
            });
          }

          else {
            this.authService.logOut();
          }
        });
    });
  }

  private _doesStoredStateMatchRequest(requestState: string, storedState: string): boolean {
    let match = false;

    if (storedState && storedState.split(':').length > 0) {
      const actualState = storedState.split(':')[1];
      if (actualState === requestState) {
        match = true;
      }
    }

    return match;
  }
}
