import { AnalyticsService } from './../common/services/analytics.service';
import { ComparisonToolComponent } from './comparison-tool/comparison-tool.component';
import { ComparisonResultsComponent } from './comparison-tool/comparison-results.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SecurityModalComponent } from '../shared/index';
import { AuthService } from '../common/services/auth.service';
import { DashboardService } from '../common/services/dashboard.service';
import { FAQService } from '../common/services/faq.service';
import { NewsService } from '../common/services/news.service';

import Utils from '../common/utils/utils';
import { environment } from '../../environments/environment';
import { SETTINGS } from '../app.constants';

declare var $: any;

// declare function unescape(s:string): string;
/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
  moduleId: module.id,
  selector: 'go-home',
  templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {

  @ViewChild('securityModal') securityModal: SecurityModalComponent;

  @ViewChild(ComparisonToolComponent) comparisonTool: ComparisonToolComponent;
  @ViewChild(ComparisonResultsComponent) comparisonResults: ComparisonResultsComponent;

  newName = '';
  errorMessage: string;
  names: any[] = [];
  schedulerViewLocationsUrl: string;
  hasTTPApplication = false;

  goldBarItems: any[] = new Array();

  alertTitle: string;
  alertContent: string;
  showAnnouncements = true;

  displayWidgetResults = false;

  public TSA_LOGIN_URL = environment.tsaLoginURL;

  public LOCAL_STORAGE_LANGUAGE_KEY = 'ttp_language';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private newsService: NewsService,
    private analyticsService: AnalyticsService,
    private faqService: FAQService
  ) {
    // set the scheduler view location url and also update it when language changes
    this.schedulerViewLocationsUrl = environment.uriScheduler + 'schedule-interview/location?lang=' + this.translate.currentLang + '&vo=true&returnUrl=' + SETTINGS.baseReturnService + '&service=';
    this.translate.onLangChange.subscribe((event: any) => {
      this.schedulerViewLocationsUrl = environment.uriScheduler + 'schedule-interview/location?lang=' + this.translate.currentLang + '&vo=true&returnUrl=' + SETTINGS.baseReturnService + '&service=';
    });
  }

  ngOnInit() {
    this.hasTTPApplication = this._hasApplication();

    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
    // navigator.languages isn't in all browsers yet, so cast
    // the type to make typescript happy.
    // let navigator = <any>window.navigator;
    // let langKey = this.getPreferredLanguage(navigator.languages);

    // if language selection is changed, fetch relevant news.
    this.newsService.newsUpdatedSubj.subscribe(res => {

      const localGoldBarItems: any = [];
      this.newsService.getNews().subscribe((res: any) => {
        const tempItems = res[this.translate.currentLang];

        if (tempItems && tempItems.length > 0) {
          for (const news of tempItems) {
            if (news.goldBarInd) {
              localGoldBarItems.push(news);
            }
          }

          this.goldBarItems = localGoldBarItems;
        }
      });
    });

    // Hack to enable multiple modals by making sure the .modal-open class
    // is set to the <body> when there is at least one modal open left
    $('body').on('hidden.bs.modal', () => {
      if ($('.modal.show').length > 0) {
          $('body').addClass('modal-open');
      }
    });
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn();
  }

  checkLogin(route ?: string): void {

    if (this.authService.isUserLoggedIn()) {
      this.router.navigate([route]);
    } else {
      if (route) { $('#security-warn-gs').modal('show'); }
      else { $('#security-warn').modal('show'); }
    }
  }

  logIn(): void {
    $('#security-warn').modal('show');
  }

  showAlertContent(goldBarItem: any) {
    console.log('Gold bar Item :', goldBarItem);
    if (goldBarItem) {
      if (goldBarItem.name) {
        this.alertTitle = goldBarItem.name;

        if (goldBarItem.content) {
          this.alertContent = goldBarItem.content;
        }
      }

      $('#alert-info-modal').modal('show');
    }
  }

  scrollDown() {
    const body = $('html, body');
    $('html, body').animate({
      scrollTop: $('#whatCanIDo').offset().top
    }, 1000);
  }

  private _hasApplication(): boolean {
    if (this.authService.isUserLoggedIn()) {
      const dashMetaData = this.dashboardService.dashboard;
      if (dashMetaData) {
        if (dashMetaData.applications && dashMetaData.applications.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  private hasStoredLanguage(): boolean {
    if (localStorage.getItem(this.LOCAL_STORAGE_LANGUAGE_KEY)) {
      return true;
    } else {
      return false;
    }
  }

  // TODO Move to utils
  public getPreferredLanguage(languages: string[]): string {
    const storedLanguage = localStorage.getItem(this.LOCAL_STORAGE_LANGUAGE_KEY);
    if (storedLanguage) {
      return storedLanguage;
    }
    if (languages) {
      for (const lang of languages) {
        for (const validLang of ['es', 'fr', 'en']) {
          if (lang.startsWith(validLang)) {
            return validLang;
          }
        }
      }
    }
    return 'en';
  }

  public onRunComparison(choices: any) {
    this.displayWidgetResults = true;
    this.comparisonResults.runComparisons(choices);
    setTimeout(() => {
      document.getElementById('pp-widget').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 200);

    this.faqService.showHideFAQBar(false);
  }

  public onResetComparison($event: any) {
    this.displayWidgetResults = false;
    this.faqService.showHideFAQBar(true);
  }

  /**
   * Gets alerted that the user wants to reset the page to initial state. Resets the widget choices.
   */
  public onResetHomeState($event: any) {
    Utils.scrollUpFocusChildElem('comparison-tool-container');
    this.comparisonTool.reset();
  }

  public logGetStartedProgram(programName: string) {
    if (programName) {
      this.analyticsService.logGetStarted(programName).subscribe();
    }
  }

  public showConsiderTSAModal(): void {
    $('#modal-consider-tsa').modal('show');
  }

  public execContinueWithUP($event: any): void {
    $('#security-warn').modal('show');
  }
}

