<div class="page-content">
	<div class="row">
		<div class="col-lg-12 progress-top-level centered">
			<h1 translate>DASHBOARD.CHANGE_TO_GE</h1>
			<p class="divider hidden-xs"></p>
		</div>
	</div>

    <div class="container">

        <form #legalGuardianForm="ngForm">

            <div class ="content-panel">
                <div class="row">
                <div class="col-xs-12 col-sm-8">
                        <h2 translate="">APPLICATION.PERSONAL_INFO.LEGAL_GUARDIAN</h2>
                </div>
                </div>

                <p translate>APPLICATION.PERSONAL_INFO.LEGAL_GUARDIAN_INSTRUCTIONS</p>

                <div class="page-hint">
                    <i class="fa fa-lightbulb-o"></i> <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_HINT</span>
                </div>
            
                <!-- guardian last name and first name -->
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <label for="g_lastname">
                        <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_LASTNAME</span>
                        </label>
                        <input class="form-control" name="g_lastname" id="g_lastname" maxlength="50" [(ngModel)]="legalGuardian.lastName" [pattern]="textAlphaPattern" type="text"  required>
                        <div class= "field-error" *ngIf = "formErrors.g_lastname">
                            <p *ngFor= "let error of formErrors.g_lastname" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-6">
                        <label for="g_firstname">
                        <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_FIRSTNAME</span>
                        </label>
                        <input class="form-control" name="g_firstname" id="g_firstname" maxlength="30" [(ngModel)]="legalGuardian.firstName" [pattern]="textAlphaPattern" type="text"  required>
                        <div class= "field-error" *ngIf = "formErrors.g_firstname">
                            <p *ngFor= "let error of formErrors.g_firstname" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                </div>

                <!-- guardian middle name and date of birth -->
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <label for="g_middlename">
                        <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_MIDDLENAME</span>
                        </label>
                        <input class="form-control" name="g_middlename" id="g_middlename" maxlength="30" [(ngModel)]="legalGuardian.middleName" [pattern]="textAlphaPattern" type="text"  >
                        <div class= "field-error" *ngIf = "formErrors.g_middlename">
                            <p *ngFor= "let error of formErrors.g_middlename" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                    
                    <date-selection [minDate]="minDate" [domId]="'g_DOB'" [errorLabel]="'G_DOB'" [maxDate]="maxDate" 
                        [(ngModel)]= "legalGuardian.dob" #gdateOfBirth name="g_dateOfBirth" 
                        [label] = "'APPLICATION.PERSONAL_INFO.GUARDIAN_DATE_OF_BIRTH'" [required]="true"></date-selection> 
                    
                </div>

                <!-- guardian gender -->
                <div class="row">
                    <div class="col-xs-12">
                        <label for="g_gender" id="g_gender_label">
                            <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_GENDER</span>
                        </label>
                    </div>
                    <div class="col-xs-12">
                        <label for="maleLgGender" id="g_male_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.gender == 'M' }" tabindex="0" role="button"
                            (keyup.enter)="legalGuardian.gender = 'M'" (click)="legalGuardian.gender = 'M'" aria-labelledby="g_gender_label g_male_label">
                            <span aria-hidden="true"></span>
                            <input type="radio" name="g_gender" value="M" id="maleLgGender" [(ngModel)]="legalGuardian.gender" required>{{'APPLICATION.PERSONAL_INFO.MALE' | translate}}
                        </label>
                        
                        <label for="femaleLgGender" id="g_female_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.gender == 'F' }" tabindex="0" role="button"
                            (keyup.enter)="legalGuardian.gender = 'F'" (click)="legalGuardian.gender = 'F'" aria-labelledby="g_gender_label g_female_label">
                            <span aria-hidden="true"></span>
                            <input type="radio" name="g_gender" value="F" id="femaleLgGender" [(ngModel)]="legalGuardian.gender" required>{{'APPLICATION.PERSONAL_INFO.FEMALE' | translate}}
                        </label>

                        <!-- <label for="xLgGender" id="g_x_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.gender == 'X' }" tabindex="0" role="button"
                            (keyup.enter)="legalGuardian.gender = 'X'" (click)="legalGuardian.gender = 'X'" aria-labelledby="g_gender_label g_x_label">
                            <span aria-hidden="true"></span>
                            <input type="radio" name="g_gender" value="X" id="xLgGender" [(ngModel)]="legalGuardian.gender" required>{{'APPLICATION.PERSONAL_INFO.XGENDER' | translate}}
                        </label> -->

                        <p class="field-error" *ngFor= "let error of formErrors.g_gender" [innerHTML]= "error | translate"></p>
                </div>
                </div>


                <div class="page-hint" style="margin-top: 50px">
                    <i class="fa fa-lightbulb-o"></i> <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_LOOKUP_HINT</span>
                </div>

                <div class="row form-group" role="radiogroup" aria-labelledby="guardian_ttpid_label">
                    <div class="col-xs-12">
                        <label for="g_accountLookupTypeSel" id="guardian_ttpid_label">
                            <span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_MEM_OR_APP_ID</span>
                        </label>
                    </div>
                    <div class="col-xs-12">
                        <label id="aid_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.idType == 'A' }" role="radio">
                            <span aria-hidden="true"></span>
                            <input type="radio" id="g_appIdLookup" name="g_accountLookupTypeSel" value="A"  
                                [(ngModel)]="legalGuardian.idType" 
                                (keyup.enter)="clearGuardianAccountLookupType()" (click)="clearGuardianAccountLookupType()" required>{{'APPLICATION.PERSONAL_INFO.GUARDIAN_APPLICATION_ID_LABEL' | translate}}
                    </label> 
                    <label id="pid_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.idType == 'P' }" role="radio">
                            <span aria-hidden="true"></span>
                            <input type="radio" id="g_passIdLookup" name="g_accountLookupTypeSel" value="P" 
                                [(ngModel)]="legalGuardian.idType" 
                                (keyup.enter)="clearGuardianAccountLookupType()" (click)="clearGuardianAccountLookupType()" required>{{'SIGN_UP.MEMBERSHIP_LABEL' | translate}}
                        </label>
                        <label id="none_label" class="btn btn-radio" [ngClass]="{ 'active': legalGuardian.idType == 'N' }" role="radio">
                            <span aria-hidden="true"></span>
                            <input type="radio" id="g_noLookup" name="g_accountLookupTypeSel" value="N"  
                                [(ngModel)]="legalGuardian.idType" 
                                (keyup.enter)="clearGuardianAccountLookupType()" (click)="clearGuardianAccountLookupType()" required >{{'APPLICATION.PERSONAL_INFO.GUARDIAN_NONE_LABEL' | translate}}
                    </label>                 
                    </div>
                </div>

                <div class="row" *ngIf="legalGuardian.idType == 'P'">
                    <div class="col-xs-12">
                        <label for="g_passId">
                            <span class="required">*</span><span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_PASSID</span>
                        </label>
                    </div>

                    <div class="col-xs-12 col-md-6">
                        <input class="form-control" name="g_passId" id="g_passId" 
                            [value-type]="'numeric'" maxlength=9 minlength=9
                            [(ngModel)]="legalGuardian.passId"  required>
                        <div class= "field-error" *ngIf = "formErrors.g_passId">
                            <p *ngFor= "let error of formErrors.g_passId" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="legalGuardian.idType == 'A'">
                    <div class="col-xs-12">
                        <label for="g_applicationId">
                            <span class="required">*</span><span translate>APPLICATION.PERSONAL_INFO.GUARDIAN_APPLICATIONID</span>
                        </label> 
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <input class="form-control" name="g_applicationId" id="g_applicationId" [value-type]="'numeric'" 
                            [(ngModel)]="legalGuardian.applicationId" required>
                        <div class= "field-error" *ngIf = "formErrors.g_applicationId">
                            <p *ngFor= "let error of formErrors.g_applicationId" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <!-- Cancel/Submit Buttons -->
		<div class="row row-button-group">
            
			<div class="pull-right col-xs-12 col-sm-4 col-md-4">
				<button id="next" class="btn btn-nav btn-primary btn-block" type="submit" (click)="submitForm()" (keyup.enter)="submitForm()">
					<span translate>GENERAL_REUSABLE.CONFIRM_CONTINUE</span>
				</button>
			</div>

            <div class="pull-left col-xs-12 col-sm-4 col-md-3">
                    <a id="back" class="btn btn-default btn-nav btn-block" name="back" (click)="cancel()" tabindex="0" (keyup.enter)="cancel()">
					<span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
				</a>
			</div>


			<!-- <div class="col-xs-12 hidden-xs" style="text-align: right; color: #666">
				<i translate>{{nextCaption}}</i>
			</div> -->
		</div>
    </div>
</div>