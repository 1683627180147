import { environment } from './../../../../environments/environment';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../common/services/analytics.service';
import { SETTINGS } from '../../../app.constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

/**
 * This class represents the information modal component
 */
@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'program-info-modal',
  templateUrl: 'program-info-modal.component.html'
})

export class ProgramInfoModalComponent implements OnInit, AfterViewInit {

    schedulerViewLocationsUrl: string;

    /*
     * TODO should we get these from the reference data???
     * PROGRAMS DESC  - CODE - PRICE
     * GLOBAL ENTRY   - UP   - 120
     * TSA            - TSA  - 78
     * NEXUS          - NH   - 120
     * SENTRI         - SH   - 120
     * FAST           - FN   - 50
    */
    programCost: string;

    @Input()
    id: string;

    @Input()
    title: string;

    @Input()
    buttonText: string;

    // @Input('enrollment-center-url')
    enrollmentCenterUrl: string;

    @Input()
    membershipLink: string;

    @Input()
    program: string;

    @Input()
    benefits: string;

    public tsaGetStarteURL: string =  environment.tsaLoginURL;
    public tsaPreLearnURL =  'https://universalenroll.dhs.gov/programs/precheck';

    public tsaPreMapURL: string = environment.tsaLoginURL + '/map';

    currentLang: string;

    constructor(private analyticsService: AnalyticsService, private translate: TranslateService) {
        // set the scheduler view location url and also update it when language changes
        this.schedulerViewLocationsUrl = environment.uriScheduler + 'schedule-interview/location?lang=' + this.translate.currentLang + '&vo=true&returnUrl=' + SETTINGS.baseReturnService + '&service=';
        this.translate.onLangChange.subscribe((event: any) => {
            this.schedulerViewLocationsUrl = environment.uriScheduler + 'schedule-interview/location?lang=' + this.translate.currentLang + '&vo=true&returnUrl=' + SETTINGS.baseReturnService + '&service=';
            this.currentLang = event.lang;
        });
    }

    ngOnInit() {
        this.programCost = this.getPricesFromProgram(this.program);
        this.enrollmentCenterUrl = this.getEnrollmentCenterURL(this.program);
    }

    // Ignored because modal would appear after the test has executed
    /* istanbul ignore next */
    ngAfterViewInit() {
        const modalId = this.id; // The global context is lost in the on event method

        // jQuery for toggling the arrow image.
        $('#eptCollapsible-' + modalId).on('show.bs.collapse', () => {
          $('#collapse-arrow1-' + modalId).removeClass('fa-chevron-right').addClass('fa-chevron-down');
        })
        .on('hide.bs.collapse', () => {
            $('#collapse-arrow1-' + modalId).removeClass('fa-chevron-down').addClass('fa-chevron-right');
          });

        $('#delayCollapsible-' + this.id).on('show.bs.collapse', () => {
            $('#collapse-arrow2-' + modalId).removeClass('fa-chevron-right').addClass('fa-chevron-down');
        })
        .on('hide.bs.collapse', () => {
            $('#collapse-arrow2-' + modalId).removeClass('fa-chevron-down').addClass('fa-chevron-right');
        });

        // jQuery to reset collapsed
        $('#' + modalId).on('hidden.bs.modal', () => {
            $('#eptCollapsible-' + modalId).collapse('hide');
            $('#delayCollapsible-' + modalId).collapse('hide');
        });
    }

    private getPricesFromProgram(programCode: string) {
        let cost;
        switch (programCode) {
            case 'UP' : cost = '$120';
                        break;
            case 'TSA': cost = '$77.95';
                        break;
            case 'NH' : cost = '$120';
                        break;
            case 'SH' : cost = '$120';
                        break;
            case 'FN' : cost = '$50';
                        break;
            default: cost = '$120';
                     break;
        }

        return cost;
    }

    private getEnrollmentCenterURL(programCode: string) {
        let url;
        switch (programCode) {
            case 'UP' : url = this.schedulerViewLocationsUrl + 'up';
                        break;
            case 'TSA': url = 'https://www.tsa.gov/precheck/enrollment-centers';
                        break;
            case 'NH' : url = this.schedulerViewLocationsUrl + 'nh';
                        break;
            case 'SH' : url = this.schedulerViewLocationsUrl + 'sh';
                        break;
            case 'FN' : url = this.schedulerViewLocationsUrl + 'fn';
                        break;
            default: url = '';
                     break;
        }
        return url;
    }


    /**
     * Closes one modal and opens the security modal.
     *
     * @param originalModalId = the id for the modal
     */
    public logIn(originalModalId?: string): void {
        if (originalModalId) {
            $('#' + originalModalId).modal('hide');
        }

        // When the data-dismiss is used on the html element or the jquery hide is called modal-open
        // class is removed from the body tag.  So scrolling is messed up. This is to fix that.
        setTimeout(() => {
            $('#security-warn').modal('show');
        }, 400);
    }

    public logGetStartedProgram(programName: string) {
        if (programName) {
          this.analyticsService.logGetStarted(programName).subscribe();
        }
    }
}
