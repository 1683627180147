import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../common/services/auth.service';
import { Router } from '@angular/router';
import { SETTINGS } from '../../../app.constants';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;

@Component({
  moduleId: module.id,
  selector: 'security-modal',
  templateUrl: 'security.component.html'
})

export class SecurityModalComponent implements OnInit {

	@Input()
	id:string;

	@Input('route')
	route:string;

	@Input()
	routeParams: string;

	constructor(
		private router: Router,
		private authService: AuthService,
		private translate: TranslateService
  	) {}

	ngOnInit(): void { }

	continue(overrideWindow?: any): void {
		if (this.route == '/dashboard') this.route = '';
		if (this.route) {
			$('#security-warn').modal('hide');
			$('#security-warn-gs').modal('hide');
			this.router.navigate([this.route]);
		}
		else if (!this.authService.isUserLoggedIn()) {

      this.authService.logIn().subscribe(res=>{
        if (res) {
          $('#security-warn').modal('hide');
    			$('#security-warn-gs').modal('hide');
          let user = this.authService.getUser();
          if (user.name) {
            this.router.navigate(['/dashboard']);
          }
          else {
            this.router.navigate(['/account-profile/' + user.userId]);
          }
        }
      });
		}
	}

  decline() : void {
      this.router.navigate(['/']);
  }

	private gotoRoute() {
		if(this.routeParams)
			this.router.navigate([this.route, this.routeParams]);
		else
			this.router.navigate([this.route]);
	}
}
