import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component ({
    moduleId : module.id,
    templateUrl : 'privacy-act.component.html'
})

export class PrivacyActComponent {

    constructor() {
    }

    public closePrivacyAct() : void {
        window.close();
    }
}
