import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../shared/modals/modal.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
  	private authService: AuthService,
  	private router: Router,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private modalService: ModalService
  	){}

  canActivate() {
    return this._isLoggedIn();
  }

  private _isLoggedIn(): boolean {
  	if (this.authService.isUserLoggedIn()) {
  		return true;
  	}
    else {
      if (this.authService.hasUserLoggedIn()) {
        this.translate.get('SESSION.EXPIRED').subscribe((res:string) => {
          this.modalService.notify(res, "Session", "OK").then(res => {
            this.authService.logOut();
            this.spinner.hide();
          });
        });
        return false;
      }
      else {
        this.authService.logOut();
        this.spinner.hide();
        return false;
      }
    }
  }

}
