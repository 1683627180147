import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../common/services/auth.service';
import { User } from '../../common/models/user';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../shared/modals/modal.service';
import { SystemConfigService } from '../../common/services/system-config.service';
import { SurveyStatsService } from '../../common/services/survey-stats.service';
import { AnalyticsService } from '../../common/services/analytics.service';

import Utils from '../../common/utils/utils';
import { environment } from '../../../environments/environment';

declare var $: any;

/**
 * This class represents the navigation bar component.
 */
@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'go-navbar',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {

    user = new User();
    isUserAuthenticated: boolean;
    showLearnMore: boolean;
    canShowNav = true;
    hideBar = false;
    browsertab: any;
    accountCreation = false;

    surveyAvailable = false;
    surveyUrl: string;

    tsaURL: string = environment.tsaLoginURL;

    constructor(
        public translate: TranslateService,
        public authService: AuthService,
        private router: Router,
        private modalService: ModalService,
        private sysConfigService: SystemConfigService,
        private surveyStatsService: SurveyStatsService,
        private analyticsService: AnalyticsService)
    {
        authService.authorizedUser$.subscribe(user => {
            this.user = user;
            this.canShowNav = this._hasUserAccountORUnAuthenticated();
        });

        // on route change, retrieve new page level faqs
        this.router.events.subscribe((event: any) => {
           if (event instanceof NavigationEnd){
               const routeName = Utils.getRouteName(this.router.url);
               if (routeName  === 'faq' || routeName === 'under-maintenance' || routeName == 'privacy-act') {
                   this.hideBar = true;
               }
               else {
                   this.hideBar = false;
               }
               // add logout button for account creation.  route guard blocks route if not logged on in.
               if (routeName === 'account-profile'){
                   this.accountCreation = true;
               }
               else {
                   this.accountCreation = false;
               }
           }
        });

        this.sysConfigService.initData().then((res: any) => {
            if (res) {
                this.surveyUrl = res.surveyUrl;
                this.surveyAvailable = res.surveyAvailable;
            }
        });
    }

    ngOnInit(): void {
        this._setUser();
    }

    logOut(event: Event): void {
        event.preventDefault();

        if (this.surveyAvailable) {
            const promptBody = 'NAVBAR.SURVEY.PROMPT_BODY';
            const promptTitle = 'NAVBAR.SURVEY.PROMPT_TITLE';
            const promptBtnNo = 'GENERAL_REUSABLE.NO_BUTTON';
            const promptBtnYes = 'GENERAL_REUSABLE.YES_BUTTON';

            this.modalService.confirm(promptBody, promptTitle, promptBtnNo, promptBtnYes)
                .then(
                    res => {
                        if (res) {
                            const surveyParam = 'survey=true';
                            this.surveyStatsService.logSurveyChoiceYes(this.authService.getUser().userId).subscribe();
                            this.authService.logOutAndRedirect(surveyParam, this.surveyUrl);
                        } else {
                            this.surveyStatsService.logSurveyChoiceNo(this.authService.getUser().userId).subscribe();
                            this.authService.logOut();
                        }
                    }
                );
        } else {
            this.authService.logOut();
        }

        this._setUser();
    }

    logIn(): void {
        $('#security-warn').modal('show');
    }

    collapse(id: string) {
        document.getElementById(id).setAttribute('class', 'collapse navbar-collapse');
    }

    openFAQ(){
        const url = environment.baseUrl + 'faq?lang=' + this.translate.currentLang;
        if (!this.browsertab || this.browsertab.closed) {
            this.browsertab = window.open(url, '_blank');
            // add a load listener to the window so that the title gets changed on page load
            this.browsertab.addEventListener('load', function() {
                this.browsertab.document.title = 'FAQ - Trusted Traveler Programs';
            });
            this.collapse('app-navbar');
        }
        this.browsertab.focus();
    }

    public logTsaLinkClicked() {
        this.analyticsService.logGoToTSASelected().subscribe();
    }

    public logLoginButtonClicked() {
        this.analyticsService.logLogin().subscribe();
    }

    public scrollToFaq() {
        Utils.scrollUpFocusChildElem('faqbar');
    }

    /* return true if user is not logged in, or if user is logged in and has an account */
    private _hasUserAccountORUnAuthenticated(): boolean {
        // if user name is set (only done as part of TTP account creation)
        // and user is also logged in
        if (!this.authService.isUserLoggedIn() || (this.user.name && this.authService.isUserLoggedIn())){
            return true;
        }
        return false;
    }

    private _setUser(): void {
        this.isUserAuthenticated = this.user.username ? true : false;
    }
}
