import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleService } from '../../common/services/schedule.service';
import { AuthService } from '../../common/services/auth.service';
import { AppointmentModel } from '../../common/models/appointments/appointment.model';
import { GESAppointmentModel } from '../../common/models/appointments/ges-appointment.model';
import { SchedulerModel } from '../../common/models/appointments/scheduler.model';
import { ApplicationMetadata } from '../../common/models/application-metadata.model';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';
import { ModalService } from '../../shared/modals/modal.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { User } from '../../common/models/user';
import { TranslateService } from '@ngx-translate/core';
import Utils from '../../common/utils/utils';
import * as moment from 'moment-timezone';

@Component({
    moduleId: module.id,
    templateUrl: 'interview-cancel.component.html'
})
export class InterviewCancelComponent implements OnInit {

    public appointmentModel: AppointmentModel = null;
    public applicationMetadata: ApplicationMetadata = null;
    public user: User = new User();
    public programName = '';
    protected programId = '';
    public isIE = false;

    public cancelReason = '';
    public hasCancelReasonError = false;
    moment: any;

    protected gesAppointmentModel: GESAppointmentModel = new GESAppointmentModel();

    constructor(private router: Router,
                private scheduleService: ScheduleService,
                private referenceService: TranslateReferenceService,
                private authService: AuthService,
                private modalService: ModalService,
                private translate: TranslateService,
                private spinner: SpinnerService) {
                    this.moment = moment;
                }

    ngOnInit() {

        if (this.scheduleService.focusedAppointmentApp != null) {
            this.setup();
        } else {
            // appointment data has not been gathered so redirect
            this.returnHome();
        }

        // this.setTestData();
    }

    private setup(){
        this.spinner.show();
        this.checkForIE();
        this.applicationMetadata = this.scheduleService.focusedAppointmentApp;
        this.programId = this.applicationMetadata.programs.length > 0 ? this.applicationMetadata.programs[0] : '';
        this.user = this.authService.getUser();
        this.scheduleService.getAppointment(
            this.scheduleService.focusedAppointmentApp.applicationId,
            this.scheduleService.focusedAppointmentApp.source)
            .subscribe(
                (res: any) => {
                    this.spinner.hide();
                    this.appointmentModel = new AppointmentModel(res);
                }, error => {
                    this.spinner.hide();
                }
            );
        // let hasErrors:boolean = false;
        this.setupReferenceData();
    }

    private checkForIE(): void {
        const result: any = Utils.detectIE();
        if (result){
            this.isIE = true;
        }
    }

    public onSubmit(event: any){
        if (this.cancelReason.length > 0 && this.applicationMetadata != null
            && this.applicationMetadata.programs.length > 0
            && this.appointmentModel != null){

            this.spinner.show();

            this.hasCancelReasonError = false;

            this.gesAppointmentModel.applicationCode = Number(this.applicationMetadata.applicationId);
            this.gesAppointmentModel.startTimestamp = this.appointmentModel.startTimestamp;
            this.gesAppointmentModel.endTimestamp = this.appointmentModel.endTimestamp;

            this.gesAppointmentModel.enrollmentCenterName = this.appointmentModel.locationName;
            this.gesAppointmentModel.appointmentId = this.appointmentModel.id;

            this.gesAppointmentModel.passId = this.user.membershipOrPassId;
            this.gesAppointmentModel.programId = this.applicationMetadata.programs[0].toLowerCase();
            this.gesAppointmentModel.locationId = Number(this.appointmentModel.locationId);
            this.gesAppointmentModel.type = SchedulerModel.GES_STATUS_CANCELLED;
            this.gesAppointmentModel.eventTypeId = SchedulerModel.APPOINTMENT_TYPE_CANCEL_ID;
            this.gesAppointmentModel.rescheduledEventTypeId = null;

            this.gesAppointmentModel.source = this.applicationMetadata.source;
            this.gesAppointmentModel.goesUserId = this.user.userId;
            //
            this.gesAppointmentModel.type = SchedulerModel.GES_STATUS_CANCELLED;
            this.gesAppointmentModel.cancellationReason = this.cancelReason;

            this.scheduleService.deleteAppointment(this.gesAppointmentModel).subscribe(
                res => {
                    this.spinner.hide();
                    if (res){
                        this.router.navigate(['/cancellation-summary']);
                    } else {
                        this.translate.get('INTERVIEW_CANCEL.ERROR').subscribe(resTranslate => {
                            this.modalService.alert(resTranslate);
                        });
                    }
                },
                error => {
                    this.spinner.hide();
                    this.hasCancelReasonError = true;
                    this.gesAppointmentModel.cancellationReason = 'Error cancelling appointment';
                    this.translate.get('INTERVIEW_CANCEL.ERROR').subscribe(res => {
                        this.modalService.alert(res);
                    });
                }
            );

        } else {
            this.hasCancelReasonError = true;
            this.translate.get('INTERVIEW_CANCEL.REQUIRED_EXPLAIN').subscribe(res => {
                this.modalService.alert(res);
            });
        }
    }

    public onBack(event: any){
        this.router.navigate(['/dashboard']);
    }

    protected setupReferenceData(){
        this.programName = this.referenceService.getProgramName(this.programId);
    }

    protected returnHome(){
        this.router.navigate(['/']);
    }

    public goToReschedule(event: any) {

        if (this.scheduleService.focusedAppointmentApp.canDoRemoteInterview) {

            this.router.navigate(['dashboard/select-interview-type'],
                { queryParams: { appId: this.scheduleService.focusedAppointmentApp.applicationId,
                    scheduleType: AppointmentModel.TYPE_UPDATE } });
        } else {
            const url: string = this.scheduleService.returnRescheduleUri(
                this.scheduleService.focusedAppointmentApp, this.authService.getUser());
            this.authService.refreshTokenAndGotoExternalUrl(url);
        }
    }

/*
    private setTestData(){
        //
        this.appointmentModel = new AppointmentModel();
        this.appointmentModel.locationName = "EC Center #1";
        this.appointmentModel.locationPhone = "(703) 888-1111";
        this.appointmentModel.locationDirections = "Take freeway to 1st street.";
        this.appointmentModel.startDateObject = new Date("2017-06-01T08:00:00");
        this.appointmentModel.startTime = '08:00';
        //
        this.appointmentModel.startTimestamp = '2017-09-12T08:45:00';
        this.appointmentModel.endTimestamp = '2017-09-12T09:00:00';
        this.appointmentModel.id = 1;
        this.appointmentModel.locationId = 1;
        //
        this.user = new User();
        this.user.membershipOrPassId = '779092655';
        this.user.userId = '7d470cac-b0d2-kenj-will-4a76119c29aa';
        //
        this.applicationMetadata = new ApplicationMetadata({});
        this.applicationMetadata.applicationId = '665';
        this.applicationMetadata.programs = new Array<string>();
        this.applicationMetadata.programs.push('UP');
        //
        this.programId = this.applicationMetadata.programs[0];

        this.setupReferenceData();
    }
*/
}
