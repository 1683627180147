
<a href="javascript:void(0);" (click)="skip();" (key.enter)="skip();" class="skip">{{'HOME.SKIP_TO_CONTENT' | translate}}</a>

<!-- // OFFICIAL STATEMENT -->
<div class="us-official">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12 text-left official-notice">
        <img alt="{{'IMAGE_ALT.US_FLAG' | translate}}" src="assets/images/flags/usFlag.png"/>
        {{'TOOLBAR.OFFICIAL_NOTICE' | translate}}
      </div>
      <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12 text-right language-menu">
        <div class="dropdown pull-right">
          <b class="hidden-xs hidden-sm">{{'TOOLBAR.CHOOSE_LANGUAGE' | translate}}:&nbsp;&nbsp;</b>
          <button class="btn dropdown-toggle" type="button" id="languageMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <img alt="{{'IMAGE_ALT.US_UK_FLAG' | translate}}" src="{{flagSrc}}"> {{ currentLanguage }}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" role="menu" aria-labelledby="">
            <li>
              <a href="javascript:void(0)" (click)="setAndSaveLanguage('en')" title="English (U.S./U.K.)">
                <img alt="{{'IMAGE_ALT.US_UK_FLAG' | translate}}" src="assets/images/flags/usUkFlag.png">
                English (U.S./U.K.)
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="setAndSaveLanguage('fr')" title="French">
                <img alt="{{'IMAGE_ALT.FRENCH_FLAG' | translate}}" src="assets/images/flags/franceFlag.png">
                Français
              </a>
            </li>
            <li class="last">
              <a href="javascript:void(0)" (click)="setAndSaveLanguage('es')" title="Spanish">
                <img height="26" alt="{{'IMAGE_ALT.MEXICAN_FLAG' | translate}}" src="assets/images/flags/mexicoFlag.png">
                Español
              </a>
            </li> 
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- // LOGOS + NAVBAR -->
<div class="container">

  <!-- // LOGOS -->
  <div class="header">
    <!-- // TRUSTED TRAVELER PROGRAMS LOGO -->
    <div class="logo-ttp-main">
      <div class="cbp">
        <a href="http://www.dhs.gov" target="_blank">
          <img alt="{{'IMAGE_ALT.DHS_SEAL' | translate}}" src="assets/images/logos/tsaprecheck-banner.png">
        </a>
      </div>
      <!-- <div class="cbp">
        <a href="http://www.dhs.gov" target="_blank">U.S. Department of<br/> Homeland Security</a> 
      </div> -->
      <div class="ttp" *ngIf="!disableButtons">
        <a [routerLink]="['/']">Trusted Traveler <br class="visible-xs">Programs</a>
      </div>
      <div class="ttp" *ngIf="disableButtons">
         Trusted Traveler <br class="visible-xs">Programs
      </div>

      <div class="user-menu-container pull-right">    
        <!-- // User Menu -->
        <ul class="user-menu">
          <!--login.gov-->
          <li class="user-info" *ngIf="user.loginGovEmail">
            <span translate>HOME.LOGINGOV_LABEL</span>
            <b>{{user.loginGovEmail}}</b>
          </li>
          <!--Name-->
          <li *ngIf="isUserAuthenticated && user.name" class="user-info">
            <span translate>HOME.NAME_LABEL</span>
            <b>{{user.name}}</b>
          </li>
          <!--PASSID-->
          <li class="user-info" *ngIf="isUserAuthenticated && user.name">
            <span translate>HOME.MEMBERSHIP_LABEL</span>
            <b>{{ user.membershipOrPassId || 'GENERAL_REUSABLE.NO_PASSID' | translate }}</b>
          </li>
        </ul>
          <!--<li class="dropdown pull-right">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;&nbsp;
              {{ user.name }} &nbsp;&nbsp;<span class="caret"></span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-info">
                <span *ngIf="user.email">
                  <b>Email:</b>
                  {{ user.email }}
                  <hr>
                </span>
                <b>Membership # / PASSID:</b>
                <span id="membership-id">{{ user.membershipOrPassId || 'GENERAL_REUSABLE.NO_PASSID' | translate }}</span>
              </li>-->
              <!-- <li class="menu-item"><a href="#" title="{{'TOOLBAR.USER_MENU.CHANGE_PASSWORD' | translate}}">{{'TOOLBAR.USER_MENU.CHANGE_PASSWORD' | translate}}</a></li>
              <li class="menu-item"><a href="#" title="{{'TOOLBAR.USER_MENU.CHANGE_SECURITY_QUESTIONS' | translate}}">{{'TOOLBAR.USER_MENU.CHANGE_SECURITY_QUESTIONS' | translate}}</a></li> -->
              <!-- <li class="menu-item last"><a href="#" title="{{'TOOLBAR.USER_MENU.EDIT_GOES_PROFILE' | translate}}">{{'TOOLBAR.USER_MENU.EDIT_GOES_PROFILE' | translate}}</a></li> -->
            <!--</ul>-->
          <!--</li>-->
      </div>
    </div>

  </div>

</div>
