
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../app/common/services/auth.service';
import { SpinnerService } from '../app/shared/spinner/index';
import { DashboardService } from '../app/common/services/dashboard.service';
import Utils, { WebViewMsg } from './common/utils/utils';
@Component({
  selector: 'payment-routing',
  template: ``
})
export class PaymentRoutingComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private spinner: SpinnerService,
    private router: Router,
    private dashbaordService: DashboardService) { }

  ngOnInit(): void {
    this._login();
  }

  private _login() {

    this.spinner.show();

    const route = '';


    this.route.params.subscribe((params: Params) => {
        this.route.queryParams.subscribe((qparams: Params) => {

            const id =  qparams.id;
            const status = params.status;
            const lang = qparams.lang;

            if (lang) {
              this.translate.use(lang);
            }

            if (status === 'success' && id) {
                this.dashbaordService.setAlertStatus(status);
                this.dashbaordService.setAlertLink('/payment-history/' + id);
                this.dashbaordService.setAlertMessage('DASHBOARD.PAYMENT_SUCCESS');
                this.dashbaordService.setAlertLinkMessage('DASHBOARD.VIEW_RECIEPT');
            }
            else if (status === 'failure') {
               this.dashbaordService.setAlertStatus(status);
               this.dashbaordService.setAlertMessage('DASHBOARD.PAYMENT_FAILED');
            }

            // Notify flutter web view
            const flutterParams = {
              status,
              paymentId: id
            };

            Utils.notifyWebView(WebViewMsg.PAYMENT, flutterParams);

            this.authService.reLogin().subscribe(res => {
              if (res) {
                const user = this.authService.getUser();
                if (user.name) {
                  this.router.navigate(['/dashboard']).then(() => {
                    this.spinner.hide();
                  });
                }
                else {
                  this.router.navigate(['/account-profile/' + user.userId]).then(() => {
                    this.spinner.hide();
                  });
                }
              }
            });
      });
    });

  }
}
