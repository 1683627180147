import { Component } from '@angular/core';
import { ModalService } from '../modal.service';
declare var $:any;
/**
 * This class represents the information modal component
 */
@Component({
  moduleId: module.id,
  selector: 'alert-modal',
  templateUrl: 'alert-modal.component.html'
})

export class AlertModalComponent {

	translateSrc: string;
	params:any = { value: '' };

	constructor(modalService: ModalService) {
		// modalService.alert = this.alert.bind(this);
		modalService.alert$.subscribe(data => this.alert(data.key, data.params));
	}

	// todo: at times this is being set with an error string, not a translation key
	alert(msgKey: string, params?: any): void {
		this.translateSrc = msgKey;
		this.params = params;
		$('#alertModal').modal();
	}

}
