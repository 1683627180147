// PROD BUILD ENV
declare global {
  interface Window { env: any; }
}

window.env = window.env || {};

export const environment = {
  production: true,
  useStaticTestAPIData: window.env.useStaticTestAPIData,
  apiVersion: window.env.apiVersion || 'v1/',
  uriEndpoint: window.env.uriEndpoint || 'https://ttp-app-dev.cbp.dhs.gov/api/',
  uriCSEndpoint: window.env.uriCSEndpoint || 'https://credsrv-dev.cbp.dhs.gov/',
  uriSchedulerEndpoint: window.env.uriSchedulerEndpoint || 'https://ttp-scheduler-dev.cbp.dhs.gov/schedulerapi/',
  uriScheduler: window.env.uriScheduler || '/schedulerui/',
  baseUrl: window.env.baseUrl || 'https://ttp-dev.cbp.dhs.gov/',

  serviceProviderName : window.env.serviceProviderName || 'GOES-local',
  TOKEN_REFRESH_BEFORE_MIN : window.env.TOKEN_REFRESH_BEFORE_MIN || 5,
  BYPASS_ENABLED : window.env.BYPASS_ENABLED,
  SHOW_API_ERRORS : window.env.SHOW_API_ERRORS,
  underMaintenance : window.env.underMaintenance,

  tsaLoginURL: window.env.tsaLoginURL || 'https://edit-beta-testint.tsa.gov/precheck',

  // Whitelist/Blacklist for angular jwt
  allowedDomains: window.env.allowedDomains || [
    'ttp-app-dev.cbp.dhs.gov',
    'localhost:9090',
    'ttp-app-dev2.cbp.dhs.gov',
    'localhost:4080'
  ],
  disallowedRoutes: window.env.disallowedRoutes || [
      /ttp-app-dev.cbp.dhs.gov\/api\/v1\/goesapp\/reference\/data/g,
      /ttp-app-dev2.cbp.dhs.gov\/api\/v1\/goesapp\/reference\/data/g,
      /localhost:9090\/api\/v1\/goesapp\/reference\/data/g,
  ],
  authenticateWithCookie: true
};
