<div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="'MODAL.SECURITY_MODAL_TITLE' | translate"></h4>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img src="assets/images/logos/dhs-seal.png" alt="{{'IMAGE_ALT.DHS_SEAL' | translate}}">
        </div>
        <p [innerHTML]=" 'MODAL.SECURITY_MODAL_BODY' | translate"></p>
      </div>
      <div class="modal-footer">
        <div class="row row-button-group">
    	  	<div class="col-xs-12 col-sm-6 col-sm-push-5 col-sm-offset-1">
          	<button type="button" class="btn btn-block btn-primary" (click)="continue()">
              <span translate>GENERAL_REUSABLE.CONSENT_CONTINUE</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
        	</div>
          <div class="col-xs-12 col-sm-5 col-sm-pull-7">
            <button type="button" class="btn btn-block btn-default" (click)="decline()" data-dismiss="modal">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span translate>GENERAL_REUSABLE.DECLINE_EXIT</span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

