<div class="how-enrollment-works">
		<div class="row">
		    <div class="bar bar-start" style="width: 30.4%; margin-left: 1.5%; float: left;" [ngClass]="{ 'bar-inactive': stepDone == 0 }"></div>
		    <div class="bar" style="margin: 0% 2.9%; width: 30.4%; float: left;" [ngClass]="{ 'bar-inactive': stepDone <= 3 }"></div>
		    <div class="bar bar-end" style="width: 30.4%; margin-right: 1.5%; float: right;" [ngClass]="{ 'bar-inactive': stepDone <= 7 }"></div>
		</div>
	<div class="row">
		<div class="col-xs-12 col-md-4">
			
			<div class="first">
				<h2 translate>HOME.ENROLLMENT.ACCOUNT</h2>
				<ul>
					<li [ngClass]="{ active: stepDone >= 1 }" translate>HOME.ENROLLMENT.CREATE_ACCOUNT</li>
					<!--<li [ngClass]="{ active: stepDone >= 2 }" translate>HOME.ENROLLMENT.LOG_IN</li>-->
					<li [ngClass]="{ active: stepDone >= 3 }" translate>HOME.ENROLLMENT.FILL_OUT_PROFILE</li>
				</ul>
                <p>
				    <a href="" data-toggle="modal" data-target="#mdl-why-login" translate>HOME.ENROLLMENT.WHY_LOGIN_GOV</a>
                </p>
			</div>
		</div>
		<div class="col-xs-12 col-md-4">
			<div class="notfirst-mobile">
				<h2 translate>HOME.ENROLLMENT.APPLICATION</h2>
				<ul>
					<li [ngClass]="{ active: stepDone >= 4 }" translate>HOME.ENROLLMENT.SELECT_PROGRAM</li>
					<li [ngClass]="{ active: stepDone >= 5 }" translate>HOME.ENROLLMENT.FILL_OUT_APPLICATION</li>
					<li [ngClass]="{ active: stepDone >= 6 }" translate>HOME.ENROLLMENT.PAY_FEE</li>
					<li [ngClass]="{ active: stepDone >= 7, hourglass: stepDone < 7 }" translate>HOME.ENROLLMENT.WAIT_CONDITIONAL_APPROVAL</li>
				</ul>
				<p>
					<a href="" data-toggle="modal" data-target="#mdl-what-i-need" translate>HOME.ENROLLMENT.WHAT_WILL_I_NEED</a>
				</p>
			</div>

		</div>
		<div class="col-xs-12 col-md-4">
			<div class="notfirst-mobile">			
				<h2 translate>HOME.ENROLLMENT.INTERVIEW</h2>
				<ul>
					<!-- <li [ngClass]="{ active: stepDone >= 8 }" translate>HOME.ENROLLMENT.SCHEDULE_INTERVIEW</li> -->
					<li [ngClass]="{ active: stepDone >= 8 }" translate>HOME.ENROLLMENT.ATTEND_INPERSON_INTERVIEW</li>
					<li [ngClass]="{ active: stepDone >= 9 }" translate>HOME.ENROLLMENT.HAVE_INTERVIEW</li>
					<!--<li [ngClass]="{ active: stepDone >= 10, hourglass: stepDone < 10 }" translate>HOME.ENROLLMENT.WAIT_APPROVAL</li>-->
					<li [ngClass]="{ active: stepDone >= 11 }" translate>HOME.ENROLLMENT.ACTIVATE_MEMBERSHIP</li>
				</ul>
				<p>
					<!--/* //NOSONAR */--><a target="_blank" href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-arrival" rel="noopener" translate>MODAL.FIND_UP_EOA</a>
					<br>
					<a href="{{schedulerViewLocationsUrl}}" target="_blank" translate>HOME.ABOUT.CHECK_INTERVIEW_AVAILABILITY</a>
				</p>
			</div>
		</div>
	</div>
</div>

<info-modal 
  [id]="'mdl-why-login'" 
  [title]="'FAQ.WHY_LOGIN_GOV_TITLE'"
  [buttonText]="'MODAL.BUTTON_CLOSE'"            
  [translate-src]="'FAQ.WHY_LOGIN_GOV_TEXT'"></info-modal>

<info-modal 
  [id]="'mdl-what-i-need'" 
  [title]="'FAQ.WHAT_NEED_TITLE'"
  [buttonText]="'MODAL.BUTTON_CLOSE'"            
  [translate-src]="'FAQ.WHAT_NEED_TEXT'"></info-modal>

 <info-modal 
  [id]="'mdl-app-fee'" 
  [title]="'FAQ.APP_FEE_TITLE'"
  [buttonText]="'MODAL.BUTTON_OK'"            
  [translate-src]="'FAQ.APP_FEE_TEXT'"></info-modal>

<info-modal 
  [id]="'mdl-where-interviews'" 
  [title]="'FAQ.WHERE_INTERVIEWS_TITLE'"
  [buttonText]="'MODAL.BUTTON_OK'"            
  [translate-src]="'FAQ.WHERE_INTERVIEWS_TEXT'"></info-modal>