<div class="intro-area-start">
    <div class="container program-grid">
      <div class="row">
        <div class="col-sm-12 text-center" data-label="title">
          <h2 class="ttp-header-text" translate>HOME.TRUSTED_TRAVELER_TITLE_TEXT</h2>
          <p class="ttp-subheader-text" translate>HOME.INTRO_TITLE_TTP</p>
        </div>
        <div class="col-xs-12 text-center">
          <span class="middle-lineText" translate>
            HOME.MIDDLE_CONTENT_SUBTEXT
          </span>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-xs-12 text-center">
          <span class="ttp-subheader-text" translate>
            HOME.MIDDLE_CONTENT_SUBTEXT
          </span>
        </div>
      </div> -->
    </div>
</div>

  <!-- // PROGRAM GRID -->
  <!-- <a name="program-grid"></a> -->
  <div class="container">
    <div class="row">
      <div class="ttp-columns col-xs-12">
        <div class="program-grid">
          <div class="row program-row">
            <div class="col-xs-12 col-md-2">
              <div class="program-cell">
                <div>
                  <div class="precheck-container">
                    <img class="TSA-pre" src="assets/images/logos/tsaprecheck-official.png" alt="{{'IMAGE_ALT.TSA_PRECHECK_LOGO' | translate}}">
                    <!-- <span>TSA PreCheck<sup>&reg;</sup></span> -->
                  </div>
                  <div>
                    <p class="text-top" translate>HOME.TSA_TEXT_TOP</p>
                  </div>
                  <div class="card-best-for">
                    <p [innerHTML]="'HOME.TSA_BEST_FOR' | translate"></p>
                  </div>
                  <div class="program-price-container-tsa">
                    <span class="program-price">{{'MODAL.FROM' | translate}}</span>
                    <h2 class="program-price program-price-air"><b>$77.95 <span class="usd-26">USD</span></b></h2>
                    <div class="tsa-price-disclaimer" translate>MODAL.NEW_ENROLLMENT</div>
                  </div>
                </div>
                <div class="tsa-pre-include-spacer">
                </div>
                <div class="eligible-text">
                  <p  [innerHTML]="'HOME.ELIGIBILITY_INFOTEXT' | translate"></p>
                </div>
                <div class="processing-time-container">
                  <p>
                    <span><i class="fa fa-calendar-check-o"></i></span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.LABEL</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.TSA_MIN</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.TSA_MAX</span>
                </div>
                <div class="start-button">
                  <a href="" class="learn-more" data-toggle="modal" data-target="#md5-learn-more" translate>HOME.ABOUT.LEARN_MORE</a>
                  <a class="btn btn-success get-started" tabindex="0" [attr.href]="TSA_LOGIN_URL" (click)="logGetStartedProgram('TSA');" (keyup.enter)="logGetStartedProgram('TSA');"
                    target="_blank" rel="noopener noreferrer" translate>HOME.GET_STARTED</a>
                </div>
              </div>
            </div>
            <div class="col-xs-12  col-md-2 ">
              <div class="program-cell">
                <div>
                  <div class="logo-container">
                    <img class="global-entry" src="assets/images/logos/logo-global-entry.png" alt="{{'IMAGE_ALT.GLOBAL_ENTRY_LOGO' | translate}}">
                  </div>
                  <div><p class="text-top" translate>HOME.STREAMLINED_STATEMENT_UP</p></div>
                  <div class="card-best-for">
                    <p [innerHTML]="'HOME.UP_BEST_FOR' | translate"></p>
                  </div>
                  <div class="program-price-container">
                    <h2 class="program-price"><b>$120 <span class="usd-26">USD</span></b></h2>
                  </div>
                </div>
                <div>
                  <span class="tsa-pre-inclusion tsa-pre-include" translate>HOME.TSA_INCLUDE</span>
                  <div class="tsa-pre-include-text">TSA PreCheck<sup>&reg;</sup></div>
                </div>
                <div class="eligible-text">
                  <p [innerHTML]="'HOME.ELIGIBILITY_FOREIGN_INFOTEXT' | translate"></p>
                </div>

                <div class="processing-time-container">
                  <p>
                    <span><i class="fa fa-calendar-check-o"></i></span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.LABEL</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.VARY</span>
                </div>
                <div class="start-button">
                  <a href="" class="learn-more" data-toggle="modal" data-target="#md2-learn-more" translate>HOME.ABOUT.LEARN_MORE</a>
                  <a class="btn btn-success get-started" tabindex="0" (click)="logGetStartedProgram('UP');showConsiderTSAModal();" (keyup.enter)="logGetStartedProgram('UP');showConsiderTSAModal();" translate>HOME.GET_STARTED</a>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-2 ">
              <div class="program-cell">
                <div>
                  <div class="logo-container">
                    <img class="nexus" src="assets/images/logos/logo-nexus.png" alt="{{'IMAGE_ALT.NEXUS_LOGO' | translate}}">
                  </div>
                  <div><p class="text-top" translate>HOME.STREAMLINED_STATEMENT_NH</p></div>
                  <div class="card-best-for">
                    <p [innerHTML]="'HOME.NH_BEST_FOR' | translate"></p>
                  </div>
                  <div class="program-price-container">
                    <h2 class="program-price"><b>$120 <span class="usd-26">USD</span></b></h2>
                  </div>
                </div>
                <div>
                  <span class="tsa-pre-inclusion tsa-pre-include" translate>HOME.TSA_MAY_INCLUDE</span>
                  <div class="tsa-pre-include-text">TSA PreCheck<sup>&reg;</sup></div>
                </div>
                <div class="eligible-text">
                  <p  [innerHTML]="'HOME.ELIGIBILITY_FOREIGN_US_CN_MX_INFOTEXT' | translate"></p>
                </div>
                <div class="processing-time-container">
                  <p>
                    <span><i class="fa fa-calendar-check-o"></i></span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.LABEL</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.VARY</span>
                </div>
                <div class="start-button">
                  <a href="" class="learn-more" data-toggle="modal" data-target="#md3-learn-more" translate>HOME.ABOUT.LEARN_MORE</a>
                  <a class="btn btn-success get-started" tabindex="0" (click)="logGetStartedProgram('NH');logIn();" (keyup.enter)="logGetStartedProgram('NH');logIn();" translate>HOME.GET_STARTED</a>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-2 ">
              <div class="program-cell">
                <div>
                  <div class="logo-container">
                      <img class="sentri" src="assets/images/logos/logo-sentri.png" alt="{{'IMAGE_ALT.SENTRI_LOGO' | translate}}">
                  </div>
                  <div><p class="text-top" translate>HOME.STREAMLINED_STATEMENT_SH</p></div>
                  <div class="card-best-for">
                    <p [innerHTML]="'HOME.SH_BEST_FOR' | translate"></p>
                  </div>
                  <div class="program-price-container">
                    <h2 class="program-price"><b>$120 <span class="usd-26">USD</span></b></h2>
                  </div>
                </div>
                <div>
                  <span class="tsa-pre-inclusion tsa-pre-include" translate>HOME.TSA_MAY_INCLUDE</span>
                  <div class="tsa-pre-include-text">TSA PreCheck<sup>&reg;</sup></div>
                </div>
                <div class="eligible-text">
                  <p  [innerHTML]="'HOME.ELIGIBILITY_RESIDENTS_AVAIL_INFOTEXT' | translate"></p>
                </div>
                <div class="processing-time-container">
                  <p>
                    <span><i class="fa fa-calendar-check-o"></i></span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.LABEL</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.VARY</span>
                </div>
                <div class="start-button">
                  <a href="" class="learn-more" data-toggle="modal" data-target="#md4-learn-more" translate>HOME.ABOUT.LEARN_MORE</a>
                  <a class="btn btn-success get-started" tabindex="0" (click)="logGetStartedProgram('SH'); logIn();" (keyup.enter)="logGetStartedProgram('SH'); logIn();" translate>HOME.GET_STARTED</a>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-2">
              <div class="program-cell">
                <div>
                  <div class="logo-container">
                    <img class="fast" src="assets/images/logos/logo-us-mexico-fast.jpg" alt="{{'IMAGE_ALT.FAST_LOGO' | translate}}">
                  </div>
                  <div>
                    <p class="text-top" translate>HOME.STREAMLINED_STATEMENT_FAST</p>
                  </div>
                  <div class="card-best-for">
                    <p [innerHTML]="'HOME.FAST_BEST_FOR' | translate"></p>
                  </div>
                  <div class="program-price-container">
                    <h2 class="program-price"><b>$50 <span class="usd-26">USD</span></b></h2>
                  </div>
                </div>
                <div>
                  <span class="tsa-pre-inclusion tsa-pre-notinclude" translate>HOME.TSA_NOT_INCLUDE</span>
                  <div class="tsa-pre-include-text">TSA PreCheck<sup>&reg;</sup></div>
                </div>
                <div class="eligible-text">
                  <p [innerHTML]="'HOME.ELIGIBILITY_FOREIGN_US_CN_MX_INFOTEXT' | translate"></p>
                </div>
                <div class="processing-time-container">
                  <p>
                    <span><i class="fa fa-calendar-check-o"></i></span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.LABEL</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.FAST_MIN</span><br>
                    <span translate>HOME.APP_PROCESSING_TIME.FAST_MAX</span>
                </div>
                <div class="start-button">
                  <a href="" class="learn-more" data-toggle="modal" data-target="#md6-learn-more" translate>HOME.ABOUT.LEARN_MORE</a>
                  <a class="btn btn-success get-started" tabindex="0"  (click)="logGetStartedProgram('FN'); logIn();" (keyup.enter)="logGetStartedProgram('FN'); logIn();" translate>HOME.GET_STARTED</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-10">
              <p class="landing-bottom-text container text-center" [innerHTML]="'HOME.TTP_ENTRYFINEPRINT' | translate"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container compare-grid">
    <div class="row">
      <div class="col-xs-12">
        <div class="comparison-section-container">
          <ttp-comparison-tool (runComparison)="onRunComparison($event)" (resetComparison)="onResetComparison($event)"></ttp-comparison-tool>
          <comparison-results id="pp-widget" [class.hidden]="!displayWidgetResults"  (resetHomeState)="onResetHomeState($event)"></comparison-results>
        </div>
      </div>
    </div>
  </div>

  <!-- what is right for me -->
  <div class="container compare-grid">
    <!-- // HOW ENROLLMENT WORKS  -->
    <div style="background-color:rgba(242, 248, 255, 1)">
      <div class="container how-enrollment-works text-center">
        <h2 style="color:#336699" translate>HOME.ENROLLMENT.HOW_WORKS</h2>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <p class="enrollment-title" translate>
              <!-- <img class="enrollment-img" src="assets/images/logos/apply.png" alt="{{'IMAGE_ALT.APPLY' | translate}}">HOME.ENROLLMENT.APPLY</p> -->
              <i class="fa fa-desktop circle_for_fa" title="{{'IMAGE_ALT.APPLY' | translate}}"></i>HOME.ENROLLMENT.APPLY</p>
              <p class="text-enrolment" translate>HOME.ENROLLMENT.SUBMIT_APPLICATION</p>
            <p class="question-how">
              <a data-target="#mdl-what-i-need" data-toggle="modal" href="" class="enrollment-link" translate>HOME.ENROLLMENT.WHAT_INFO_NEEDED</a>
              <br>
              <a href="" class="enrollment-link" data-toggle="modal" data-target="#mdl-why-login" translate>HOME.ENROLLMENT.WHY_LOGIN_GOV</a>
            </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <p class="enrollment-title" translate>
              <!-- <img class="enrollment-img" src="assets/images/logos/interview.png" alt="{{'IMAGE_ALT.INTERVIEW' | translate}}">HOME.ENROLLMENT.INTERVIEW</p> -->
              <i class="fa fa-user circle_for_fa" title="{{'IMAGE_ALT.INTERVIEW' | translate}}"></i>HOME.ENROLLMENT.INTERVIEW</p>
            <p class="text-enrolment">
                <span translate>HOME.ENROLLMENT.INTERVIEW_INPERSON</span>
                <br>
                <!--/* //NOSONAR */--><a target="_blank" class="enrollment-link" href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-arrival" rel="noopener" translate>MODAL.FIND_UP_EOA</a>
                <br>
                <a href="" class="enrollment-link" data-toggle="modal" data-target="#mdl-check-int-avail" translate>HOME.ABOUT.CHECK_INTERVIEW_AVAILABILITY</a>
              </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <p class="enrollment-title" translate>
              <!-- <img class="enrollment-img" src="assets/images/logos/membership.png" alt="{{'IMAGE_ALT.MEMBERSHIP' | translate}}">HOME.ENROLLMENT.MEMBERSHIP</p> -->
              <i class="fa fa-id-card-o circle_for_fa" title="{{'IMAGE_ALT.MEMBERSHIP' | translate}}"></i>HOME.ENROLLMENT.MEMBERSHIP</p>
              <p class="text-enrolment" translate>HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP</p>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- modals -->


    <!-- <info-modal style="width:initial;" [id]="'md5-learn-more'" [title]="'DASHBOARD.TSA_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'MODAL.LEARN_MORE_TSA'"></info-modal> -->
    <program-info-modal style="width:initial;" [id]="'md5-learn-more'" [title]="'DASHBOARD.TSA_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [program]="'TSA'"
      [membershipLink]="'MODAL.LEARN_MORE_TSA_INFO.MEMBERSHIP_LINK'"
      [benefits]="'MODAL.LEARN_MORE_TSA_INFO.BENEFITS'"></program-info-modal>

    <!-- <info-modal [id]="'md2-learn-more'" [title]="'DASHBOARD.GLOBAL_ENTRY_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'MODAL.LEARN_MORE_GLOBAL_ENTRY'"></info-modal> -->
      <program-info-modal [id]="'md2-learn-more'" [title]="'DASHBOARD.GLOBAL_ENTRY_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
        [program]="'UP'"
        [membershipLink]="'MODAL.LEARN_MORE_GLOBAL_ENTRY.MEMBERSHIP_LINK'"
        [benefits]="'MODAL.LEARN_MORE_GLOBAL_ENTRY.BENEFITS'"></program-info-modal>


    <!-- <info-modal [id]="'md3-learn-more'" [title]="'DASHBOARD.NEXUS_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'MODAL.LEARN_MORE_NEXUS_MODAL'"></info-modal> -->
      <program-info-modal [id]="'md3-learn-more'" [title]="'DASHBOARD.NEXUS_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
        [program]="'NH'"
        [benefits]="'MODAL.LEARN_MORE_NEXUS.BENEFITS'"></program-info-modal>

    <!-- <info-modal [id]="'md4-learn-more'" [title]="'DASHBOARD.SENTRI_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'MODAL.LEARN_MORE_SENTRI_MODAL'"></info-modal> -->
      <program-info-modal [id]="'md4-learn-more'" [title]="'DASHBOARD.SENTRI_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
        [program]="'SH'"
        [benefits]="'MODAL.LEARN_MORE_SENTRI.BENEFITS'"></program-info-modal>

    <!-- <info-modal [id]="'md6-learn-more'" [title]="'DASHBOARD.FAST_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'MODAL.LEARN_MORE_FAST_MODAL'"></info-modal> -->
      <program-info-modal [id]="'md6-learn-more'" [title]="'DASHBOARD.FAST_LEARN_MORE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
        [program]="'FN'"
        [benefits]="'MODAL.LEARN_MORE_FAST.BENEFITS'"></program-info-modal>

    <info-modal [id]="'mdl-why-login'" [title]="'FAQ.WHY_LOGIN_GOV_TITLE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'FAQ.WHY_LOGIN_GOV_TEXT'"></info-modal>
    <info-modal [id]="'mdl-what-i-need'" [title]="'FAQ.WHAT_NEED_TITLE'" [buttonText]="'MODAL.BUTTON_CLOSE'"
      [translate-src]="'FAQ.WHAT_NEED_TEXT'"></info-modal>
    <info-modal [id]="'mdl-app-fee'" [title]="'FAQ.APP_FEE_TITLE'" [buttonText]="'MODAL.BUTTON_OK'" [translate-src]="'FAQ.APP_FEE_TEXT'"></info-modal>
    <info-modal [id]="'mdl-where-interviews'" [title]="'FAQ.WHERE_INTERVIEWS_TITLE'" [buttonText]="'MODAL.BUTTON_OK'"
      [translate-src]="'FAQ.WHERE_INTERVIEWS_TEXT'"></info-modal>
    <security-modal #securityModal [id]="'security-warn'"></security-modal>
    <security-modal [id]="'security-warn-gs'" [route]="'/getstarted'"></security-modal>
    <check-int-avail-modal [id]="'mdl-check-int-avail'"></check-int-avail-modal>
    <!-- // NEWS SCROLLER -->
    <!-- <go-news-scroller></go-news-scroller> -->
    <consider-tsa-modal [id]="'modal-consider-tsa'" (continue)="execContinueWithUP($event)"></consider-tsa-modal>
    <tsa-site-leaving [id]="'mdl-tsa-site-leaving'"></tsa-site-leaving>
