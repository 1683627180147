import {Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[match]',
    providers: [{provide: NG_VALIDATORS, useExisting: MatchValidatorDirective, multi : true }]
})

export class MatchValidatorDirective implements Validator{

    @Input() match : string;
    @Input() reverse : string;


    isReverse() : boolean {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true: false;
    }

    validate(control : AbstractControl) : {[key : string] : any} {

        let value = control.value;
        let compare = control.root.get(this.match);
        let matches : boolean;

//        if(isRegex){
//            //this.matches = this.match.test(value);
//        }
//        else{
        //}

        if(compare && compare.value && value){
            matches = compare.value.toLowerCase() === value.toLowerCase();
            if(matches && this.isReverse()){
                if(compare.errors && compare.errors['match']){
                    delete compare.errors['match'];
                     if (!Object.keys(compare.errors).length) compare.setErrors(null);
                }
            }
            else if(!matches && this.isReverse()){
                compare.setErrors({match : true});
            }
            else if(!matches && !this.isReverse()){
                return {match : true};
            }
        }
        return null;
    }

}
