import { environment } from '../../../environments/environment';
import { AnalyticsService } from './../../common/services/analytics.service';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { SETTINGS } from '../../app.constants';
import { TranslateService } from '@ngx-translate/core';

declare var $ : any;

/**
 *
 */
 @Component({
   moduleId: module.id,
   selector: 'comparison-results',
   templateUrl: 'comparison-results.component.html'
 })

 export class ComparisonResultsComponent implements OnInit {

  schedulerViewLocationsUrl: string = ''; // Base scheduler URL

  public bestMatchTxt: string;
  public bestMatchCard: any;
  public cards: any[] = new Array();

  public otherProgramsClass = ' other-programs';
  public excludePrecheckSentri: boolean = false;
  public isPromoCountry: boolean = false;
  public promoCountryClass: string;
  public recommendationTxt: string = 'HOME.YOUR_RECOMMANDATION_TEXT';

  private DEFAULT_GLOBAL_ENTRY_ELIG_TXT = 'HOME.BEST_MATCH_RESULTS.DEFAULT_GLOBAL_ENTRY_ELIG_TXT';
  public globalTravelEligibilityTxt = this.DEFAULT_GLOBAL_ENTRY_ELIG_TXT;

  private userChoices: any = null;

  @Output() resetHomeState = new EventEmitter < any > ();

  public PROGRAM_NAMES = {
    GLOBAL_ENTRY: 'global-entry',
    TSA_PRECHECK: 'tsa-precheck',
    SENTRI: 'sentri',
    NEXUS: 'nexus',
    FAST: 'fast'
  };


  private globalEntryCard = {
    id: 'card_GlobalEntry',
    programName: 'Global Entry',
    programAbbr: 'global-entry',
    price: 120,
    cardSections: 2,
    priceDescription: '5-year Membership Fee',
    section1: {
      programHighlight: 'HOME.BEST_MATCH_RESULTS.GE_PROGRAM_HIGHLIGHT',
      programHighlightDesc: 'HOME.BEST_MATCH_RESULTS.GE_PROGRAM_HIGHLIGHT_DETAILS',
    },
    section2: {
      documentClass: 'documentation-permres',
      imgAlt: 'HOME.BEST_MATCH_RESULTS.PASSPORT_PERM_RES_ALT',
      imgSrc: 'assets/images/logos/documents_pass_permRes@2x.png',
      imgDescription: 'HOME.BEST_MATCH_RESULTS.PASSPORT_PERM_RES_DESC'
    },
    cardExpandableId: 'ge-card-expandable',
    ttpLogin: true,
    actionHref: '#',
    actionClick: 'logIn()',
    appProcessClick: 'logIn()',
    findEnrollmentCenterLink: this.schedulerViewLocationsUrl + 'up',
    membershipNote: 'HOME.BEST_MATCH_RESULTS.GE_VIEWMORE_CARD_NOTE',
    eligibility: this.globalTravelEligibilityTxt,
    learnMoreLink: 'https://www.cbp.gov/travel/trusted-traveler-programs/global-entry',
    featuredBenefits: [
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.GE_BENEFITS_ONE',
      },
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.GE_BENEFITS_TWO'
      }
    ],
    additionalBenefits: [
      {
        text: 'HOME.BEST_MATCH_RESULTS.GE_ADDITIONAL_BENEFIT_ONE',
        tooltip: 'HOME.BEST_MATCH_RESULTS.GE_ADDITIONAL_BENEFIT_ONE_TT'
      }
    ]
  };

  private tsaPreCheckCard = {
    id: 'card_TSAPreCheck',
    programName: 'TSA PreCheck<sup>&reg;</sup>',
    programAbbr: 'tsaprecheck',
    price: 77.95,
    priceDescription: '5-year Membership Fee',
    cardSections: 3,
    section1: {
      programHighlight: 'HOME.BEST_MATCH_RESULTS.TSA_PROGRAM_HIGHLIGHT',
      programHighlightDesc: 'HOME.BEST_MATCH_RESULTS.TSA_PROGRAM_HIGHLIGHT_DETAILS',
    },
    middleSection: {
      header: 'HOME.BEST_MATCH_RESULTS.TSA_MIDDLE_HEADER',
      text: 'HOME.BEST_MATCH_RESULTS.TSA_MIDDLE_DESC'
    },
    section2: {
      documentClass: 'documentation-dl',
      imgAlt: 'HOME.BEST_MATCH_RESULTS.DRIVERS_LICENSE_ALT',
      imgSrc: 'assets/images/logos/documents_drivers_license@2x.png',
      imgDescription: 'HOME.BEST_MATCH_RESULTS.DRIVERS_LICENSE_DESC'
    },
    cardExpandableId: 'tsa_pre-card-expandable',
    ttpLogin: false,
    actionHref: 'https://universalenroll.dhs.gov/workflows?servicecode=11115V&service=pre-enroll',
    appProcessHref: environment.tsaLoginURL,
    appProcessClick: 'logIn()',
    findEnrollmentCenterLink: 'https://www.tsa.gov/precheck/enrollment-centers',
    membershipNote: 'HOME.BEST_MATCH_RESULTS.TSA_VIEWMORE_CARD_NOTE',
    eligibility: 'HOME.BEST_MATCH_RESULTS.TSA_ELIGIBILITY',
    learnMoreLink: environment.tsaLoginURL,
    featuredBenefits: [
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.TSA_BENEFITS_ONE'
      }
    ]
  };

  private sentriCard = {
    id: 'card_SENTRI',
    programName: 'SENTRI',
    programAbbr: 'sentri',
    price: 120,
    cardSections: 2,
    priceDescription: '5-year Membership',
    section1: {
      programHighlight: 'HOME.BEST_MATCH_RESULTS.SE_PROGRAM_HIGHLIGHT',
      programHighlightDesc: 'HOME.BEST_MATCH_RESULTS.SE_PROGRAM_HIGHLIGHT_DETAILS',
    },
    section2: {
      documentClass: 'documentation-all',
      imgAlt: 'HOME.BEST_MATCH_RESULTS.ALL_DOCUMENTS_ALT',
      imgSrc: 'assets/images/logos/documents_dl_and_pr@2x.png',
      imgDescription: 'HOME.BEST_MATCH_RESULTS.ALL_DOCUMENTS_DESC'
    },
    cardExpandableId: 'sentri-card-expandable',
    ttpLogin: true,
    actionHref: '#',
    actionClick: 'logIn()',
    appProcessHref: '#',
    appProcessClick: 'logIn()',
    findEnrollmentCenterLink: this.schedulerViewLocationsUrl + 'sh',
    membershipNote: 'HOME.BEST_MATCH_RESULTS.SE_VIEWMORE_CARD_NOTE',
    eligibility: 'HOME.BEST_MATCH_RESULTS.SE_ELIGIBILITY',
    learnMoreLink: 'https://www.cbp.gov/travel/trusted-traveler-programs/sentri',
    featuredBenefits: [
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.SE_BENEFITS_ONE',
        tooltip: 'HOME.BEST_MATCH_RESULTS.SE_BENEFITS_ONE_TT'
      }
    ],
    additionalBenefits: [
      {
        text: 'HOME.BEST_MATCH_RESULTS.SE_ADDITIONAL_BENEFIT_ONE',
        precheck: true,
        tooltip: 'HOME.BEST_MATCH_RESULTS.SE_ADDITIONAL_BENEFIT_ONE_TT'
      }, {

        text: 'HOME.BEST_MATCH_RESULTS.SE_ADDITIONAL_BENEFIT_TWO',
        precheck: true
      }
    ]
  };

  private nexusCard = {
    id: 'card_NEXUS',
    programName: 'NEXUS',
    programAbbr: 'nexus',
    price: 120,
    cardSections: 2,
    priceDescription: '5-year Membership',
    section1: {
      programHighlight: 'HOME.BEST_MATCH_RESULTS.NX_PROGRAM_HIGHLIGHT',
      programHighlightDesc: 'HOME.BEST_MATCH_RESULTS.NX_PROGRAM_HIGHLIGHT_DETAILS',
    },
    section2: {
      documentClass: 'documentation-all',
      imgAlt: 'HOME.BEST_MATCH_RESULTS.PASSPORT_PERM_RES_ALT',
      imgSrc: 'assets/images/logos/documents_dl_and_pr@2x.png',
      imgDescription: 'HOME.BEST_MATCH_RESULTS.PASSPORT_PERM_RES_DESC'
    },
    cardExpandableId: 'nexus-card-expandable',
    ttpLogin: true,
    actionHref: '#',
    actionClick: 'logIn()',
    appProcessHref: '#',
    appProcessClick: 'logIn()',
    findEnrollmentCenterLink: this.schedulerViewLocationsUrl + 'nh',
    membershipNote: 'HOME.BEST_MATCH_RESULTS.NX_VIEWMORE_CARD_NOTE',
    eligibility: 'HOME.BEST_MATCH_RESULTS.NX_ELIGIBILITY',
    learnMoreLink: 'https://www.cbp.gov/travel/trusted-traveler-programs/nexus',
    featuredBenefits: [
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.NX_BENEFITS_ONE',
        tooltip: 'HOME.BEST_MATCH_RESULTS.NX_BENEFITS_ONE_TT'
      },
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.NX_BENEFITS_TWO'
      },
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.NX_BENEFITS_THREE'
      }
    ],
    additionalBenefits: [
      {
        text: 'HOME.BEST_MATCH_RESULTS.NX_ADDITIONAL_BENEFIT_ONE'
      }
    ]
  };

  private fastCard = {
    id: 'card_FAST',
    programName: 'FAST (North or South)',
    programAbbr: 'fast',
    price: 50,
    cardSections: 2,
    priceDescription: '5-year Membership',
    section1: {
      programHighlight: 'HOME.BEST_MATCH_RESULTS.FAST_PROGRAM_HIGHLIGHT',
      programHighlightDesc: 'HOME.BEST_MATCH_RESULTS.FAST_PROGRAM_HIGHLIGHT_DETAILS',
    },
    section2: {
      documentClass: 'documentation-all',
      imgAlt: 'HOME.BEST_MATCH_RESULTS.ALL_DOCUMENTS_ALT',
      imgSrc: 'assets/images/logos/documents_dl_and_pr@2x.png',
      imgDescription: 'HOME.BEST_MATCH_RESULTS.ALL_DOCUMENTS_DESC'
    },
    cardExpandableId: 'fast-card-expandable',
    ttpLogin: true,
    actionHref: '#',
    actionClick: 'logIn()',
    appProcessHref: '#',
    appProcessClick: 'logIn()',
    findEnrollmentCenterLink: this.schedulerViewLocationsUrl + 'fn',
    membershipNote: '',
    eligibility: 'HOME.BEST_MATCH_RESULTS.FAST_ELIGIBILITY',
    learnMoreLink: 'https://www.cbp.gov/travel/trusted-traveler-programs/fast',
    featuredBenefits: [
      {
        benefit: 'HOME.BEST_MATCH_RESULTS.FAST_BENEFITS_ONE'
      }
    ]
  };

    constructor(
      private analyticsService: AnalyticsService,
      private translate: TranslateService
    ) {
      // set the scheduler view location url and also update it when language changes
      this.schedulerViewLocationsUrl = environment.uriScheduler + "schedule-interview/location?lang=" + this.translate.currentLang + "&vo=true&returnUrl=" + SETTINGS.baseReturnService + "&service=";
      this.updateCardEnrollmentCenterUrl();

      this.translate.onLangChange.subscribe((event: any) => {
        this.schedulerViewLocationsUrl = environment.uriScheduler + "schedule-interview/location?lang=" + this.translate.currentLang + "&vo=true&returnUrl=" + SETTINGS.baseReturnService + "&service=";
        this.updateCardEnrollmentCenterUrl();
      });
    }

    public logIn(): void {
      $('#security-warn').modal('show');
    }

    ngOnInit() {
    }

    /**
     * Logs that what program the user selected. If their is a best match card, checks to
     * see if the selected is equal to the best match.
     *
     * @param selectedProgram The programAbbr from the card
     */
    public logSelected(selectedProgram: string) {
      let bestMatchTxt = this.bestMatchCard && this.bestMatchCard.programAbbr ? this.getProgramCodeFromAbbr(this.bestMatchCard.programAbbr) : 'none';

      let citizenship = this.userChoices ? this.userChoices.citizenship : 'none';

      // TODO the selected program that is passed is used in the formatting, could add the code
      // to the card or change the programAbbr to be the code in the css

      this.analyticsService.logBestMatch(bestMatchTxt, this.getProgramCodeFromAbbr(selectedProgram), citizenship).subscribe();
    }

    /**
     * Show/Hides a sections when a link is clicked.
     * NOTE: the id of the link is the section id with '-link' appended
     * @param sectionId - id of the section to show hide when link clicked
     */
    public toggleDetails(sectionId: string): void {
      const linkId = sectionId + '-link';
      if ($('#' + sectionId).hasClass('collapsed')) {
        $('#' + sectionId).removeClass('collapsed');

        $('#' + linkId).attr('aria-expanded', 'true');
        $('#' + linkId + ' span:nth-child(1)').text('-');
        $('#' + linkId + ' span:nth-child(2)').text('HOME.BEST_MATCH_RESULTS.VIEW_LESS');
      } else {
        $('#' + sectionId).addClass('collapsed');
        $('#' + linkId).attr('aria-expanded', 'false');
        $('#' + linkId + ' span:nth-child(1)').text('+');
        $('#' + linkId + ' span:nth-child(2)').text('HOME.BEST_MATCH_RESULTS.VIEW_MORE_DETAILS');
      }
    }

    private resetResults() {
      this.cards = new Array();
      this.bestMatchCard = null;
      this.recommendationTxt = 'HOME.YOUR_RECOMMANDATION_TEXT';
      this.isPromoCountry = false;
      this.excludePrecheckSentri = false;
      this.globalTravelEligibilityTxt = this.DEFAULT_GLOBAL_ENTRY_ELIG_TXT;
      this.userChoices = null;
    }

    public runComparisons(choices: any) {
      this.resetResults();

      this.userChoices  = choices; // TODO create model for choices

      let citizenship = choices.citizenship ? choices.citizenship : 'US Citizen';
      let showFast = false;

      switch (citizenship) {
        // For US Citizens and Permanent Residents
        case "US":
        case "USLPR":
          if (choices.isCommercialTrucker === 'yes') {
            this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.FAST_BESTMATCH_TEXT';
            this.bestMatchCard = this.fastCard;
            showFast = true;
          }

          if (choices.internationalTravelCount >= 2) {
            this.determineCardsForFrequentIntlTravel(choices, showFast);
          } else if (choices.canadaTravelCount >= 2 && choices.internationalTravelCount < 2) {
            this.determineCardsForFrequentCanadaTravel(choices, showFast);
          } else if (choices.mexicoTravelCount >= 2 && choices.canadaTravelCount < 2 && choices.internationalTravelCount < 2) {
            this.determineCardsForFrequentMexicoTravel(choices, showFast);
          } else if (choices.domesticTravelCount >= 1) {
            this.determineCardsForFrequentDomesticTravel(choices, showFast);
          } else {
            this.cannotDetermineBestMatch(choices);
          }
          break;

        // For Canadian Citizens and Canadian Permanent Residents
        case "CA":
        case "CALPR":
          this.excludePrecheckSentri = true;

          if (choices.isCommercialTrucker == 'yes') {
            this.bestMatchCard = this.fastCard;
            this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.FAST_BESTMATCH_TEXT';

            showFast = true;
          } else {
            showFast = false;
          }
          if ((choices.internationalTravelCount == 0) && (choices.domesticTravelCount == 0) && (choices.canadaTravelCount == 0) && (choices.mexicoTravelCount == 0)) {
            if (!showFast) {
              this.bestMatchCard = null;
            }
            this.cards.push(this.nexusCard);
            this.cards.push(this.sentriCard);
          } else {
            if (!showFast) {
              this.bestMatchCard = this.nexusCard;
              this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.NX_BESTMATCH_TEXT';
            } else {
              this.cards.push(this.nexusCard);
            }

            this.cards.push(this.sentriCard);
          }
          break;
                  
        // For Argentina Citizens
        case "AR":
          this.runDefaultComparisons(choices);
          break;
        
        // For Australia Citizens
        case "AU":
          this.runDefaultComparisons(choices);
          break;  
        
        // For Bahrain Citizens
        case "BH":
          this.runDefaultComparisons(choices);
          break;        

        // For Colombian Citizens
        case "CO":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.CO_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // For German Citizens
        case "DE":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.DE_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // For Dominican Republic Citizens
        case "DO":
          this.runDefaultComparisons(choices);
          break;  

        // For Croatia Citizens
        case "HR":
          this.runDefaultComparisons(choices);
          break;

        // For India Citizens
        case "IN":
          this.runDefaultComparisons(choices);
          break;

        // For Israel Citizens
        case "IS":
          this.runDefaultComparisons(choices);
          this.promoCountryTreatment();
          break;

        // For Japan Citizens
        case "JA":
          this.runDefaultComparisons(choices);         
          break;

        // Korean Citizen
        case "KR":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.KR_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // Mexican National
        case "MX":
          this.excludePrecheckSentri = true;

          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.MX_GLOBAL_TRAVEL_ELIG_TEXT';
          if (choices.isCommercialTrucker == 'yes') {
            showFast = true;
            this.bestMatchCard = this.fastCard;
            this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.FAST_BESTMATCH_TEXT';
          } else {
            showFast = false;
          }

          if ((choices.internationalTravelCount == 0) && (choices.domesticTravelCount == 0) && (choices.canadaTravelCount == 0) && (choices.mexicoTravelCount == 0)) {
            if (!showFast) {
              this.bestMatchCard = null;
            }

            if (choices.hasPassport != 'no') {
              this.cards.push(this.globalEntryCard);
            }

            this.cards.push(this.sentriCard);
            this.cards.push(this.nexusCard);
          } else if ((choices.internationalTravelCount >=2) && (choices.canadaTravelCount < 2)) {
            if (!showFast) {
              if (choices.hasPassport !== 'no') {
                this.bestMatchCard = this.globalEntryCard;
                this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.SE_BESTMATCH_TEXT';
              } else {
                this.bestMatchCard= null;
              }
            } else {
              if (choices.hasPassport !== 'no') {
                this.cards.push(this.globalEntryCard);
              }
            }

            this.cards.push(this.nexusCard);
            this.cards.push(this.sentriCard);
          } else if ((choices.internationalTravelCount < 2) && (choices.canadaTravelCount >= 2)) {
            if (!showFast) {
              this.bestMatchCard = this.nexusCard;
              this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.SE_BESTMATCH_TEXT';
            } else {
              this.cards.push(this.nexusCard);
            }

            if (choices.hasPassport !== 'no') {
              this.cards.push(this.globalEntryCard);
            }
            this.cards.push(this.sentriCard);
          } else {
            if (choices.hasPassport !== 'no') {
              if (!showFast) {
                this.bestMatchCard = this.globalEntryCard;
              } else {
                this.cards.push(this.globalEntryCard);
              }
              this.cards.push(this.sentriCard);
              this.cards.push(this.nexusCard);
            } else {
              if (!showFast) {
                this.bestMatchCard = this.sentriCard;
              } else {
                this.cards.push(this.sentriCard);
              }
              this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.SE_BESTMATCH_TEXT';
              this.cards.push(this.nexusCard);
            }
          }
          break;

        // Netherlands
        case "NL":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.NL_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // Panama
        case "PA":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.PA_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // Qatar
        case "QA":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.QA_GLOBAL_TRAVEL_ELIG_TEXT';
          // this.promoCountryTreatment();
          break;

        // Saudi Arabia
        case "SA":
          this.runDefaultComparisons(choices);
          this.promoCountryTreatment();
          break;

        // Singapore
        case "SG":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.SG_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // Switzerland
        case "CH":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.CH_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // Taiwan
        case "TW":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.TW_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // United Kingdom
        case "UK":
          this.runDefaultComparisons(choices);
          this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.UK_GLOBAL_TRAVEL_ELIG_TEXT';
          break;

        // United Arab Emeriates
        case "AE":
          this.runDefaultComparisons(choices);        
          break;

        // For Brazilian Citizens
        case "BR":
          this.runDefaultComparisons(choices);          
          break;

        case "OTHER":
          this.excludePrecheckSentri = true;

          if ((choices.canadaTravelCount > 0) || (choices.mexicoTravelCount > 0)) {
            this.bestMatchCard = this.sentriCard;
            this.bestMatchTxt = 'HOME.BEST_MATCH_RESULTS.SE_NON_MEXICO_BESTMATCH_TEXT';
          } else {
            this.bestMatchCard = null;
            this.cards.push(this.sentriCard);
          }
          break;

      default:
        this.runDefaultComparisons(choices);
        break;
    }

    this.setBestMatchHeader(choices);
  }

  private runDefaultComparisons(choices: any) {
    this.excludePrecheckSentri = true;

    this.globalTravelEligibilityTxt = 'HOME.BEST_MATCH_RESULTS.CONTACT_HOME_GOVERNMENT_INS';

    if ((choices.internationalTravelCount == 0) && (choices.domesticTravelCount == 0) && (choices.canadaTravelCount == 0) && (choices.mexicoTravelCount == 0)) {
      this.cards.push(this.globalEntryCard);
      this.cards.push(this.sentriCard);
    } else {
      if (choices.hasPassport != 'no') {
        this.bestMatchCard = this.globalEntryCard;
      }
      this.cards.push(this.sentriCard);
    }
  }

  private setBestMatchHeader(choices: any): void {
    if (this.bestMatchCard) {
      this.otherProgramsClass = 'other-programs';

      this.recommendationTxt = 'HOME.YOUR_RECOMMANDATION_TEXT';
      switch (this.bestMatchCard.programAbbr) {
        case "global-entry":
          if (choices.citizenship == "US Citizen" || choices.citizenship == "US Resident" || choices.citizenship == "") {
            this.bestMatchTxt = 'HOME.GLOBAL_ENTRY_REC_TSA_BESTMATCH_TEXT';
          } else {
            this.bestMatchTxt = 'HOME.GLOBAL_ENTRY_BESTMATCH_TEXT';
          }
          break;
        case "tsaprecheck":
          this.bestMatchTxt = 'HOME.TSA_PRECHECK_BESTMATCH_TEXT';
          break;
        case "nexus":
          this.bestMatchTxt = 'HOME.NEXUS_BESTMATCH_TEXT';
          break;
        case "sentri":
          if (choices.citizenship == "Mexican") {
            this.bestMatchTxt = 'HOME.SENTRI_MEXICO_BESTMATCH_TEXT';
          } else {
            this.bestMatchTxt = 'HOME.SENTRI_BESTMATCH_TEXT';
          }
          break;
        case "fast":
          this.bestMatchTxt = 'HOME.FAST_BEST_MATCH_TEXT';
          break;
      }
    } else {
      this.otherProgramsClass = '';
      this.recommendationTxt = 'HOME.BEST_MATCH_NOT_TEXT';
      this.bestMatchTxt = 'HOME.BEST_TTP_AVALABLE_TEXT';
    }
  }

  /**
   * Returns the additional benefits for a program/card minus the exclusions if their are
   * any.
   *
   * Currently only SENTRI has exclusions. The excludePrecheckSentri flag is set during the
   * comparisons.
   *
   * @param program
   */
  public filterExclusionsFromBenefits(program: string) {

    if (program === 'sentri') {
      if (this.excludePrecheckSentri) {
        return this.sentriCard.additionalBenefits.filter(b => !b.precheck);
      } else {
        return this.sentriCard.additionalBenefits;
      }
    } else if (program === 'global-entry') {
      return this.globalEntryCard.additionalBenefits;
    } else if (program === 'nexus') {
      return this.nexusCard.additionalBenefits;
    } else if (program === 'tsa-precheck') {
      return null;
    } else {
      return null;
    }
  }

  public returnHome() {
    // TODO verifiy with Theresa how to handle for now just scroll up to comparison tool
    this.resetHomeState.emit(true);
    // Utils.scrollUp('select-citizenship');
  }

  private promoCountryTreatment() {
    this.isPromoCountry = true;
  }

  /**
   * Converts the programAbbr from the card to the ttp accepted code.
   * @param programAbbr
   */
  private getProgramCodeFromAbbr(programAbbr: string) {
    let programCode;
    switch (programAbbr) {
      case 'global-entry':
        programCode = 'UP';
        break;
      case 'tsaprecheck':
        programCode = 'TSA';
        break;
      case 'sentri':
        programCode = 'SH';
        break;
      case 'nexus':
        programCode = 'NH';
        break;
      case 'fast':
        programCode = 'FN';
        break;
      default:
        programCode = '';
    }

    return programCode;
  }

  private updateCardEnrollmentCenterUrl() {
			this.globalEntryCard.findEnrollmentCenterLink = this.schedulerViewLocationsUrl + 'up';
			this.nexusCard.findEnrollmentCenterLink = this.schedulerViewLocationsUrl + 'nh';
			this.sentriCard.findEnrollmentCenterLink = this.schedulerViewLocationsUrl + 'sh';
			this.fastCard.findEnrollmentCenterLink =this.schedulerViewLocationsUrl + 'fn';
  }

  /**
   * For US/ US LPR Citizens
   * @param choices
   * @param showFast
   */
  private determineCardsForFrequentCanadaTravel(choices: any, showFast: boolean) {
    if (choices.canadaTravelCount >= 2) {
      if (!showFast) {
        this.bestMatchCard = this.nexusCard;
      } else {
        this.cards.push(this.nexusCard);
      }

      this.cards.push(this.tsaPreCheckCard);

      if (choices.hasPassport != 'no') {
        this.cards.push(this.globalEntryCard);
      }

      this.cards.push(this.sentriCard);
    }
  }

  private determineCardsForFrequentMexicoTravel(choices: any, showFast: boolean) {
    if (choices.mexicoTravelCount >= 2) {
      if (!showFast) {
        this.bestMatchCard = this.sentriCard;
      } else {
        this.cards.push(this.sentriCard);
      }

      this.cards.push(this.tsaPreCheckCard);

      if (choices.hasPassport != 'no') {
        this.cards.push(this.globalEntryCard);
      }
      this.cards.push(this.nexusCard);
    }
  }

  private determineCardsForFrequentDomesticTravel(choices: any, showFast: boolean) {
    if (choices.domesticTravelCount >= 1) {
      if (!showFast) {
        this.bestMatchCard = this.tsaPreCheckCard;
      } else {
        this.cards.push(this.tsaPreCheckCard);
      }
      if (choices.hasPassport != 'no') {
        this.cards.push(this.globalEntryCard);
      }
      this.cards.push(this.sentriCard);
      this.cards.push(this.nexusCard);
    }
  }

  private determineCardsForFrequentIntlTravel(choices: any, showFast:boolean) {

    if (choices.intTravelInSixMonths != 'no') {
      if (!showFast) {
        if (choices.hasPassport != 'no') {
            this.bestMatchCard = this.globalEntryCard;
            this.bestMatchTxt = 'HOME.GLOBALENTRY_BESTMATCH_TEXT';
            this.cards.push(this.tsaPreCheckCard);
        } else {
          if (choices.domesticTravelCount >= 1) {
            this.bestMatchCard = this.tsaPreCheckCard;
          } else {
            this.cards.push(this.tsaPreCheckCard);
          }
        }
      } else {
        if (choices.hasPassport != 'no') {
          this.cards.push(this.globalEntryCard);
        }
      }

      this.cards.push(this.nexusCard);
      this.cards.push(this.sentriCard);
    } else if (choices.canadaTravelCount >= 2)  {
      // Check Canada
      this.determineCardsForFrequentCanadaTravel(choices, showFast);
    } else if (choices.mexicoTravelCount >= 2) {
      // Check Mexico
      this.determineCardsForFrequentMexicoTravel(choices, showFast);
    } else if (choices.domesticTravelCount >=1) {
      // Check Sentri
      this.determineCardsForFrequentDomesticTravel(choices, showFast);
    } else {
      // Cannot determine
      // if (choices.domesticTravelCount > 0) {
        this.cards.push(this.tsaPreCheckCard);
      // }
      if (choices.hasPassport != 'no') {
        this.cards.push(this.globalEntryCard);
      }
      this.cards.push(this.nexusCard);
      this.cards.push(this.sentriCard);
    }
  }

  /**
   * No idea why this was made so complicated.
   *
   * @param choices
   */
  private cannotDetermineBestMatch(choices:any) : void {

    // if (choices.internationalTravelCount >= 2) {
    //   if (choices.domesticTravelCount > 0) {
    //     this.cards.push(this.tsaPreCheckCard);
    //   }
    //   if (choices.hasPassport != 'no') {
    //     this.cards.push(this.globalEntryCard);
    //   }
    //   this.cards.push(this.nexusCard);
    //   this.cards.push(this.sentriCard);
    // } else if (choices.internationalTravelCount == 1 && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 0) {
    //   if (choices.domesticTravelCount > 0) {
    //     this.cards.push(this.tsaPreCheckCard);
    //   }
    //   this.cards.push(this.nexusCard);

    //   if (choices.hasPassport != 'no') {
    //     this.cards.push(this.globalEntryCard);
    //   }

    //   this.cards.push(this.sentriCard);
    // } else if (choices.internationalTravelCount == 1 && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 1) {
    //   if (choices.domesticTravelCount > 0) {
    //     this.cards.push(this.tsaPreCheckCard);
    //   }

    //   this.cards.push(this.nexusCard);

    //   this.cards.push(this.sentriCard);

    //   if (choices.hasPassport != 'no') {
    //     this.cards.push(this.globalEntryCard);
    //   }
    // } else if (choices.internationalTravelCount == 1 && choices.canadaTravelCount == 0 && choices.mexicoTravelCount == 1) {
    //   if (choices.domesticTravelCount > 0) {
    //     this.cards.push(this.tsaPreCheckCard);
    //   }

    //   this.cards.push(this.sentriCard);

    //   if (choices.hasPassport != 'no') {
    //     this.cards.push(this.globalEntryCard);
    //   }
    //   this.cards.push(this.nexusCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 1
    //           && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 1) {

    //   this.cards.push(this.tsaPreCheckCard);
    //   this.cards.push(this.nexusCard);
    //   this.cards.push(this.sentriCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 0
    //   && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 1) {

    //   this.cards.push(this.nexusCard);
    //   this.cards.push(this.sentriCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 0
    //   && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 0) {

    //   this.cards.push(this.nexusCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 0
    //   && choices.canadaTravelCount == 0 && choices.mexicoTravelCount == 1) {

    //   this.cards.push(this.sentriCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 1
    //   && choices.canadaTravelCount == 0 && choices.mexicoTravelCount == 0) {

    //   this.cards.push(this.tsaPreCheckCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 1
    //   && choices.canadaTravelCount == 0 && choices.mexicoTravelCount == 1) {

    //   this.cards.push(this.tsaPreCheckCard);
    // } else if (choices.internationalTravelCount == 0 && choices.domesticTravelCount == 1
    //   && choices.canadaTravelCount == 1 && choices.mexicoTravelCount == 0) {

    //     this.cards.push(this.tsaPreCheckCard);
    // } else {
      // if (choices.domesticTravelCount > 0) {
        this.cards.push(this.tsaPreCheckCard);
      // }
      if (choices.hasPassport != 'no') {
        this.cards.push(this.globalEntryCard);
      }
      this.cards.push(this.nexusCard);
      this.cards.push(this.sentriCard);
    // }
  }
}
