<div id="printInfo" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearValues()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" [innerHTML]="title | translate"></h4>
        </div>
        <div id="printModalBody" class="modal-body">
            <div class="hidden-print">
                <a href="javascript:void(0)" alt="print this" (click)="printModal()"><i class="fa fa-print"></i> Print</a>
            </div>

            <div *ngIf="applicationTitle">
                <h2 class="print-header"><b>Application for:</b>{{applicationTitle}}</h2>
                <h2 class="print-header"><b>Version:</b>{{appStatusStr}}</h2>
            </div>
    
            <div>
                <h2 class="print-header"><b>Time of Printout: </b>{{currentDayStr}}</h2>
            </div>
            <!-- DHS Logo and Application Logo -->
            <div class="header">
                <div class="logo-ttp-main">
                <img alt="{{'IMAGE_ALT.DHS_SEAL' | translate}}" src="assets/images/logos/dhs-seal.png">
                <span class="cbp">U.S. Customs and<br> Border Protection</span> 
                <div class="ttp">
                    Official Trusted Traveler Program Website | Department of Homeland Security
                </div>
                </div>
            </div>

            <p [innerHTML]="content"></p>
        </div>
        <div class="modal-footer">
              <div class="col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
              <button id="printConfirmBtn" type="button" class="btn btn-block btn-primary" data-dismiss="modal" translate>MODAL.BUTTON_OK</button>
            </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal --> 
