<div class="page-content">
	<div class="row">
		<div class="col-lg-12 progress-top-level centered">
	        <h1 translate *ngIf="stepDone==0">HOME.GET_STARTED</h1>
	        <h1 translate *ngIf="stepDone>0">HOME.NEXT_STEPS</h1>
			<p class="divider hidden-xs"></p>
		</div>
	</div>
    <div class="container">
    	<div class="content-panel">
	    	<div class="row" *ngIf="stepDone==0">
				<div class="col-xs-12">
				    <h2 translate>HOME.ENROLLMENT.HOW_WORKS</h2>
                    <p class="orientation-text">
						<span translate>HOME.ENROLLMENT.TO_APPLY_FOR</span>
						<span><a translate href="javascript:void(0)" (click)="learnMoreLogin()" (key.enter)="learnMoreLogin()" class="underline">HOME.ENROLLMENT.CLICK_HERE_TO_LEARN</a></span>
					</p>
				</div>
			</div>
			<!--get started messages-->
			<div class="row" *ngIf="stepDone==0">
				<div class="col-xs-12">
					<div class="page-hint">
		               <i class="fa fa-lightbulb-o"></i>
		               <span [innerHTML]="'HOME.ENROLLMENT.IF_HAVE_GOES_ID' | translate"></span>
		          </div>
				</div>
			</div>
			<!--<div class="row" *ngIf="stepDone==0"> 
				<div class="col-xs-12">
					<div class="page-hint">
		               <i class="fa fa-lightbulb-o"></i>
		               <span [innerHTML]="'HOME.ENROLLMENT.IF_APPLIED_BEFORE' | translate"></span>
						<span><a translate href="javascript:void(0)" (click)="learnMoreAlready()" (key.enter)="learnMoreAlready()" class="underline">HOME.ABOUT.LEARN_MORE</a></span>
		          </div>
				</div>
			</div>-->
			<!--next step messages-->
			<div class="row" *ngIf="stepDone>0"> 
				<div class="col-xs-12">
					<div class="page-hint">
		               <i class="fa fa-lightbulb-o"></i>
		               <span [innerHTML]="'HOME.ENROLLMENT.IF_APPLIED_BEFORE_NEXT1' | translate"></span>
					   <span> <a translate href="javascript:void(0)" [routerLink]="['/dashboard']" class="underline">HOME.ENROLLMENT.IF_APPLIED_BEFORE_NEXT2</a></span>
					   <span [innerHTML]="'HOME.ENROLLMENT.IF_APPLIED_BEFORE_NEXT3' | translate"></span>
						<span><a translate href="javascript:void(0)" (click)="learnMoreAlready()" (key.enter)="learnMoreAlready()" class="underline">HOME.ABOUT.LEARN_MORE</a></span>
		          </div>
				</div>
			</div>

			<enrollment-overview step-done="{{stepDone}}"></enrollment-overview>
		</div>

		<div class="row row-button-group">
			<div class="col-xs-12 col-sm-4 col-md-3">
				<a [routerLink]="['']" class="btn btn-nav btn-default btn-block" translate>GENERAL_REUSABLE.CANCEL_BUTTON</a>
			</div>
			<div class="col-xs-12 col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6">
				<button (click)="login()" class="btn btn-nav btn-primary btn-block" translate>GENERAL_REUSABLE.CONTINUE</button>
			</div>
			<!-- <div class="col-xs-12 hidden-xs" style="text-align: right; color: #666">
		        <i translate>HOME.ENROLLMENT.PROCEED_TO_LOGIN</i>
		    </div> -->
		</div>
	</div>

</div>
<info-modal [id]="'login-modal'" [title]="'FAQ.WHY_LOGIN_GOV_TITLE'" [buttonText]="'MODAL.BUTTON_CLOSE'" [translate-src]="'FAQ.WHY_LOGIN_GOV_TEXT'"></info-modal>
<security-modal #securityModal [id]="'security-warn'"></security-modal>
<info-modal [id]="'mdl-about-goesId'" [title]="'FAQ.ABOUT_GOESID_OPTION_TITLE'" [buttonText]="'MODAL.BUTTON_CLOSE'" [translate-src]="'FAQ.ABOUT_GOESID_OPTION_TEXT'"></info-modal>
