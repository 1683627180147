<!-- confirm message modal definition -->

<div id="confirmModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="title !== ''" >
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="title | translate"></h4>
      </div>
      <div class="modal-body">
        <div class="with-icon error">
          <i class="fa fa-question-circle"></i>
          <p [innerHTML]="translateSrc | translate"></p>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row row-button-group">
          <div class="col-xs-12 col-sm-6 col-sm-push-5 col-sm-offset-1">
            <!-- <button id="confirmBtn" type="button" class="btn btn-block btn-primary" translate>MODAL.CONFIRM</button> -->
            <button id="confirmBtn" type="button" class="btn btn-block btn-nowrap btn-primary" [innerHTML]="btnYes | translate"></button>
          </div>
          <div class="col-xs-12 col-sm-5 col-sm-pull-7">
            <button  id="cancelBtn" type="button" class="btn btn-block btn-nowrap btn-default" [innerHTML]="btnNo | translate"></button>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->