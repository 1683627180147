import { Component, OnInit, DoCheck } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../common/services/auth.service';
import { User } from '../../common/models/user';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';
import { SETTINGS } from '../../app.constants';
import Utils from '../../common/utils/utils';
import { environment } from '../../../environments/environment';

/**
 * This class represents the toolbar component.
 */
@Component({
  moduleId: module.id,
  selector: 'go-toolbar',
  templateUrl: 'toolbar.component.html'
})

export class ToolbarComponent implements OnInit, DoCheck {

	public currentLanguage:string = "English (U.S./U.K.)";
	public flagSrc:string;
	public isUserAuthenticated:boolean;
	public user = new User;
    public disableButtons : boolean;

	private _INITUSER:User;
	private _USFLAG = "assets/images/flags/usUkFlag.png";
	private _FRFLAG = "assets/images/flags/franceFlag.png";
	private _SPFLAG = "assets/images/flags/mexicoFlag.png";
	// VisibleForTesting
	public LOCAL_STORAGE_LANGUAGE_KEY = 'ttp_language';

	constructor(private translate: TranslateService,
                private authService: AuthService,
                private referenceService : TranslateReferenceService) {
        authService.authorizedUser$.subscribe(
			user => { this.user = user; }
		);
	}

	ngOnInit(): void {
		this.flagSrc = this._USFLAG;
		this._INITUSER = this.user;
        this.disableButtons = environment.underMaintenance;
        
		// https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
		// navigator.languages isn't in all browsers yet, so cast
		// the type to make typescript happy.
		let navigator = <any>window.navigator;
		let lang = this.getPreferredLanguage(navigator.languages);
		if (lang !== 'en') {
			this.setLanguage(lang);
		}

		// when language changes reset the language dropdown
		this.translate.onLangChange.subscribe((event : any) =>{
			this._updateLanguageSelection(event.lang);
		});
	}

	ngDoCheck(): void {
        this.user = this.authService.getUser();
		if(this.user !== this._INITUSER) {
			this.isUserAuthenticated = this.user.username? true: false;
		}
	}

    setAndSaveLanguage(langCode : string) {
		this.setLanguage(langCode);
		localStorage.setItem(this.LOCAL_STORAGE_LANGUAGE_KEY, langCode);
	}

	setLanguage(langCode:string): void {
		this.translate.use(langCode);
        this.referenceService.pullData().subscribe();
        this._updateLanguageSelection(langCode);
	}

	private _updateLanguageSelection(langCode: string) {
		if(langCode === 'fr'){
			this.currentLanguage = "FRANÇAIS";
			this.flagSrc = this._FRFLAG;
		}
		else if (langCode === 'es'){
			this.currentLanguage = "ESPAÑOL";
			this.flagSrc = this._SPFLAG;
		}
		else{
			this.currentLanguage = "English (U.S./U.K.)";
			this.flagSrc = this._USFLAG;
		}
	}

	getPreferredLanguage(languages : string[]) : string {
		let storedLanguage = localStorage.getItem(this.LOCAL_STORAGE_LANGUAGE_KEY);
		if (storedLanguage) {
			return storedLanguage;
		}
		if (languages) {
			for (let lang of languages) {
				for (let validLang of ['es', 'fr', 'en']) {
					if (lang.startsWith(validLang)) {
						return validLang;
					}
				}
			}
		}
		return 'en';
	}

	skip() {
		Utils.scrollUp('main-content');
	}
}
