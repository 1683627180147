import { Component, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';

@Component({
   selector: 'spinner',
   template: `<div class="spinner-overlay" [ngClass]="{'in': isVisible(), 'out': !isVisible()}">
               </div><div class="spinner" [ngClass]="{'in': isVisible(), 'out': !isVisible()}" ></div>`
})
export class SpinnerComponent implements OnInit, OnDestroy {

    private show: boolean = false;
    private subscription: Subscription = null;

    constructor(private spinnerService: SpinnerService) {}

    ngOnInit() {
       this.createServiceSubscription();
    }

    private createServiceSubscription() {
        this.subscription = this.spinnerService.spinnerObservable.subscribe(show => {
            if (show) {
                this.showSpinner();
            } else {
                this.hideSpinner();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isVisible(): boolean {
        return this.show;
    }

    showSpinner() {
        this.show = true;
    }

    hideSpinner() {
        this.show = false;
    }
}
