import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
declare var $:any;
/**
 * This class represents the information modal component
 */
@Component({
  moduleId: module.id,
  selector: 'confirm-modal',
  templateUrl: 'confirm-modal.component.html'
})

export class ConfirmModalComponent {

  translateSrc: string;
  public title: string;
  public btnNo: string;
  public btnYes: string;

  private _confirmBtn: HTMLElement;
  private _cancelBtn: HTMLElement;

  constructor(modalService: ModalService){
    modalService.confirm  = this.confirm.bind(this);
  }

  ngOnInit() {
    this._confirmBtn = document.getElementById('confirmBtn');
    this._cancelBtn = document.getElementById('cancelBtn');
  }

  confirm(msgKey: string, titleKey?:string, noKey?:string, yesKey?:string): Promise<boolean> {
    this._resetText();
    if(titleKey)
    this.title = titleKey;
    if(noKey)
    this.btnNo = noKey;
    if(yesKey)
    this.btnYes = yesKey;

    this.translateSrc = msgKey;
    let promise = new Promise<boolean>(resolve => {
      this._configureConfirmModal(resolve);
    });
    return promise;
  }

  private _configureConfirmModal(resolve: (x: boolean) => any){
    let confirmed = (e:any) => resolve(true);
    let cancelled = (e:any) => resolve(false);
    if(!this._confirmBtn || !this._cancelBtn) return cancelled(null);

    this._confirmBtn.onclick = ((e:any) => {
      e.preventDefault();
      $('#confirmModal').modal('hide');
      confirmed(e);
    });

    this._cancelBtn.onclick = ((e:any) => {
      e.preventDefault();
      $('#confirmModal').modal('hide');
      cancelled(e);
    });

    $('#confirmModal').modal();

  }

    reset():void {
      this._cancelBtn.click();

  }

  private _resetText():void {
      this.title = "";
      this.btnNo = "MODAL.CANCEL";
      this.btnYes = "MODAL.CONFIRM";
  }

}
