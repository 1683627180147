<div class="page-content">
    <div class="row">
      <div class="col-lg-12 progress-top-level centered">
        <!--
          dashboard title is from
        -->
        <h1 class="header-1-recon" translate>OMBUDSMAN.TITLE</h1>
        <p class="divider hidden-xs"></p>
      </div>
    </div>

    <!-- Start Reconsideration View Request read only page  -->


    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- Form Start -->
          <form #reconsiderationNewform="ngForm">
            <div class="row">
              <div class="content-panel with-side-bar">
                <div class="main">
                  <h2 class="header-2-recon" translate>OMBUDSMAN.REQUEST_INFO</h2>
                  <table border=0>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.RECONSIDERATION_REQUEST_ID</span></b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <!--change to request ID-->
                        <span id="requestId">{{ReconsiderationId}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_SUBMIITED_DATE</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="submitedDateId">{{submittedDateStr | ttpDate:'MMM DD, yyyy'}}</span>
                      </td>
                    </tr>
                    <br>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_NAME</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="userNameId">{{user.name}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_DOB</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="dateOfBirthId">{{dobFormatStr}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_PROGRAM</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="programId">{{programName}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_MEMBERSHIP_ID</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="membershipId">{{user.membershipOrPassId}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="middle">
                          <b><span translate>OMBUDSMAN.REQUESTER_EMAIL</span>: </b>
                        </div>
                      </td>
                      <td class="table-width">
                        &nbsp;
                      </td>
                      <td>
                        <span id="emailId">{{user.email}}</span>
                      </td>
                    </tr>
                  </table>
                  <br>

                  <div class="middle">
                    <b><span translate>OMBUDSMAN.REQUESTER_REASON</span></b>

                  </div>
                  <div class="middle">
                    <!--  text from submission added below-->
                    <span id="requestReasonId">{{requestReason}}</span>
                  </div>
                  <br>
                <div class="row">
                    <div class="col-md-9" class="attach-margin">
                      <span class="attach-font-weight" translate>OMBUDSMAN.ATTACHED_FILES</span>
                      <p></p>
                      <div class="middle" *ngIf="reconAttachments.length>0 "> <!-- Start Only show table if files are selected-->
                        <div class="tab-content" style="padding-left: 0 !important;">
                          <div id="dataTable" class="panel-body table-responsive" style="padding-left: 0 !important;">
                        <table role="grid" class="table table-sortable table-striped mdl-js-data-table">
                        <thead class="att-tab-bac-col">
                          <tr role="row">
                              <th scope="col" role="columnheader" class="table-headerl non-numeric sortable" (click)="sortColumn('fileName', sortDirection)" (keydown)="sortColumn('fileName', sortDirection)" aria-sort="none" data-sort="text" tabindex="0" translate>&nbsp;&nbsp;OMBUDSMAN.FILE_NAME
                                <div class="pull-right">
                                  <i class="fa fa-caret-up" *ngIf="sortField=='fileName' && sortDirection=='asc'"></i>
                                  <i class="fa fa-caret-down" *ngIf="sortField=='fileName' && sortDirection=='desc'"></i>
                                </div>
                              </th>
                              <th scope="col" role="columnheader" class="table-headerl non-numeric sortable" (click)="sortColumn('fileSize', sortDirection)" (keydown)="sortColumn('fileSize', sortDirection)" aria-sort="none" tabindex="0" translate>OMBUDSMAN.FILE_SIZE
                                <div class="pull-right">
                                  <i class="fa fa-caret-up" *ngIf="sortField=='fileSize' && sortDirection=='asc'"></i>
                                  <i class="fa fa-caret-down" *ngIf="sortField=='fileSize' && sortDirection=='desc'"></i>
                                </div>
                              </th>
                              <th scope="col" role="columnheader" class="table-headerl non-numeric sortable" (click)="sortColumn('uploadDate', sortDirection)" (keydown)="sortColumn('uploadDate', sortDirection)" aria-sort="none" data-sort="date" tabindex="0" translate>OMBUDSMAN.DATE_TIME
                                <div class="pull-right">
                                  <i class="fa fa-caret-up" *ngIf="sortField=='uploadDate' && sortDirection=='asc'"></i>
                                  <i class="fa fa-caret-down" *ngIf="sortField=='uploadDate' && sortDirection=='desc'"></i>
                                </div>
                              </th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- https://angular.io/api/common/DecimalPipe -->
                          <tr class="stripe-row" *ngFor="let eachAttachment of reconAttachments">
                            <td scope="row" class="non-numeric">
                              <a href="javascript:void(0)" oncontextmenu="return false;" (click)="downloadAttachment(eachAttachment?.fileName
                                    ,eachAttachment?.fileObjectKey
                                    ,eachAttachment?.contentType)"
                                  (key.enter)="downloadAttachment(eachAttachment?.fileName,eachAttachment?.fileObjectKey,eachAttachment?.contentType)">
                                {{ eachAttachment?.fileName }}
                              </a>
                            </td>

                            <td scope="row" class="non-numeric">{{ eachAttachment?.fileSize/1024/1024 | number:'.2-2' }} MB</td>
                            <!--  eachAttachment?.fileSize/1024/1024 | number:'.0-2' -->
                            <td scope="row" class="non-numeric">{{ eachAttachment?.fileUploadDateStr | ttpDate:'MM/DD/YYYY hh:mm a' }}</td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                    </div> <!-- End -- Only show table if files are selected-->
                    <div class="middle" *ngIf="reconAttachments.length==0 "> <!-- Start Only show table if files are selected-->
                      <!-- <p>Number of files selected : {{ reconAttachments?.length }}</p> -->
                      <div class="tab-content" style="padding-left: 0 !important;">
                        <div id="dataTable" class="panel-body table-responsive" style="padding-left: 0 !important;">
                      <table class="table table-sortable table-striped mdl-js-data-table">
                        <thead class="att-tab-bac-col">
                          <tr>
                            <th scope="col" class="table-headerl non-numeric" translate>&nbsp;&nbsp;OMBUDSMAN.FILE_NAME</th>
                            <th scope="col" class="table-headerl non-numeric" translate>OMBUDSMAN.FILE_SIZE</th>
                             <th scope="col" class="table-headerl non-numeric" translate>OMBUDSMAN.DATE_TIME</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- https://angular.io/api/common/DecimalPipe -->
                          <tr class="stripe-row">
                            <td scope="row"  colspan="3" class="colspan-3 non-numeric" translate>
                              OMBUDSMAN.NO_ATTACHMENTS_SUBMITTED
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div> <!-- End -- NO files are selected-->
                  </div>
                  </div>
                  </div>
                </div>
              </div>
                <div class="middle">
                    <!--'Return to Dashboard' btn-->
                    <div class="row row-button-group">
                      <div class="col-xs-12 col-sm-5 col-sm-offset-7 col-md-4 col-md-offset-8" >
                        <button  class="btn btn-block btn-primary btn-transition" data-toggle="tooltip" data-placement="top" title="{{'OMBUDSMAN.RETURN_TO_YOUR_DASHBOARD' | translate}}"  (click)="goBack()" translate>OMBUDSMAN.RETURN_TO_DASHBOARD</button>
                      </div>

                    </div>

                  </div>

              </div>
            </div>
          </form>
          <!-- From End-->
          <!--
              https://github.com/valor-software/ng2-file-upload
              https://github.com/valor-software/ng2-file-upload/tree/master/demo/components/file-upload
              https://valor-software.com/ng2-file-upload/
              https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
              https://codepen.io/adambene/pen/xRWrXN
              https://stackblitz.com/edit/ng2-file-upload?file=app%2Fapp.component.html
             -->



        </div>
      </div>
    </div>



    <!-- End Reconsideration Request Read Only page  -->
  </div>
