<div class="page-content">
    <div class="row">
        <div class="col-xs-12 progress-top-level centered">
            <h1><span translate>PROGRESS.SCHEDULE_INTERVIEW</span>: {{programName}}</h1>
            <p class="divider hidden-xs"></p>
        </div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <div class="content-panel interview-review">
                    <div class="row">
                        <div class="col-xs-8">
                            <h2 translate>INTERVIEW_CANCEL.CANCEL.TITLE</h2>
                        </div>
                        <div class="col-xs-4 required-div">
                            <span class="pull-right required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="appointmentModel">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            
                            <div class="page-hint">
                                <i class="fa fa-lightbulb-o"></i>
                                <span translate>INTERVIEW_CANCEL.CANCEL.INFO</span> 
                            </div>
                            
                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.CANCEL.APPOINTMENT_SUMMARY</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</td>
                                        <td>{{appointmentModel.locationName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.DATE</td>
                                        <td>{{appointmentModel.startDateObject | date: 'EEE, MMM dd, y'}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.TIME</td>
                                        <td>
                                            {{appointmentModel.startTime}} 
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                            <span> {{appointmentModel.tzData}} ({{moment.tz(appointmentModel.tzData).format('z')}})</span>
                                            <a tabindex="0" href="javascript:void(0)" (click)="goToReschedule($event)" (keyup.enter)="goToReschedule($event)"><i class="fa fa-pencil-square-o"></i> <span translate>INTERVIEW_CONFIRMATION.RESCHEDULE_APPT</span></a> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PHONE</td>
                                        <td>{{appointmentModel.locationPhone}}</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.DIRECTIONS</td>
                                        <td>{{appointmentModel.locationDirections}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--<div class="sub-header">
                                <h3 translate>INTERVIEW_CANCEL.CANCEL.APPOINTMENT_SUMMARY</h3>
                            </div>

                            <dl class="dl-horizontal">
                                <dt translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</dt>
                                <dd>{{appointmentModel.locationName}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.DATE</dt>
                                <dd>{{appointmentModel.startDateObject | date: 'EEE, MMM dd, y'}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.TIME</dt>
                                <dd>
                                    {{appointmentModel.startTime}} 
                                    <span *ngIf="appointmentModel?.startDateObject?.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                    <span *ngIf="appointmentModel?.startDateObject?.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                    <a href="javascript:void(0)" (click)="goToReschedule($event)"><i class="fa fa-pencil-square-o"></i> <span translate>INTERVIEW_CONFIRMATION.RESCHEDULE_APPT</span></a> 
                                </dd>
                                <dt translate>INTERVIEW_CONFIRMATION.PHONE</dt>
                                <dd>{{appointmentModel.locationPhone}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.DIRECTIONS</dt>
                                <dd>{{appointmentModel.locationDirections}}</dd>
                            </dl>-->

                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.APPLICATION</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CANCEL.CANCEL.APPLICANT_NAME</td>
                                        <td>{{user.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PASSID</td>
                                        <td>{{user.membershipOrPassId}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PROGRAM</td>
                                        <td>{{programName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.APPLICATION_ID</td>
                                        <td>{{applicationMetadata.applicationId}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 cancel-reason">
                            <form>
                                <label for="cancellationReason"><span class="required">*</span> <span translate>INTERVIEW_CANCEL.REASON</span></label>
                                <textarea *ngIf="!isIE" [value-type]="'textPattern'" class="form-control" name="cancellationReason" rows="4" required [(ngModel)]="cancelReason" maxlength="2000"></textarea>
                                <input *ngIf="isIE" id="cancellationReason" [value-type]="'textPattern'"  tabindex="0" class="form-control" name="cancellationReason" maxlength="2000"  [(ngModel)] = "cancelReason">
                            </form>
                            <div class="field-error" *ngIf="hasCancelReasonError">
                                <p translate>INTERVIEW_CANCEL.REQUIRED_EXPLAIN</p>
                            </div>
                        </div>
                    </div>

                </div> <!-- end content panel -->

            </div>
        </div>

        <!-- print/cancel -->
        <div class="row row-button-group">
            <div class="pull-right col-xs-12 col-sm-4 col-md-3 col-xs-offset-2 col-sm-offset-1 col-md-offset-4">
                <button class="btn btn-primary btn-nav btn-block" (click)="onSubmit($event)"><span translate>INTERVIEW_CANCEL.CANCEL_BUTTON</span> <i class="fa fa-check"></i></button>
                <div class="col-xs-12" style="text-align: right; color: #666">
                    <i translate>SIGN_UP.RETURN_TO_DASHBOARD</i>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3">
                <button class="btn btn-default btn-block btn-nav" (click)="onBack($event)"><span translate>GENERAL_REUSABLE.BACK_BUTTON</span></button>
            </div>
        </div> <!-- end button group -->

    </div> <!-- end container -->
</div> <!-- end page content -->