import { Component, OnInit, AfterViewInit} from '@angular/core';
import { FAQService } from '../common/services/faq.service';
import { FAQ } from '../common/models/faq.model';
import { FAQContentItem } from '../common/models/faq-content-item.model';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Utils from '../common/utils/utils';
declare var $ : any;

@Component({
    moduleId : module.id,
    templateUrl : 'faq.component.html'
})

export class FAQComponent implements OnInit, AfterViewInit {


    faqList : FAQ[];
    selectedItems : FAQContentItem[];
    public expColToggle : number;


    constructor(private faqService : FAQService,
                private router : Router,
                private translate : TranslateService,
                private route : ActivatedRoute){}

    ngOnInit(){
        // set document title to distinguish the FAQ tab
        document.title =  "FAQ - Trusted Traveler Programs";

        // get language in-case it was passed in
        // let langKey = this.translate.currentLang;
        this.route.queryParams.subscribe((params: Params) => {
            let langKey = params['lang'];
            if(langKey){
                this.translate.use(langKey);
            }
            // retrieve initial default FAQ list
            this.faqService.updateFAQList(this.translate.currentLang).subscribe(res =>{
                if(res){
                    this.faqList = res;
                    this.selectedItems = new Array<FAQContentItem>();
                    this.faqList.forEach(faq => {
                        this.selectedItems.push(new FAQContentItem());
                    });
                }
            });
        });

        //on Language change, retrieve new list and update page level faqs
        this.translate.onLangChange.subscribe((event : any) =>{
           this.faqList = new Array<FAQ>();
           this.faqService.updateFAQList(event.lang).subscribe(res =>{
               if(res){
                   this.faqList = res;
                   this.selectedItems = new Array<FAQContentItem>();
                   this.faqList.forEach(faq => {
                     this.selectedItems.push(new FAQContentItem());
                   });
               }
           });
        });
    }

    changeSelected(item : FAQContentItem, index : number){
        if(this.selectedItems && index < this.selectedItems.length){
            this.selectedItems[index]= item;
        }
    }

    ngAfterViewInit(){

        //jQuery for toggling the arrow image.
         $(document).on('show.bs.collapse', '.collapse', function(e : any) {
           $(this).parent().find("#collapse-arrow").removeClass("fa-chevron-right").addClass("fa-chevron-down");
        })
        .on('hide.bs.collapse', '.collapse',  function(e : any) {
            $(this).parent().find("#collapse-arrow").removeClass("fa-chevron-down").addClass("fa-chevron-right");
        });

        if (window.location.href.indexOf("faq#topic_6")>-1){
           setTimeout(function(){
                window.document.getElementById('topic_6').scrollIntoView();
           }, 1000);
        }

    }

    public closeFAQ() : void {
        window.close();
    }

    scrollTo(id : string): void {
        Utils.scrollUp(id);
    }

    public collapseAll() : void {
        $('.collapse').collapse('hide');
    }

    public expandAll() : void {
        $('.collapse').collapse('show');
    }



}
