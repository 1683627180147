import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../common/utils/utils';
import { AnalyticsService } from '../../../common/services/analytics.service';

@Component({
  moduleId: module.id,
  selector: 'tsa-site-leaving',
  templateUrl: 'tsa-site-leaving-modal.component.html'
})

export class TSASiteLeavingModalComponent {

    constructor(private analyticsService: AnalyticsService) {}

    @Input()
    id: string;

    public confirmGoToTSAPrecheck() {
        this.logGetStartedProgram('TSA');
        Utils.gotoExternalUrl(environment.tsaLoginURL);
    }

    public logGetStartedProgram(programName: string) {
        if (programName) {
          this.analyticsService.logGetStarted(programName).subscribe();
        }
    }
}
