  <div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" [innerHTML]="'MODAL.NEED_HELP_TITLE' | translate"></h4>
      </div>
      <div class="modal-body">
        <div *ngIf="!screenShotInfoShown">
          <p [innerHTML]="'MODAL.NEED_HELP' | translate"></p>
          <button type="button" class="btn-link" (click)="showScreenShotInfo()">
            {{ 'MODAL.HOW_SCREENSHOT' | translate }}
          </button>
        </div>

        <div *ngIf="screenShotInfoShown">
          <p [innerHTML]= "'MODAL.SCREENSHOT_HELP' | translate"></p>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-4 col-md-4" *ngIf="screenShotInfoShown" [ngClass]="{ 'col-sm-6 col-xs-6' : screenShotInfoShown }">
          <button type="button" class="btn btn-block btn-default" (click)="showHelpInfo()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
          </button>
        </div>
  	  	<div class="col-lg-4 col-md-4 col-md-offset-4 col-lg-offset-4" [ngClass]="{ 'col-sm-6 col-xs-6' : screenShotInfoShown, 'col-sm-12 col-xs-12' : !screenShotInfoShown }">
        	<button type="button" class="btn btn-block btn-primary" data-dismiss="modal" (click)="reset()">Close</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->