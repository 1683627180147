<button #ttButton
	type="button" 
	class="btn btn-tooltip" 
	data-container="body" 
	data-toggle="popover"
	data-trigger="hover focus" 
    data-html="true"
	data-placement="top"
	role="tooltip">
  <i class="fa fa-question" aria-hidden="true"></i>
</button>