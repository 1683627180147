import { Component, Input, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

/**
 * This class represents the toolbar component.
 */
@Component({
  moduleId: module.id,
  selector: 'tooltip',
  templateUrl: 'tooltip.component.html'
})

export class TooltipComponent implements AfterViewChecked {

    @ViewChild('ttButton') el: ElementRef;

    // tslint:disable-next-line:no-input-rename
    @Input('tooltip-text')
    ttText: string;

    @Input() ttLabel: string;

    currentLang: string;

    constructor(private translate: TranslateService) {}

    ngAfterViewChecked() {
        if (this.currentLang !== this.translate.currentLang) {
            this.translate.get(this.ttText).subscribe((res: string) => {
                $(this.el.nativeElement).attr('data-content', res);

                if (!this.ttLabel) {
                  $(this.el.nativeElement).attr('aria-label', res);
                }
            });

            if (this.ttLabel) {
                this.translate.get(this.ttLabel).subscribe((res: string) => {
                  $(this.el.nativeElement).attr('aria-label', res);
              });
            }
            this.currentLang = this.translate.currentLang;
        }
        $(this.el.nativeElement).popover();
      }
}
