import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlerService } from './error-handler.service';
import { DashboardMetadata } from '../models/dashboard-metadata.model';
import { ApplicationMetadata } from '../models/application-metadata.model';

import { HttpClient } from '@angular/common/http';
import Utils from '../utils/utils';
import { environment } from '../../../environments/environment';
import { SETTINGS } from '../../app.constants';

@Injectable()
export class DashboardService {

  dashboard: DashboardMetadata;
  abtcRequestType = '';
  abtcAddOnCode = '';
  alertStatus = '';
  alertLink = '';
  alertMessage = '';

  reconsiderationSubmitted = false; // Indicates whether the last reconsideration action was a submit

  bannerStatus = '';
  bannerMessage = '';

  alertLinkMessage = '';
  currentPrograms: Array<string> = []; // List of program codes that the user currently holds a membership (AP, EX, DE)
  membershipProgram = '';
  availPartnerProgams: Array<string> = ['SE']; // List of all of the partner programs TTP supports

  constructor(private http: HttpClient,
              private errorHandler: ErrorHandlerService) { }

  getBannerStatus(): string {
    return this.bannerStatus;
  }

  setBannerStatus(status: string): void {
    this.bannerStatus = status;
  }
  getBannerMessage(): string {
    return this.bannerMessage;
  }

  setBannerMessage(msg: string): void {
    this.bannerMessage = msg;
  }

  resetBanner(): void {
    this.bannerMessage = '';
    this.bannerStatus = 'empty';
  }

  getMembershipProgram(): string {
      return this.membershipProgram;
  }

  setMembershipProgram(value: string): void {
      this.membershipProgram = value;
  }

  getABTCRequestType(): string {
      return this.abtcRequestType;
  }

  setABTCRequestType(value: string): void {
      this.abtcRequestType = value;
  }

  getABTCAddOnCode(): string {
      return this.abtcAddOnCode;
  }

  setABTCAddOnCode(value: string): void {
      this.abtcAddOnCode = value;
  }

  resetAlert(): void {
    this.alertStatus = '';
    this.alertLink = '';
    this.alertMessage = '';
    this.alertLinkMessage = '';
  }

  getAlertLinkMessage(): string {
    return this.alertLinkMessage;
  }

  setAlertLinkMessage(msg: string): void {
    this.alertLinkMessage = msg;
  }

  getAlertMessage(): string {
    return this.alertMessage;
  }

  setAlertMessage(msg: string): void {
    this.alertMessage = msg;
  }

  getAlertLink(): string {
    return this.alertLink;
  }

  setAlertLink(link: string): void {
    this.alertLink = link;
  }
  getAlertStatus(): string {
    return this.alertStatus;
  }

  setAlertStatus(status: string): void {
    this.alertStatus = status;
  }

  getDashboard(userId: string, membershipOrPassId?: string): Observable<any> {
    if (userId) {

      // TODO change uri to just dashboard; front-end an back-end
      let requestURI = '';

      if (SETTINGS.useStaticTestAPIData) {
        requestURI = SETTINGS.staticTestAPIRoot + 'dashboard-metadata.json';
      }
      else {
        requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/metadata/' + userId;

        if (membershipOrPassId && Utils.isNumeric(membershipOrPassId)) {
          requestURI += '?passId=' + membershipOrPassId;
        }
      }

      return this.http.get(requestURI).pipe(
        map((res: any) => {
          const data = res;
          if (this.errorHandler.checkErrorCode(data)) {
            this.dashboard = this.initDashboard(data);

            // Initialize the current programs
            this.initCurrentPrograms();

            return this.dashboard;
          }
          return null;
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }
    else {
      return of(false);
    }
  }

  private initDashboard(res: any): DashboardMetadata {
    const dashboard = new DashboardMetadata(res);

    return dashboard;
  }

  public getActiveApplications(): ApplicationMetadata[] {
    const result: any = this.dashboard ?  this.dashboard.applications : new Array<ApplicationMetadata>();
    return result;
  }

  public activateMembershipCard(passId: string, securityCode: string): Observable<any> {

      const reqObj = {
          activationCode : securityCode,
          gesPassId : passId
      };

      return this.http.post(environment.uriEndpoint + environment.apiVersion + 'goesapp/card/activate', reqObj).pipe(
        map(response => {
          return response;
        }),
        catchError(error => this.errorHandler.handleError(error)));
  }

  public hasActiveGoesApps(): Observable<boolean> {
    if (this.dashboard) {
      if (this.dashboard.hasPendingAppSubmittals) {
        return of(true);
      }

      for (const app of this.dashboard.applications) {
        if (app.status === 'IA' || app.status === 'CM' || app.status === 'PC'){
          return of(true);
        }
      }
    }

    return of(false);
  }

  /**
   * Initializes the current programs that the applicants has/had a membership. This is usedul for
   * select programs, so current programs include Approved, Expired, and Denied.
   *
   * The program code is added to the currentPrograms array.
   *
   * @return {[type]} [description]
   */
  private initCurrentPrograms() {
    if (this.dashboard) {
      for (const membership of this.dashboard.memberships) {
        if (membership.membershipStatus === 'AP' ||
            membership.membershipStatus === 'DE' ||
            membership.membershipStatus === 'DN' ||
            membership.membershipStatus === 'EX') {
          this.currentPrograms.push(membership.program);
        }
      }
    }
  }

  public hasActiveTTPMembership(): boolean {
    const   TTP_PROGRAM_CODES: Array<string> = ['UP', 'SH', 'NH'];

    if (this.dashboard) {
      for (const membership of this.dashboard.memberships) {
          if (TTP_PROGRAM_CODES.includes(membership.program) &&
                (membership.membershipStatus === 'AP' || membership.membershipStatus === 'DE')) {
              return true;
          }
      }
    }
    return false;
  }

  public hasActiveMembership(): boolean {
    if (this.dashboard) {
      for (const membership of this.dashboard.memberships) {
          if (membership.membershipStatus === 'AP' || membership.membershipStatus === 'DE') {
              return true;
          }
      }
    }
    return false;
  }
}
