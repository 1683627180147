<div id="{{id}}" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" translate>HOME.ABOUT.CHECK_INTERVIEW_AVAILABILITY</h4>
      </div>
      <div class="modal-body">
        <div class="chk-interview-ins">
          <span translate>HOME.CHECK_INTERVIEW_AVAILABILITY_INSTR</span>
        </div>
        <div id="chkIntLinks" class="text-center">
          <a class="btn btn-info chk-interview-btn" target="_blank" rel="noopener noreferrer" href="https://www.tsa.gov/precheck/enrollment-centers">TSA PreCheck<sup>&reg;</sup></a>
          <a class="btn btn-info chk-interview-btn" id="chkUPAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'UP'">{{ globalEntryName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkNHAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'NH'">{{ nexusName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkSHAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'SH'">{{ sentriName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkFNAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'FS'">{{ fsName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkFSAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'FN'">{{ fnName }}</a>
        </div>
      </div>
      <div class="modal-footer">
  	  	<div class="col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-4">
        	<button type="button" class="btn btn-block btn-primary" data-dismiss="modal" translate>MODAL.BUTTON_CLOSE</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
